import React from "react";
import AddTemplate from "../../components/template/AddTemplate";

function AddTemplateContainer() {
    return (
        <>
            <container maxWidth='lg'>
                <AddTemplate />
            </container>
        </>
    )
}
export default AddTemplateContainer;