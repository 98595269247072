import React from 'react';
import { Form } from 'src/components/shared';
import LoginForm from '../components/LoginForm';
import { Typography } from '@mui/material';
import Spacer from 'src/components/shared/Spacer';

function LoginContainer() {
  return (
    <>
      <Typography variant="h3" color="white" textAlign="center">
        Get Me to My Dashboard
      </Typography>
      <Spacer x={2} y={2} />
      <LoginForm />
      <Spacer x={2} y={2} />
      <Typography variant="h5" color="white" textAlign="center">
        Contact 7Health.Pro Support
      </Typography>
    </>
  );
}

export default LoginContainer;
