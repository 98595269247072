import { ReactComponent as Dashboard } from 'src/assets/images/sidebar/dashboard.svg';
import { ReactComponent as Appointment } from 'src/assets/images/sidebar/appointment.svg';
import { ReactComponent as Patients } from 'src/assets/images/sidebar/patients.svg';
import { ReactComponent as Billing } from 'src/assets/images/sidebar/billing.svg';
import { ReactComponent as Clinics } from 'src/assets/images/sidebar/clinics.svg';
import { ReactComponent as Settings } from 'src/assets/images/sidebar/settings.svg';
import { ReactComponent as Staff } from 'src/assets/images/sidebar/staff.svg';
import { ReactComponent as Tasks } from 'src/assets/images/sidebar/tasks.svg';
import { ReactComponent as doctor } from 'src/assets/images/sidebar/doctor.svg';
import {ReactComponent as area} from 'src/assets/images/sidebar/area.svg';
import {ReactComponent as treatment} from 'src/assets/images/sidebar/treatment.svg'
import {ReactComponent as tag} from 'src/assets/images/sidebar/tag.svg'
import {ReactComponent as permissions} from 'src/assets/images/sidebar/permissions.svg'
import {ReactComponent as templates} from 'src/assets/images/sidebar/template.svg'
import {ReactComponent as medicalrecord} from 'src/assets/images/sidebar/medicalrecord.svg'
import {ReactComponent as followups} from 'src/assets/images/sidebar/followup.svg'
import {ReactComponent as messages} from 'src/assets/images/sidebar/message.svg'
import {ReactComponent as notifications} from 'src/assets/images/sidebar/notification.svg'
import {ReactComponent as notificationsettings} from 'src/assets/images/sidebar/notificationsetting.svg'
// import {ReactComponent as patientinfo} from 'src/assets/images/sidebar/patientinfo.svg'


const iconConfig = {
  dashboard: Dashboard,
  appointment: Appointment,
  patients: Patients,
  billing: Billing,
  clinics: Clinics,
  settings: Settings,
  tasks: Tasks,
  staff: Staff,
  doctor:doctor,
  area:area,
  tag:tag,
  treatment:treatment,
  permission:permissions,
  template:templates,
  medicalrecords:medicalrecord,
  followup:followups,
  messages:messages,
  notifications:notifications,
  notificationsetting:notificationsettings,
  // patientinfo:patientinfo,
  
};

export default iconConfig;
