import React from "react";
import AddNotificationSetting from "../../components/notification/AddNotificationSetting";

function AddNotificationSettingContainer() {
    return (
        <>
            <container maxWidth='lg'>
            <AddNotificationSetting/>
            </container>
        </>
    )
}
export default AddNotificationSettingContainer;