import React, { useState, useEffect } from 'react';
import {
    AppBar, Tabs, Tab, Box, Grid, Button, Divider, Typography
} from "@mui/material";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; 
import {
    addDoctorProfileRequest,
    fetchDoctorProfileByIdRequest
} from '../../store/appActions';
import PersonalInfo from './PersonalInfo';
import LoginInfo from './LoginInfo';
import ProfessionalInfo from './ProfessionalInfo';
import EducationalInfo from './EducationalInfo';
import Availability from './Availability';
import ConsultingOptions from './ConsultingOptions';
import SEO from './SEO';
import Links from './Links';

const initialvalues = {
    id:'',
    personalInfo: {
        profileTitle: '',
        title: '',
        fullName: '',
        displayName: '',
        profilePic: '',
        profileBannerPic: '',
        homeAddress: '',
        languagesSpoken: '',
        aboutPersonal: '',
        status: 'ACTIVE',
    },
    loginInfo: {
        email: '',
        contactNumber: '',
        username: '',
        biometric: '',
        status: 'ACTIVE',
    },
    professionalInfo: {
        experience: '',
        licenceInfo: '',
        professionalExperience: '',
        challenges: '',
        awardsAchievements: '',
        specialProcedure: '',
        requestToPatients: '',
        status: 'ACTIVE',
    },
    educationalInfo: {
        schoolInformation: '',
        collegeInformation: '',
        specialisationInformation: '',
        status: 'ACTIVE',
    },
    consulting: {
        teleMedicines: 'No',
        clinicMeet: 'No',
        homeVisit: 'No',
        emergencyAvailability: 'No',
        onePatientAverageTime: '',
        gapBetweenAppointments: '',
        collectPatientsInfo: 'No',
        newCaseConsultingFees: '',
        oldCaseConsultingFees: '',
        noteForPatientsOnUnavailability: 'No',
        insuranceAccepting: 'No',
        governmentYojana: 'No',
        publishProfileOnline: 'No',
        acceptPatientsOnline: 'No',
        status: 'ACTIVE',
    },
    seoPurpose: {
        profileTitle: '',
        profileMetaKeywords: '',
        profileDescription: '',
        footerText: '',
        topKeywords: '',
        status: 'ACTIVE',
    },
    links: [
        {
            link: '',
            linkTitle: '',
            status: 'ACTIVE',
        },
    ],
    doctorTimings: {
        timings: [{ day: '', startTime: '', endTime: '' }],
        acceptingAppointments: 'yes',
    },
};

function DoctorProfile() {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [selectedTab, setSelectedTab] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [initialValues, setInitialValues] = useState(initialvalues); 

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    useEffect(() => {
        const fetchDoctorProfile = async () => {
            if (id) {
                try {
                    await new Promise((resolve, reject) => {
                        dispatch(fetchDoctorProfileByIdRequest(id, (profile) => {
                            console.log('Fetched Profile:', profile);
                            setInitialValues(profile);
                            setIsLoading(false);
                        }, reject));
                    });
                } catch (error) {
                    enqueueSnackbar('Failed to load doctor profile', { variant: 'error' });
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        };
        fetchDoctorProfile();
    }, [dispatch, id, enqueueSnackbar]);
    
    const validationSchema = Yup.object({
        personalInfo: Yup.object().shape({
            fullName: Yup.string().required("Full name is required!"),
            contactNumber: Yup.string().matches(/^\d{10}$/, 'Contact number must be 10 digits'),
        }),
    });

    const handleSubmit = async (values, { resetForm }) => {
        const doctorProfile = { ...values };
      
        try {
          console.log('Submitting values:', values); 
      
          await new Promise((resolve, reject) => {
            dispatch(addDoctorProfileRequest(doctorProfile, resolve, reject)); 
          });
      
          console.log('Doctor profile submitted successfully');
          enqueueSnackbar(id ? 'Doctor profile updated successfully' : 'Doctor profile added successfully', { variant: 'success' });
          resetForm();
          navigate('/doctorprofiles');
      
        } catch (error) {
          console.error('Error during submission:', error); 
          enqueueSnackbar(error.message || 'Failed to save doctor profile', { variant: 'error' });
        }
      };
    
    if (isLoading) return <Typography>Loading...</Typography>;

    return (
        <Box>
            <Typography variant="h3" color='#393A96' fontWeight='bold' textAlign='center'>
                {id ? 'Edit Doctor Profile' : 'Add Doctor Profile'}
            </Typography>
            <Divider sx={{ width: '95%', margin: '20px auto' }} />

            <AppBar position="static" color="default">
                <Tabs value={selectedTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto">
                    <Tab label="Personal Info" />
                    <Tab label="Login Info" />
                    <Tab label="Professional Info" />
                    <Tab label="Educational Info" />
                    <Tab label="Availability" />
                    <Tab label="Consulting Options" />
                    <Tab label="SEO" />
                    <Tab label="Links" />
                </Tabs>
            </AppBar>

            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, handleChange, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        {selectedTab === 0 && (
                            <PersonalInfo
                                formValues={values.personalInfo}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                            />
                        )}
                        {selectedTab === 1 && (
                            <LoginInfo
                                formValues={values.loginInfo}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                            />
                        )}
                        {selectedTab === 2 && (
                            <ProfessionalInfo
                                formValues={values.professionalInfo}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                            />
                        )}
                        {selectedTab === 3 && (
                            <EducationalInfo
                                formValues={values.educationalInfo}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                            />
                        )}
                        {selectedTab === 4 && (
                            <Availability
                                formValues={values.doctorTimings}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                            />
                        )}
                        {selectedTab === 5 && (
                            <ConsultingOptions
                                formValues={values.consulting}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                            />
                        )}
                        {selectedTab === 6 && (
                            <SEO
                                formValues={values.seoPurpose}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                            />
                        )}
                        {selectedTab === 7 && (
                            <Links
                                formValues={values.links}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                            />
                        )}

                        <Grid container justifyContent="space-between" sx={{ marginTop: '20px' }}>
                            {selectedTab > 0 && (
                                <Button onClick={() => setSelectedTab((prev) => prev - 1)}>
                                    Previous
                                </Button>
                            )}
                            {selectedTab < 7 && (
                                <Button onClick={() => setSelectedTab((prev) => prev + 1)}>
                                    Next
                                </Button>
                            )}
                            {selectedTab === 7 && (
                                <Button variant="contained" type="submit">
                                    {id ? 'Update Profile' : 'Add Profile'}
                                </Button>
                            )}
                        </Grid>
                    </form>
                )}
            </Formik>
        </Box>
    );
}

export default DoctorProfile;
