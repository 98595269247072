import React from "react";
import AddStaff from "../../components/staff/AddStaff";

function AddStaffContainer() {
    return (
        <>
            <container maxWidth='lg'>
            <AddStaff/>
            </container>
        </>
    )
}
export default AddStaffContainer;