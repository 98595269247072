import React, { useEffect, useMemo } from "react";
import { FormBox } from "../doctor/style";
import { Grid, Typography } from "@mui/material";
import Select from 'src/components/shared/Form/Select';
import InputComponent from 'src/components/shared/Form/Input';
import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from "notistack";
import { fetchDoctornameRequest } from "../../store/appActions";
import InputAutocomplete from 'src/components/App/AppGrid/Filter/Dropdown';

const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

function PersonalInfo({ handlePrevious, handleNext }) {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { values, handleChange, setFieldValue } = useFormikContext();
    const doctors = useSelector((state) => state.app.doctors || []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await new Promise((resolve, reject) => {
                    dispatch(fetchDoctornameRequest('', resolve, reject));
                });
            } catch (error) {
                console.error('Failed to load data:', error);
                enqueueSnackbar(error.message || 'Failed to load data', { variant: 'error' });
            }
        };

        fetchData();
    }, [dispatch, enqueueSnackbar]);

    const doctorOptions = useMemo(() => (
        doctors.map((doctor) => ({
            label: doctor.name,
            value: doctor.id,
        }))
    ), [doctors]);

    const titleOptions = [
        { label: 'Dr.', value: 'Dr.' },
        { label: 'Mr.', value: 'Mr.' },
        { label: 'Mrs.', value: 'Mrs.' },
        { label: 'Miss.', value: 'miss' },
    ];

    const languageOptions = [
        { label: 'English', value: 'english' },
        { label: 'Hindi', value: 'hindi' },
        { label: 'Gujrati', value: 'gujrati' },
    ];

    const handleSearchDoctor = async (searchTerm) => {
        if (searchTerm && typeof searchTerm === 'string' && searchTerm.trim().length >= 3) {
            try {
                await new Promise((resolve, reject) => {
                    dispatch(fetchDoctornameRequest(searchTerm.trim(), resolve, reject));
                });
            } catch (error) {
                console.error('Search error:', error);
                enqueueSnackbar(error.message || 'Failed to search users', { variant: 'error' });
            }
        }
    };


    const debouncedSearch = useMemo(() => debounce(handleSearchDoctor, 300), [handleSearchDoctor]);

    const handleInputChange = (searchTerm) => {
        console.log('User is searching for:', searchTerm);
        onSearchChange(searchTerm);
    };

    const onSearchChange = (searchTerm) => {
        console.log('User is searching for:', searchTerm);
        debouncedSearch(searchTerm);
    };

    return (
        <FormBox sx={{ marginTop: '20px', marginLeft: '5%' }}>
            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                <Grid item>
                    <Typography variant="h5" fontWeight="bold">Doctor</Typography>
                    <InputAutocomplete
                        variant="outlined"
                        fullWidth
                        name="user"
                        value={doctorOptions.find(doctor => doctor.value === values.user) || null}
                        onChange={(value) => setFieldValue("user", value ? value.value : '')}
                        options={doctorOptions}
                        onInputChange={(event, value) => handleInputChange(value)}
                        placeholder="Select a doctor"
                    />
                </Grid>
                <Grid item>
                    <Typography variant="h5" fontWeight="bold">Title</Typography>
                    <Select
                        variant="outlined"
                        fullWidth
                        name="personalInfo.title"
                        value={values.personalInfo.title}
                        onChange={handleChange}
                        options={titleOptions}
                        placeholder="Select a title"
                    />
                </Grid>
                <Grid item>
                    <Typography variant="h5" fontWeight="bold">Full Name</Typography>
                    <InputComponent
                        variant="outlined"
                        fullWidth
                        name="personalInfo.fullName"
                        value={values.personalInfo.fullName}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item>
                    <Typography variant="h5" fontWeight="bold">Display Name</Typography>
                    <InputComponent
                        variant="outlined"
                        fullWidth
                        name="personalInfo.displayName"
                        value={values.personalInfo.displayName}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item>
                    <Typography variant="h5" fontWeight="bold">Language Spoken</Typography>
                    <Select
                        variant="outlined"
                        fullWidth
                        multiple
                        name="personalInfo.languagesSpoken"
                        value={values.personalInfo.languagesSpoken || []}
                        onChange={(event) => setFieldValue('personalInfo.languagesSpoken', event.target.value)}
                        options={languageOptions}
                        placeholder="Select languages"
                    />
                </Grid>
                <Grid item>
                    <Typography variant="h5" fontWeight="bold">Home Address</Typography>
                    <InputComponent
                        variant="outlined"
                        fullWidth
                        name="personalInfo.homeAddress"
                        value={values.personalInfo.homeAddress}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item>
                    <Typography variant="h5" fontWeight="bold">About</Typography>
                    <InputComponent
                        variant="outlined"
                        fullWidth
                        name="personalInfo.aboutPersonal"
                        value={values.personalInfo.aboutPersonal}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
        </FormBox>
    );
}

export default PersonalInfo;
