import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  FormControl,
  TextField,
  Chip,
  Avatar,
  CircularProgress,
  createFilterOptions,
} from '@mui/material';
import { debounce } from 'lodash';
const propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['standard', 'filled', 'outlined']),
  name: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.any,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  remoteMethod: PropTypes.func,
  autocomplete: PropTypes.bool,
  fullWidth: PropTypes.bool,
  showAvatar: PropTypes.bool,
  optLabel: PropTypes.string,
  optValue: PropTypes.string,
  disabled: PropTypes.bool,
  allowAdd: PropTypes.bool,
  inputProps: PropTypes.any,
};
const defaultProps = {
  className: undefined,
  variant: 'standard',
  name: undefined,
  label: undefined,
  defaultValue: undefined,
  placeholder: 'Select',
  multiple: false,
  autocomplete: false,
  fullWidth: false,
  showAvatar: false,
  optLabel: 'label',
  optValue: 'value',
  disabled: false,
  allowAdd: false,
  inputProps: {},
};
const InputAutocomplete = ({
  className,
  variant,
  label,
  name,
  defaultValue,
  placeholder,
  options,
  onChange,
  multiple,
  remoteMethod,
  fullWidth,
  showAvatar,
  optLabel,
  optValue,
  value = [], // Default to an empty array
  disabled,
  allowAdd,
  inputProps,
  error,
  helperText,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [remoteOptions, setRemoteOptions] = useState([]);
  const filter = createFilterOptions();
  const onType = useCallback(
    debounce(async (val) => {
      if (typeof remoteMethod === 'function') {
        setLoading(true);
        try {
          const data = await remoteMethod(val || '');
          setRemoteOptions(data);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
    }, 250),
    [remoteMethod]
  );
  const optionsList = remoteMethod ? remoteOptions : options;
  return (
    <FormControl fullWidth={fullWidth} margin="normal">
      <Autocomplete
        multiple={multiple}
        id={props.id}
        value={value} // Ensure this is controlled properly
        disabled={disabled}
        options={optionsList}
        getOptionLabel={(option) => option[optLabel] || ''}
        isOptionEqualToValue={(option, val) => option[optValue] === val[optValue]}
        onChange={(event, newValue) => {
          if (newValue) {
            onChange(newValue); // Pass the selected value up to the parent
          } else {
            onChange([]); // Handle case where no value is selected
          }
        }}
        onOpen={() => {
          if (remoteMethod) {
            onType(); // Fetch remote data when the dropdown opens
          }
        }}
        className={className}
        filterOptions={(options, params) => {
          let filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some(option => inputValue === option[optLabel]);
          if (allowAdd && inputValue && !isExisting) {
            filtered.unshift({
              [optValue]: undefined,
              [optLabel]: inputValue,
              'x-add-new-label': `+ Add ${inputValue}`,
            });
          }
          return filtered;
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              avatar={showAvatar ? <Avatar>T</Avatar> : null}
              variant="filled"
              color="secondary"
              label={option[optLabel]}
              {...getTagProps({ index })}
              key={option[optValue] || index} // Ensure unique key
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            {...inputProps}
            label={label}
            variant={variant}
            fullWidth={fullWidth}
            error={error}
            helperText={helperText}
            placeholder={placeholder}
            onChange={(e) => {
              e.persist();
              onType(e.target.value);
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderOption={(props, option) => (
          <li {...props} key={`${option[optValue]}`}>
            {option[optLabel]}
          </li>
        )}
        name={name}
        {...props}
      />
    </FormControl>
  );
};
InputAutocomplete.propTypes = propTypes;
InputAutocomplete.defaultProps = defaultProps;
export default InputAutocomplete;