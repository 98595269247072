import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { useNavigate } from 'react-router-dom';
import { ListItem } from './styles';

function Item({ item, pathname }) {
  const navigate = useNavigate();
  const className = pathname === item.link ? 'active AppMenuItem' : 'AppMenuItem';

  const handleClick = () => {
    navigate(item.link);
  };

  return (
    <ListItem className={className} onClick={handleClick}>
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText primary={item.title} />
    </ListItem>
  );
}

export default Item;
