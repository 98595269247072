import React from "react";
import AddDoctor from "../../components/doctor/AddDoctor";

function AddDoctorContainer() {
    return (
        <>
            <container maxWidth='lg'>
                <AddDoctor />
            </container>
        </>
    )
}
export default AddDoctorContainer;