import React from "react";
import { FormBox } from "../doctor/style";
import { Grid, Typography, Button } from "@mui/material";
import InputComponent from 'src/components/shared/Form/Input';
import Select from 'src/components/shared/Form/Select';
import AppTimePicker from 'src/components/shared/Form/Timepicker';
import { useFormikContext } from 'formik';

const days = [
    { label: 'Monday', value: 'monday' },
    { label: 'Tuesday', value: 'tuesday' },
    { label: 'Wednesday', value: 'wednesday' },
    { label: 'Thursday', value: 'thursday' },
    { label: 'Friday', value: 'friday' },
    { label: 'Saturday', value: 'saturday' },
    { label: 'Sunday', value: 'sunday' },
    { label: 'Monday-Friday', value: 'monday-friday' },
    { label: 'Saturday-Sunday', value: 'saturday-sunday' },
];

function ClinicTimings({ handlePrevious, handleNext }) {
    const { values, setFieldValue, touched, errors } = useFormikContext();

    const handleAddTiming = () => {
        setFieldValue('clinicTiming', [
            ...values.clinicTiming,
            { day: '', startTime: '', endTime: '' },
        ]);
    };

    const handleRemoveTiming = (index) => {
        const newTimings = values.clinicTiming.filter((_, i) => i !== index);
        setFieldValue('clinicTiming', newTimings);
    };

    return (
        <FormBox sx={{ marginTop: '20px', marginLeft: '5%' }}>
            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                <Typography variant="h5" fontWeight="bold">Clinic Timings</Typography>
                {values.clinicTiming.map((timing, index) => (
                    <Grid container spacing={2} key={index}>
                        <Grid item xs={4}>
                            <Select
                                variant="outlined"
                                fullWidth
                                name={`clinicTiming[${index}].day`}
                                value={timing.day}
                                onChange={(event) => setFieldValue(`clinicTiming[${index}].day`, event.target.value)}
                                options={days}
                                error={touched.clinicTiming?.[index]?.day && Boolean(errors.clinicTiming?.[index]?.day)}
                                helperText={touched.clinicTiming?.[index]?.day && errors.clinicTiming?.[index]?.day}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AppTimePicker
                                variant="outlined"
                                fullWidth
                                value={timing.startTime}
                                onChange={(value) => setFieldValue(`clinicTiming[${index}].startTime`, value)}
                                renderInput={(params) => <InputComponent {...params} />}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AppTimePicker
                                variant="outlined"
                                fullWidth
                                value={timing.endTime}
                                onChange={(value) => setFieldValue(`clinicTiming[${index}].endTime`, value)}
                                renderInput={(params) => <InputComponent {...params} />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="outlined" color="error" onClick={() => handleRemoveTiming(index)}>
                                Remove
                            </Button>
                        </Grid>
                    </Grid>
                ))}

                <Grid item>
                    <Button onClick={handleAddTiming}>
                        Add Timing
                    </Button>
                </Grid>

            </Grid>
        </FormBox>
    );
}

export default ClinicTimings;
