import React, { useState, useEffect, useMemo } from "react";
import { FormBox } from "../doctor/style";
import * as Yup from 'yup';
import { Grid, Typography } from "@mui/material";
import InputComponent from "src/components/shared/Form/Input";
import Select from 'src/components/shared/Form/Select';
import { Button } from "src/components/shared";
import {
    addBillingRequest,
    updateBillingRequest,
    fetchBillingByIdRequest,
    fetchDoctornameRequest,
    fetchPatientnameRequest,
    fetchTokenAppointmentByPatientIdRequest,
    fetchPatientByUserIdRequest
} from '../../store/appActions';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import Datepicker from "src/components/shared/Form/Datepicker";
import { useParams, useNavigate } from 'react-router-dom';
import InputAutocomplete from "src/components/App/AppGrid/Filter/Dropdown";

const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

function AddEditBilling() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const doctors = useSelector((state) => state.app.doctors);
    const [availablePatients, setAvailablePatients] = useState([]);
    const [tokenAppointments, setTokenAppointments] = useState([]);

    const [initialValues, setInitialValues] = useState({
        paymentFor: '',
        amount: '',
        date: dayjs().format('YYYY-MM-DD'),
        method: '',
        type: 'credit',
        user: '',
        patient: '',
        tokenAppointment: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([
                    new Promise((resolve, reject) => dispatch(fetchDoctornameRequest('', resolve, reject))),
                ]);

                if (id) {
                    const billing = await new Promise((resolve, reject) =>
                        dispatch(fetchBillingByIdRequest(id, resolve, reject))
                    );
                    setInitialValues({
                        paymentFor: billing.paymentFor || '',
                        amount: billing.amount || '',
                        date: billing.date || dayjs().format('YYYY-MM-DD'),
                        method: billing.method || '',
                        type: billing.type || '',
                        user: billing.user || '',
                        patient: billing.patient || '',
                        tokenAppointment: billing.tokenAppointment || '',
                    });
                    handleFetchPatients(billing.user);
                    handleFetchTokenAppointments(billing.patient);
                }

                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [dispatch, id]);

    const handleFetchPatients = (userId) => {
        console.log('Fetching patients for userId:', userId);
        dispatch(fetchPatientByUserIdRequest(userId, (data) => {
            console.log('Fetched patients:', data);
            setAvailablePatients(data.map((patient) => ({ label: patient.name, value: patient.id })));
        }, (error) => {
            enqueueSnackbar('Failed to fetch patients', { variant: 'error' });
        }));
    };

    const handleFetchTokenAppointments = (patientId) => {
        dispatch(fetchTokenAppointmentByPatientIdRequest(patientId, (data) => {
            setTokenAppointments(data);
        }, (error) => {
            console.error('Error:', error);
        }));
    };

    const handleSubmit = async (values, { resetForm }) => {
        const billing = { ...values };

        try {
            if (id) {
                await new Promise((resolve, reject) => {
                    dispatch(updateBillingRequest(id, billing, resolve, reject));
                });
                enqueueSnackbar('Billing Updated Successfully', { variant: 'success' });
            } else {
                await new Promise((resolve, reject) => {
                    dispatch(addBillingRequest(billing, resolve, reject));
                });
                enqueueSnackbar('Billing Added Successfully', { variant: 'success' });
            }
            resetForm();
            navigate('/billings');
        } catch (error) {
            enqueueSnackbar('Error handling billing', { variant: 'error' });
        }
    };

    const tokenAppointmentOptions = useMemo(() => (
        tokenAppointments.map((appointment) => ({
            label: appointment.id,
            value: appointment.id,
        }))
    ), [tokenAppointments]);

    const handleSearch = (searchTerm, fetchFunc) => {
        if (searchTerm && typeof searchTerm === 'string' && searchTerm.trim().length >= 3) {
            return new Promise((resolve, reject) => {
                dispatch(fetchFunc(searchTerm.trim(), resolve, reject));
            });
        }
    };

    const debouncedSearchDoctor = useMemo(() => debounce((term) => handleSearch(term, fetchDoctornameRequest), 300), []);
    const debouncedSearchPatient = useMemo(() => debounce((term) => handleSearch(term, fetchPatientnameRequest), 300), []);

    const doctorOptions = useMemo(() => (
        doctors.map((doctor) => ({ label: doctor.name, value: doctor.id }))
    ), [doctors]);

    const handleInputChange = (searchTerm, type) => {
        console.log(`User is searching for ${type}:`, searchTerm);
        if (type === 'doctor') {
            debouncedSearchDoctor(searchTerm);
        } else if (type === 'patient') {
            debouncedSearchPatient(searchTerm);
        }
    };

    return (
        <FormBox sx={{ marginTop: '20px' }}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    paymentFor: Yup.string().required("Please enter paymentFor!"),
                    amount: Yup.string().required('Please enter the amount!'),
                    date: Yup.string().required('Please select the date!'),
                    method: Yup.string().required('Please select the method!'),
                    type: Yup.string().required('Please select the type!'),
                    user: Yup.string().required('Please select the user!'),
                    patient: Yup.string().required('Please select the patient!'),
                    tokenAppointment: Yup.string().required('Please select the token appointment!'),
                })}
                onSubmit={handleSubmit}
            >
                {({ values, handleChange, handleSubmit, touched, errors, isValid, setFieldValue }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                            <Typography variant="h4" color='#393A96' fontWeight='bold'>
                                {id ? 'Edit Billing' : 'Add Billing'}
                            </Typography>
                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">Payment For</Typography>
                                <InputComponent
                                    variant="outlined"
                                    fullWidth
                                    name="paymentFor"
                                    value={values.paymentFor}
                                    onChange={handleChange}
                                    error={touched.paymentFor && Boolean(errors.paymentFor)}
                                    helperText={touched.paymentFor && errors.paymentFor}
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">Amount</Typography>
                                <InputComponent
                                    variant="outlined"
                                    fullWidth
                                    name="amount"
                                    value={values.amount}
                                    onChange={handleChange}
                                    error={touched.amount && Boolean(errors.amount)}
                                    helperText={touched.amount && errors.amount}
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">Pick Date</Typography>
                                <Datepicker
                                    variant="outlined"
                                    fullWidth
                                    type="date"
                                    name="date"
                                    value={values.date}
                                    onChange={(value) => setFieldValue('date', dayjs(value).format('YYYY-MM-DD'))}
                                    error={touched.date && Boolean(errors.date)}
                                    helperText={touched.date && errors.date}
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">Type</Typography>
                                <Select
                                    variant="outlined"
                                    fullWidth
                                    name="type"
                                    value={values.type}
                                    options={[
                                        { label: 'Credit', value: 'credit' },
                                        { label: 'Debit', value: 'debit' }
                                    ]}
                                    onChange={handleChange}
                                    error={touched.type && Boolean(errors.type)}
                                    helperText={touched.type && errors.type}
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">Method</Typography>
                                <Select
                                    variant="outlined"
                                    fullWidth
                                    name="method"
                                    value={values.method}
                                    options={[
                                        { label: 'Card', value: 'card' },
                                        { label: 'Cash', value: 'cash' }
                                    ]}
                                    onChange={handleChange}
                                    error={touched.method && Boolean(errors.method)}
                                    helperText={touched.method && errors.method}
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">User</Typography>
                                <InputAutocomplete
                                    variant="outlined"
                                    fullWidth
                                    name="user"
                                    value={doctorOptions.find(doctor => doctor.value === values.user) || null}
                                    onChange={(value) => {
                                        setFieldValue("user", value ? value.value : '');
                                        handleFetchPatients(value ? value.value : '');
                                    }}
                                    options={doctorOptions}
                                    onInputChange={(event, value) => handleInputChange(value, 'doctor')}
                                    placeholder="Select a doctor"
                                    error={touched.user && Boolean(errors.user)}
                                    helperText={touched.user && errors.user}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">Patient</Typography>
                                <InputAutocomplete
                                    variant="outlined"
                                    fullWidth
                                    name="patient"
                                    value={availablePatients.find(patient => patient.value === values.patient) || null}
                                    onChange={(value) => {
                                        const selectedPatientId = value ? value.value : '';
                                        setFieldValue("patient", selectedPatientId);
                                        if (selectedPatientId) {
                                            handleFetchTokenAppointments(selectedPatientId); 
                                        } else {
                                            setTokenAppointments([]);
                                        }
                                    }}
                                    options={availablePatients}
                                    onInputChange={(event, value) => handleInputChange(value, 'patient')}
                                    placeholder="Select a patient"
                                    error={touched.patient && Boolean(errors.patient)}
                                    helperText={touched.patient && errors.patient}
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">Token Appointment</Typography>
                                <InputAutocomplete
                                    variant="outlined"
                                    fullWidth
                                    name="tokenAppointment"
                                    value={tokenAppointmentOptions.find((option) => option.value === values.tokenAppointment) || null}
                                    onChange={(value) => setFieldValue("tokenAppointment", value ? value.value : '')}
                                    options={tokenAppointmentOptions}
                                    placeholder="Select a token appointment"
                                    error={touched.tokenAppointment && Boolean(errors.tokenAppointment)}
                                    helperText={touched.tokenAppointment && errors.tokenAppointment}
                                />
                            </Grid>

                            <Grid container justifyContent="center" sx={{ marginTop: '20px' }}>
                            <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={!isValid}
                                >
                                    {id ? 'Update Billing' : 'Add Billing'}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </FormBox>
    );
}

export default AddEditBilling;