import DashboardContainer from '../container/DashboardContainer';
import AppointmentContainer from '../container/AppointmentContainer';
import AddAppointmentContainer from '../container/AddAppointmentContainer';
import DoctorContainer from '../container/doctor/DoctorContainer';
import AddDoctorContainer from '../container/doctor/AddDoctorContainer';
import ClinicContainer from '../container/clinic/ClinicContainer';
import AddClinicContainer from '../container/clinic/AddClinicContainer';
import TaskContainer from '../container/task/TaskContainer';
import AddTaskContainer from '../container/task/AddTaskContainer'
import PatientsContainer from '../container/patient/PatientContainer';
import AddPatientContainer from '../container/patient/AddPatientContainer';
import StaffsContainer from '../container/staff/StaffsContainer';
import AddStaffContainer from '../container/staff/AddStaffContainer';
import BillingsContainer from '../container/billing/BillingContainer';
import AddBillingContainer from '../container/billing/AddBillingContainer';
import AddAreaContainer from '../container/area/AddAreaContainer';
import AreasContainer from '../container/area/AreaContainer';
import TreatmentsContainer from '../container/treatment/TreatmentContainer';
import AddTreatmentContainer from '../container/treatment/AddTreatmentContainer';
import TagsContainer from '../container/tag/TagContainer';
import AddTagContainer from '../container/tag/AddTagContainer';
import PermissionsContainer from '../container/permissions/PermissionsContainer'
import AddPermissionContainer from '../container/permissions/AddPermissionContainer'
import TemplatesContainer from '../container/template/TemplateContainer'
import AddTemplateContainer from '../container/template/AddTemplateContainer';
import MedicalrecordsContainer from '../container/medicalrecord/MedicalRecordContainer';
import AddMedicalrecordContainer from '../container/medicalrecord/AddMedicalRecordContainer';
import FollowupContainer from '../container/followup/FollowupContainer';
import AddMessageContainer from '../container/message/AddMessageContainer';
import MessageContainer from '../container/message/MessageContainer';
import AddFollowupContainer from '../container/followup/AddFollowupContainer';
import NotificationSettingsContainer from '../container/notification/NotificationSettingContainer';
import AddNotificationSettingContainer from '../container/notification/AddNotificationSettingContainer';
import NotificationsContainer from '../container/notification/NotificationContainer';
import AddNotificationContainer from '../container/notification/AddNotificationContainer';
import AddDoctorProfileContainer from '../container/profile/AddDoctorProfileContainer';
import DoctorProfileContainer from '../container/profile/DoctorProfileContainer'
import PatientInfoContainer from '../container/patientinfo/PatientInfoContainer';
import AddPatientInfoContainer from '../components/patientinfo/AddPatientInfo';
import AddClinicProfileContainer from '../container/clinicprofile/AddClinicProfileContainer'
import ClinicProfileContainer from '../container/clinicprofile/ClinicProfileContainer'


export default [
    {
    title: 'dashboard',
    component: DashboardContainer,
    url: '/',
    exact: true,
    auth: true,
  },
  {
    title: 'doctor',
    component: DoctorContainer,
    url: '/doctors',
    exact: true,
    auth: true,
  },
  {
    title: 'adddoctor',
    component: AddDoctorContainer,
    url: '/doctor',
    exact: true,
    auth: true,
  },
  {
    title: 'updateDoctor',
    component: AddDoctorContainer,
    url: '/doctor/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'clinic',
    component: ClinicContainer,
    url: '/clinics',
    exact: true,
    auth: true,
  },
  {
    title: 'addclinic',
    component: AddClinicContainer,
    url: '/clinic',
    exact: true,
    auth: true,
  },
  {
    title: 'updateclinic',
    component: AddClinicContainer,
    url: '/clinic/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'task',
    component: TaskContainer,
    url: '/tasks',
    exact: true,
    auth: true,
  },
  {
    title: 'billing',
    component: BillingsContainer,
    url: '/billings',
    exact: true,
    auth: true,
  },
  {
    title: 'addbilling',
    component: AddBillingContainer,
    url: '/billing',
    exact: true,
    auth: true,
  },
  {
    title: 'updatebilling',
    component: AddBillingContainer,
    url: '/billing/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'addTask',
    component: AddTaskContainer,
    url: '/task',
    exact: true,
    auth: true,
  },
  {
    title: 'updateTask',
    component: AddTaskContainer,
    url: '/task/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'appointment',
    component: AppointmentContainer,
    url: '/appointments',
    exact: true,
    auth: true,
  },
  {
    title: 'addappointment',
    component: AddAppointmentContainer,
    url: '/appointment',
    exact: true,
    auth: true,
  },
  {
    title: 'updateappointment',
    component: AddAppointmentContainer,
    url: '/appointment/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'patient',
    component: PatientsContainer,
    url: '/patients',
    exact: true,
    auth: true,
  },
  {
    title: 'addPatient',
    component: AddPatientContainer,
    url: '/patient',
    exact: true,
    auth: true,
  },
  {
    title: 'updatePatient',
    component: AddPatientContainer,
    url: '/patient/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'staff',
    component: StaffsContainer,
    url: '/staffs',
    exact: true,
    auth: true,
  },
  {
    title: 'addStaff',
    component: AddStaffContainer,
    url: '/staff',
    exact: true,
    auth: true,
  },
  {
    title: 'updateStaff',
    component: AddStaffContainer,
    url: '/staff/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'area',
    component: AreasContainer,
    url: '/areas',
    exact: true,
    auth: true,
  },
  {
    title: 'addarea',
    component: AddAreaContainer,
    url: '/area',
    exact: true,
    auth: true,
  },
  {
    title: 'updatearea',
    component: AddAreaContainer,
    url: '/area/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'tag',
    component: TagsContainer,
    url: '/tags',
    exact: true,
    auth: true,
  },
  {
    title: 'addtag',
    component: AddTagContainer,
    url: '/tag',
    exact: true,
    auth: true,
  },
  {
    title: 'updatetag',
    component: AddTagContainer,
    url: '/tag/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'treatment',
    component: TreatmentsContainer,
    url: '/treatments',
    exact: true,
    auth: true,
  },
  {
    title: 'addtreatment',
    component: AddTreatmentContainer,
    url: '/treatment',
    exact: true,
    auth: true,
  },
  {
    title: 'updatetreatment',
    component: AddTreatmentContainer,
    url: '/treatment/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'permissions',
    component: PermissionsContainer,
    url: '/permissions',
    exact: true,
    auth: true,
  },
  {
    title: 'addpermission',
    component: AddPermissionContainer,
    url: '/permission',
    exact: true,
    auth: true,
  },
  {
    title: 'updatepermission',
    component: AddPermissionContainer,
    url: '/permission/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'templates',
    component: TemplatesContainer,
    url: '/templates',
    exact: true,
    auth: true,
  },
  {
    title: 'template',
    component: AddTemplateContainer,
    url: '/template',
    exact: true,
    auth: true,
  },
  {
    title: 'updatetemplate',
    component: AddTemplateContainer,
    url: '/template/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'medicalrecords',
    component: MedicalrecordsContainer,
    url: '/medicalrecords',
    exact: true,
    auth: true,
  },
  {
    title: 'medicalrecord',
    component: AddMedicalrecordContainer,
    url: '/medicalrecord',
    exact: true,
    auth: true,
  },
  {
    title: 'updatemedicalrecord',
    component: AddMedicalrecordContainer,
    url: '/medicalrecord/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'followups',
    component: FollowupContainer,
    url: '/followups',
    exact: true,
    auth: true,
  },
  {
    title: 'addfollowup',
    component: AddFollowupContainer,
    url: '/followup',
    exact: true,
    auth: true,
  },
  {
    title: 'updatefollowup',
    component: AddFollowupContainer,
    url: '/followup/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'messages',
    component: MessageContainer,
    url: '/messages',
    exact: true,
    auth: true,
  },
  {
    title: 'addmessage',
    component: AddMessageContainer,
    url: '/message',
    exact: true,
    auth: true,
  },
  {
    title: 'updatemessage',
    component: AddMessageContainer,
    url: '/message/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'notifications',
    component: NotificationsContainer,
    url: '/notifications',
    exact: true,
    auth: true,
  },
  {
    title: 'addnotification',
    component: AddNotificationContainer,
    url: '/notification',
    exact: true,
    auth: true,
  },
  {
    title: 'updatenotification',
    component: AddNotificationContainer,
    url: '/notification/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'notificationSettings',
    component: NotificationSettingsContainer,
    url: '/notificationSettings',
    exact: true,
    auth: true,
  },
  {
    title: 'addnotificationSetting',
    component: AddNotificationSettingContainer,
    url: '/notificationSetting',
    exact: true,
    auth: true,
  },
  {
    title: 'updatenotificationSetting',
    component: AddNotificationSettingContainer,
    url: '/notificationSetting/:id',
    exact: true,
    auth: true,
  },
 
  {
    title: 'patientinfo',
    component: PatientInfoContainer,
    url: '/patientinfos',
    exact: true,
    auth: true,
  },
  {
    title: 'addpatientinfo',
    component: AddPatientInfoContainer,
    url: '/patientinfo',
    exact: true,
    auth: true,
  },
  {
    title: 'updatepatientinfo',
    component: AddPatientInfoContainer,
    url: '/patientinfo/:id',
    exact: true,
    auth: true,
  },

  {
    title: 'adddoctorprofile',
    component: AddDoctorProfileContainer,
    url: '/doctorprofile',
    exact: true,
    auth: true,
  },
  {
    title: 'updatedoctorprofile',
    component: AddDoctorProfileContainer,
    url: '/doctorprofile/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'doctorprofile',
    component: DoctorProfileContainer,
    url: '/doctorprofiles',
    exact: true,
    auth: true,
  },
  {
    title: 'addclinicprofile',
    component: AddClinicProfileContainer,
    url: '/clinicprofile',
    exact: true,
    auth: true,
  },
  {
    title: 'updateclinicprofile',
    component: AddClinicProfileContainer,
    url: '/clinicprofile/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'clinicprofile',
    component: ClinicProfileContainer,
    url: '/clinicprofiles',
    exact: true,
    auth: true,
  },
];
