import React, { useState, useEffect, useMemo } from 'react';
import { FormBox } from '../doctor/style';
import * as Yup from 'yup';
import { Grid, Typography } from '@mui/material';
import { Button } from 'src/components/shared';
import InputComponent from 'src/components/shared/Form/Input';
import Select from 'src/components/shared/Form/Select';
import { addTemplateRequest, updateTemplateRequest, fetchTemplateByIdRequest, fetchClinicnameRequest } from '../../store/appActions';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useParams, useNavigate } from 'react-router-dom';
import InputAutocomplete from 'src/components/App/AppGrid/Filter/Dropdown';
import toast from "src/utils/toast";

const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

function Template() {
    const dispatch = useDispatch();
    const clinics = useSelector((state) => state.app.clinics || []);
    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const [initialValues, setInitialValues] = useState({
        title: '',
        text: '',
        type: '',
        clinic: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([
                    new Promise((resolve, reject) => dispatch(fetchClinicnameRequest('',resolve, reject))),
                ]);

                if (id) {
                    await new Promise((resolve, reject) => dispatch(fetchTemplateByIdRequest(id, resolve, reject)))
                        .then((template) => {
                            setInitialValues({
                                title: template.title || '',
                                text: template.text || '',
                                type: template.type || '',
                                clinic: template.clinic || '',
                            });
                        });
                }

                setIsLoading(false);
            } catch (error) {
                console.log(error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [dispatch, id]);

    const handleSubmit = async (values, { resetForm }) => {
        console.log('Form Values:', values);
        const template = { ...values };

        try {
            if (id) {
                await new Promise((resolve, reject) => {
                    dispatch(updateTemplateRequest(id, template, resolve, reject));
                });
                toast.success('Template Updated Successfully');
            } else {
                await new Promise((resolve, reject) => {
                    dispatch(addTemplateRequest(template, resolve, reject));
                });
                toast.success('Template Added Successfully');
                resetForm();
            }

            navigate('/templates');
        } catch (error) {
            console.log(error);
        }
    };

    const clinicOptions = useMemo(() => (
        clinics.map((clinic) => ({
            label: clinic.name,
            value: clinic.id,
        }))
    ), [clinics]);


    const handleSearchClinic = (searchTerm) => {
        if (searchTerm && typeof searchTerm === 'string' && searchTerm.trim().length >= 3) {
            return new Promise((resolve, reject) => {
                dispatch(fetchClinicnameRequest(searchTerm.trim(), resolve, reject));
            });
        }
    };
    
    const debouncedSearch = useMemo(() => debounce(handleSearchClinic, 300), [dispatch]);
    
    const handleInputChange = (searchTerm) => {
        console.log('Clinic is searching for:', searchTerm);
        onSearchChange(searchTerm);
      };
    
      const onSearchChange = (searchTerm) => {
        console.log('Clinic is searching for:', searchTerm);
        debouncedSearch(searchTerm);
      };


    return (
        <>
            <FormBox sx={{ marginTop: '20px' }}>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={Yup.object().shape({
                        title: Yup.string().required("Please enter template's title!"),
                        text: Yup.string().required("Please enter template's text!"),
                        type: Yup.string().required("Please select template's type!"),
                        clinic: Yup.string().required("Please select the clinic!"),
                    })}
                    onSubmit={handleSubmit}
                >
                    {({ values, handleChange, handleSubmit, touched, errors, isValid, setFieldValue }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                                <Typography
                                    variant="h4"
                                    color='#393A96'
                                    fontWeight='bold'
                                >
                                    {id ? 'Edit Template' : 'Add Template'}
                                </Typography>

                                <Typography variant="h5" fontWeight="bold">Title</Typography>
                                <InputComponent
                                    variant="outlined"
                                    fullWidth
                                    name="title"
                                    value={values.title}
                                    onChange={handleChange}
                                    error={touched.title && Boolean(errors.title)}
                                    helperText={touched.title && errors.title}
                                />

                                <Grid item>
                                    <Typography variant="h5" fontWeight="bold">Text</Typography>
                                    <InputComponent
                                        variant="outlined"
                                        fullWidth
                                        name="text"
                                        value={values.text}
                                        onChange={handleChange}
                                        error={touched.text && Boolean(errors.text)}
                                        helperText={touched.text && errors.text}
                                        multiline
                                        rows={4}
                                    />
                                </Grid>

                                <Grid item>
                                    <Typography variant="h5" fontWeight="bold">Type</Typography>
                                    <Select
                                        variant="outlined"
                                        fullWidth
                                        name="type"
                                        value={values.type}
                                        options={[
                                            { label: 'Prescription', value: 'prescription' },
                                            { label: 'Message', value: 'message' },
                                        ]}
                                        onChange={handleChange}
                                        error={touched.type && Boolean(errors.type)}
                                        helperText={touched.type && errors.type}
                                    />
                                </Grid>

                                <Grid item>
                                    <Typography variant='h5' fontWeight='bold'>Clinic</Typography>
                                    <InputAutocomplete
                                        variant="outlined"
                                        fullWidth
                                        name="clinic"
                                        value={clinicOptions.find(clinic => clinic.value === values.clinic) || null}
                                        onChange={(value) => setFieldValue("clinic", value ? value.value : '')}
                                        options={clinicOptions}
                                        onInputChange={(event, value) => handleInputChange(value)}
                                        placeholder="Select a clinic"
                                        error={touched.clinic && Boolean(errors.clinic)}
                                        helperText={touched.clinic && errors.clinic}
                                    />
                                </Grid>

                                <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '10px' }}>
                                    <Button type="submit" variant="contained" sx={{ textAlign: 'center' }} disabled={!isValid}>
                                        {id ? 'Update Template' : 'Add Template'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </FormBox>
        </>
    );
}

export default Template;