export const authPages = ['/auth/login', '/auth/forgot-password'];

export const dateFormat = 'Do MMM YYYY';
export const timeFormat = 'H:mm';
export const girdLoadingMessages = [
    'we are loading your data',
    "we'll be right with you",
    'getting everything ready',
    'loading your info',
    "we're loading your data",
    'putting everything together',
  ];