import React from "react";
import AddPermission from "../../components/permission/AddPermission";

function AddPermissionContainer() {
    return (
        <>
            <container maxWidth='lg'>
            <AddPermission/>
            </container>
        </>
    )
}
export default AddPermissionContainer;