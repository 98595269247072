import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Grid, Typography, CircularProgress } from '@mui/material';
import Select from 'src/components/shared/Form/Select';
import InputComponent from "src/components/shared/Form/Input";
import { Button } from 'src/components/shared';
import { Formik } from 'formik';
import { addNotificationRequest, updateNotificationRequest, fetchNotificationByIdRequest, fetchDoctornameRequest } from '../../store/appActions';
import { FormBox } from '../doctor/style';
import { useSnackbar } from 'notistack';
import { useParams, useNavigate } from 'react-router-dom';
import InputAutocomplete from 'src/components/App/AppGrid/Filter/Dropdown';


const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

function AddNotification() {
    const dispatch = useDispatch();
    const doctors = useSelector((state) => state.app.doctors );
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const [initialValues, setInitialValues] = useState({
        message: '',
        url: '',
        notificationStatus: '',
        user: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                await new Promise((resolve, reject) => {
                    dispatch(fetchDoctornameRequest('', resolve, reject));
                });

                if (id) {
                    await new Promise((resolve, reject) => {
                        dispatch(fetchNotificationByIdRequest(id, resolve, reject));
                    }).then((notification) => {
                        setInitialValues({
                            message: notification.message || '',
                            url: notification.url || '',
                            notificationStatus: notification.notificationStatus || '',
                            user: notification.user || '',
                        });
                    });
                }

                setIsLoading(false);
            } catch (error) {
                console.log(error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [dispatch, id]);

    const handleSubmit = async (values, { resetForm }) => {
        const notification = { ...values };

        try {
            if (id) {
                await new Promise((resolve, reject) => {
                    dispatch(updateNotificationRequest(id, notification, resolve, reject));
                });
                enqueueSnackbar('Notification updated successfully', { variant: 'success' });
            } else {
                await new Promise((resolve, reject) => {
                    dispatch(addNotificationRequest(notification, resolve, reject));
                });
                enqueueSnackbar('Notification added successfully', { variant: 'success' });
                resetForm();
            }

            navigate('/notifications');
        } catch (error) {
            console.log(error);
        }
    };

    const doctorOptions = useMemo(() => (
        doctors.map((doctor) => ({
            label: doctor.name,
            value: doctor.id,
        }))
    ), [doctors]);


    const handleSearchDoctor = async (searchTerm) => {
        if (searchTerm && typeof searchTerm === 'string' && searchTerm.trim().length >= 3) {
            try {
                await new Promise((resolve, reject) => {
                    dispatch(fetchDoctornameRequest(searchTerm.trim(), resolve, reject));
                });
            } catch (error) {
                console.error('Search error:', error);
                enqueueSnackbar(error.message || 'Failed to search users', { variant: 'error' });
            }
        }
    };



    const debouncedSearch = useMemo(() => debounce(handleSearchDoctor, 300), [handleSearchDoctor]);


    const handleInputChange = (searchTerm) => {
        console.log('User is searching for:', searchTerm);
        onSearchChange(searchTerm);
    };

    const onSearchChange = (searchTerm) => {
        console.log('User is searching for:', searchTerm);
        debouncedSearch(searchTerm);
    };

    if (isLoading) {
        return (
            <FormBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </FormBox>
        );
    }

    return (
        <FormBox sx={{ marginTop: '20px', marginLeft: '5%' }}>
            <Grid container direction='column' spacing={2} sx={{ padding: '30px' }}>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={Yup.object().shape({
                        message: Yup.string().required('Please enter notification message!'),
                        url: Yup.string().required('Please enter URL!'),
                        notificationStatus: Yup.string().required('Please select notification status!'),
                        user: Yup.string().required('Please select a user!'),
                    })}
                    onSubmit={handleSubmit}
                >
                    {({ values, handleChange, handleSubmit, touched, errors, isValid, setFieldValue }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <Typography
                                variant='h4'
                                color='#393A96'
                                fontWeight='bold'
                                sx={{ marginLeft: '2%' }}
                            >
                                {id ? 'Edit Notification' : 'Add Notification'}
                            </Typography>

                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    Notification Message
                                </Typography>
                                <InputComponent
                                    variant='outlined'
                                    fullWidth
                                    name='message'
                                    value={values.message}
                                    onChange={handleChange}
                                    error={touched.message && Boolean(errors.message)}
                                    helperText={touched.message && errors.message}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    URL
                                </Typography>
                                <InputComponent
                                    variant='outlined'
                                    fullWidth
                                    name='url'
                                    value={values.url}
                                    onChange={handleChange}
                                    error={touched.url && Boolean(errors.url)}
                                    helperText={touched.url && errors.url}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">
                                    Notification Status
                                </Typography>
                                <Select
                                    variant="outlined"
                                    fullWidth
                                    name="notificationStatus"
                                    value={values.notificationStatus}
                                    options={[
                                        { label: 'Clicked', value: 'clicked' },
                                        { label: 'Check', value: 'check' },
                                        { label: 'Uncheck', value: 'uncheck' },
                                    ]}
                                    onChange={handleChange}
                                    error={touched.notificationStatus && Boolean(errors.notificationStatus)}
                                    helperText={touched.notificationStatus && errors.notificationStatus}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    User
                                </Typography>
                                <InputAutocomplete
                                    variant="outlined"
                                    fullWidth
                                    name="user"
                                    value={doctorOptions.find(doctor => doctor.value === values.user) || null}
                                    onChange={(value) => setFieldValue("user", value ? value.value : '')}
                                    options={doctorOptions}
                                    onInputChange={(event, value) => handleInputChange(value)}
                                    placeholder="Select a doctor"
                                    error={touched.user && Boolean(errors.user)}
                                    helperText={touched.user && errors.user}
                                />
                            </Grid>

                            <Grid item container justifyContent='center' sx={{ marginTop: '20px' }}>
                                <Button variant='contained' type='submit' disabled={!isValid}>
                                    {id ? 'Update Notification' : 'Add Notification'}
                                </Button>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </Grid>
        </FormBox>
    );
}

export default AddNotification;
