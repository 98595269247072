import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AvatarImg from "@mui/material/Avatar";
import Link from '@mui/material/Link';
import { logout } from '../../../modules/auth/store/authActions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'; 


const AccountMenu = () => {
  const [avatarEl, setAvatarEl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAvatarClick = (e) => {
    setAvatarEl(e.currentTarget);
  };
  const handleLogout = () => {
    dispatch(logout());
  };

  const handleAvatarClose = () => {
    setAvatarEl(null);
  };

  const open = Boolean(avatarEl);

  return (
    <div>
        <Button aria-describedby='accountMenu' onClick={handleAvatarClick}>
            <Avatar alt="Remy Sharp" src={AvatarImg} />
            <KeyboardArrowDownIcon />
        </Button>

        <Popover
            id='accountMenu'
            open={open}
            anchorEl={avatarEl}
            onClose={handleAvatarClose}
            anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
            }}
        >
            <List disablePadding>
            <ListItem disablePadding>
                <ListItemButton>
                <Link underline="none" color="inherit" href="#"><ListItemText primary="My Account" /></Link>
                </ListItemButton>
            </ListItem>
            <Divider />
            {/* <ListItem disablePadding>
                <ListItemButton>
                <Link underline="none" color="inherit" href="#"><ListItemText primary="Settings" /></Link>
                </ListItemButton>
            </ListItem> */}
            <Divider />
            <ListItem disablePadding>
            <ListItemButton>
              <Link underline="none" color="inherit" href="#" onClick={handleLogout}>
                <ListItemText primary="Logout" />
              </Link>
            </ListItemButton>
          </ListItem>
            </List>
        </Popover>
    </div>
  );
};

export default AccountMenu;

