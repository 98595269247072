import AvatarImg from '../assets/images/avatar.jpg';

export const appointments = [
    {
        id: 'jcjx-dskjd-dsjkd',
        age: 30,
        name: 'User 1',
        mobile: '9033941811',
        date: '8/April/24 - 11:51 AM',
        symptoms: 'Headache Issue',
        status: 'Active',
    },
    {
        id: 'cnxm-ccxxc-cxccc',
        age: 30,
        name: 'User 2',
        mobile: '9033941811',
        date: '8/April/24 - 11:51 AM',
        symptoms: 'Headache Issue',
        status: 'Active',
    },
    {
        id: 'wdsd-dsdsf-fdjkf',
        age: 30,
        name: 'User 3',
        mobile: '9033941811',
        date: '8/April/24 - 11:51 AM',
        symptoms: 'Headache Issue',
        status: 'Inactive',
    },
    {
        id: 'wdsd-dsdsf-fdjkf',
        age: 30,
        name: 'User 3',
        mobile: '9033941811',
        date: '8/April/24 - 11:51 AM',
        symptoms: 'Headache Issue',
        status: 'Active',
    },
    {
        id: 'wdsd-dsdsf-fdjkf',
        age: 30,
        name: 'User 3',
        mobile: '9033941811',
        date: '8/April/24 - 11:51 AM',
        symptoms: 'Headache Issue',
        status: 'Active',
    },
  
    
]