import { all, fork, put, call, takeLatest, select } from 'redux-saga/effects';
import * as authTypes from './authTypes';
import * as authActions from './authActions';
import { setAppLoading } from 'src/modules/app/store/appActions';
import * as authApi from '../api/authApi';
import storage from 'src/utils/storageUtils';
import { push } from 'redux-first-history';
import { authPages } from 'src/config';

function* handleLogin({ data, resolve, reject }) {
  try {
    const res = yield call(authApi.loginUser, {
      userName: data.userName,
      password: data.password,
    });
    storage.set('TOKEN', res.token);
    yield put(authActions.restoreSession());
    resolve(true);
  } catch (error) {
    storage.del('TOKEN');
    reject(error);
  }
}
function* handleLogout() {
  storage.del('TOKEN');
  yield put(push('/auth/login'));
}


function* restoreSession() {
  try {
    console.log('I AM HERE 03');
    const token = storage.get('TOKEN');
    if (token && token !== '') {
      const res = yield call(authApi.getProfile);
      console.log('Profile data:', res);
      
      if (res.admin) {
        yield put(authActions.setAuthAdmin(res.admin));
        const pathname = yield select((state) => state.router?.location?.pathname || '');
        if (authPages.includes(pathname)) {
          yield put(push('/'));
        }
      } else {
        yield put(push('/auth/login'));
      }
    } else {
      yield put(push('/auth/login'));
    }
    yield put(setAppLoading(false));
  } catch (error) {
    console.log('Session restore error:', error);
    yield put(push('/auth/login'));
    yield put(setAppLoading(false));
  }
}



export function* watchSagas() {
  yield takeLatest(authTypes.LOGIN, handleLogin);
  yield takeLatest(authTypes.RESTORE_SESSION, restoreSession);
  yield takeLatest(authTypes.LOGOUT, handleLogout);

}

export default function* runSagas() {
  yield all([fork(watchSagas)]);
}
