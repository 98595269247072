import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Box, Typography } from '@mui/material';
import { Form, Button } from 'src/components/shared';
import { palette } from 'src/config/theme';
import { login } from '../store/authActions';
import { Router } from 'react-router-dom';

function LoginForm() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values, form) => {
    return new Promise((resolve, reject) => {
      dispatch(login(values, resolve, reject));
    });
  };

  return (
    <Form
      initialValues={{
        userName: 'krishna123',
        password: 'krishna',
        remember: false,
      }}
      validationSchema={Yup.object().shape({
        userName: Yup.string()
          .trim()
          .required('Please enter your username!'),
        password: Yup.string().required('Please enter your password!'),
      })}
      onSubmit={async (values, form) => {
        setLoading(true);
        try {
          await handleSubmit(values, form);
        } catch (error) {
          console.error('Login failed', error);
        }
        setLoading(false);
      }}
    >
      {(props) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            props.submitForm();
          }}
          noValidate
        >
          <Form.Field.Input
            fullWidth
            variant="outlined"
            name="userName"
            placeholder="Username"
            disabled={loading}
            autoComplete="on"
            sx={{
              '& .MuiInputBase-root': {
                backgroundColor: palette.secondary.main,
                color: '#ffffff',
              },
              '& .MuiFormHelperText-root': {
                color: 'rgba(255,255,255,0.84) !important',
              },
            }}
          />

          <Form.Field.Input
            fullWidth
            variant="outlined"
            type="password"
            name="password"
            placeholder="Password"
            color="secondary"
            disabled={loading}
            autoComplete="current-password"
            sx={{
              '& .MuiInputBase-root': {
                backgroundColor: palette.secondary.main,
                color: '#ffffff',
              },
              '& .MuiFormHelperText-root': {
                color: 'rgba(255,255,255,0.84) !important',
              },
            }}
          />

          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant='h5' color="white">Login With OTP</Typography>
            <Typography variant='h5' color="white">Forgot password?</Typography>
          </Box>

          <Form.Field.Checkbox
            name="remember"
            label={
              <Typography variant="h5" style={{ color: '#ffffff' }}>
                Remember me
              </Typography>
            }
            sx={{
              color: '#ffffff',
              '& .Mui-checked': {
                color: '#ffffff',
              },
              '& .MuiTypography-root': {
                fontSize: (theme) => theme.typography.h5.fontSize,
              },
            }}
          />

          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              loading={loading}
              fullWidth
              size="large"
              sx={{ backgroundColor: '#ffffff', color: palette.primary.main }}
            >
              Login
            </Button>
          </Box>
        </form>
      )}
    </Form>
  );
}

export default LoginForm;
