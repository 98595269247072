import * as authTypes from './authTypes';

const initialState = {
  token: '',
  isAuthenticated: false,
  admin: {},
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case authTypes.SET_AUTH_ADMIN:
      return { ...state, isAuthenticated: true, admin: payload.admin };

    default:
      return state;
  }
};
