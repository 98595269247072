import React from "react";
import { FormBox } from "../doctor/style";
import { Grid, Typography } from "@mui/material";
import InputComponent from 'src/components/shared/Form/Input';
import { useFormikContext } from 'formik';

function LoginInfo({ handlePrevious, handleNext }) {
    const { values, handleChange, touched, errors } = useFormikContext();

    return (
        <FormBox sx={{ marginTop: '20px', marginLeft: '5%' }}>
            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                <Grid item>
                    <Typography variant="h5" fontWeight="bold">Email</Typography>
                    <InputComponent
                        variant="outlined"
                        fullWidth
                        name="loginInfo.email"
                        value={values.loginInfo.email}
                        onChange={handleChange}
                        error={touched.loginInfo?.email && Boolean(errors.loginInfo?.email)}
                        helperText={touched.loginInfo?.email && errors.loginInfo?.email}
                    />
                </Grid>
                <Grid item>
                    <Typography variant="h5" fontWeight="bold">Contact Number</Typography>
                    <InputComponent
                        variant="outlined"
                        fullWidth
                        name="loginInfo.contactNumber"
                        value={values.loginInfo.contactNumber}
                        onChange={handleChange}
                        error={touched.loginInfo?.contactNumber && Boolean(errors.loginInfo?.contactNumber)}
                        helperText={touched.loginInfo?.contactNumber && errors.loginInfo?.contactNumber}
                    />
                </Grid>
                <Grid item>
                    <Typography variant="h5" fontWeight="bold">User Name</Typography>
                    <InputComponent
                        variant="outlined"
                        fullWidth
                        name="loginInfo.username"
                        value={values.loginInfo.username}
                        onChange={handleChange}
                        error={touched.loginInfo?.username && Boolean(errors.loginInfo?.username)}
                        helperText={touched.loginInfo?.username && errors.loginInfo?.username}
                    />
                </Grid>
            </Grid>
        </FormBox>
    );
}

export default LoginInfo;
