import React from "react";
import ClinicProfile from "../../components/clinicprofile/ClinicProfile";

function AddClinicProfileContainer() {
    return (
        <>
            <container maxWidth='lg'>
                <ClinicProfile />
            </container>
        </>
    )
}
export default AddClinicProfileContainer;