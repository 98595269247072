import React from "react";
import AddBilling from "../../components/billing/AddBilling";

function AddBillingContainer() {
    return (
        <>
            <container maxWidth='lg'>
            <AddBilling/>
            </container>
        </>
    )
}
export default AddBillingContainer;