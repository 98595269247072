import React from 'react';
// import Welcome from '../components/patient/Welcome';
// import PatientInfo from '../components/patient/PatientInfo';
// import Notes from '../components/patient/Notes';
import { Container, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { palette } from 'src/config/theme';
// import Action from '../components/patient/Action';
// import AllPatient from '../components/patient/AllPatient';

export const InfoBox = styled(Box)({
  backgroundColor: palette.white,
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
  height: '95vh',
  width: '82%',

  borderRadius: '16px',
  position: 'relative',
  top: 20,
});

function DashboardContainer() {
  return (
    <>
      {/* <Welcome />
      <Container fixed>
        <InfoBox>
          <PatientInfo />
          <Notes />
          <Action />
        </InfoBox>
      </Container> */}
    </>
  );
}

export default DashboardContainer;
