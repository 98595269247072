import React from "react";
import AddTask from "../../components/task/AddTask";

function AddTaskContainer() {
    return (
        <>
            <container maxWidth='lg'>
                <AddTask />
            </container>
        </>
    )
}
export default AddTaskContainer;