import React from "react";
import AddPatient from "../../components/patient/AddPatient";

function AddPatientContainer() {
    return (
        <>
            <container maxWidth='lg'>
            <AddPatient/>
            </container>
        </>
    )
}
export default AddPatientContainer;