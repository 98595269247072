import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Grid, Typography, CircularProgress } from '@mui/material';
import InputComponent from 'src/components/shared/Form/Input';
import { Button } from 'src/components/shared';
import { Formik } from 'formik';
import { addClinicRequest, updateClinicRequest, fetchClinicByIdRequest, fetchDoctornameRequest } from '../../store/appActions';
import { FormBox } from '../doctor/style';
import { useSnackbar } from 'notistack';
import { useParams, useNavigate } from 'react-router-dom';
import InputAutocomplete from 'src/components/App/AppGrid/Filter/Dropdown';

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

function AddClinic() {
  const dispatch = useDispatch();
  const doctors = useSelector((state) => state.app.doctors);
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const [initialValues, setInitialValues] = useState({
    clinicName: '',
    address: '',
    user: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching doctors...');
        await new Promise((resolve, reject) => dispatch(fetchDoctornameRequest('',resolve, reject)));
        if (id) {
          await new Promise((resolve, reject) => {
            dispatch(fetchClinicByIdRequest(id, (clinic) => {
              setInitialValues({
                clinicName: clinic.clinicName || '',
                address: clinic.address || '',
                user: clinic.user || '',
              });
            }, reject));
          });
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to load data:', error);
        enqueueSnackbar(error.message || 'Failed to load data', { variant: 'error' });
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch, id, enqueueSnackbar]);

  const handleSubmit = async (values, { resetForm }) => {
    const clinic = { ...values };

    try {
      if (id) {
        console.log('Updating clinic with ID:', id);
        await new Promise((resolve, reject) => {
          dispatch(updateClinicRequest(id, clinic, resolve, reject));
        });
        enqueueSnackbar('Clinic updated successfully', { variant: 'success' });
      } else {
        console.log('Adding new clinic');
        await new Promise((resolve, reject) => {
          dispatch(addClinicRequest(clinic, resolve, reject));
        });
        enqueueSnackbar('Clinic added successfully', { variant: 'success' });
      }

      resetForm();
      navigate('/clinics');
    } catch (error) {
      console.error('Failed to save clinic:', error);
      enqueueSnackbar('Failed to save clinic', { variant: 'error' });
    }
  };

  const handleSearchDoctor = async (searchTerm) => {
    if (searchTerm && typeof searchTerm === 'string' && searchTerm.trim().length >= 3) {
        try {
            await new Promise((resolve, reject) => {
                dispatch(fetchDoctornameRequest(searchTerm.trim(), resolve, reject));
            });
        } catch (error) {
            console.error('Search error:', error);
            enqueueSnackbar(error.message || 'Failed to search users', { variant: 'error' });
        }
    }
};



  const debouncedSearch = useMemo(() => debounce(handleSearchDoctor, 300), [handleSearchDoctor]);

  const doctorOptions = useMemo(() => (
    doctors.map((doctor) => ({
      label: doctor.name,
      value: doctor.id,
    }))
  ), [doctors]);

  const handleInputChange = (searchTerm) => {
    console.log('User is searching for:', searchTerm);
    onSearchChange(searchTerm);
  };

  const onSearchChange = (searchTerm) => {
    console.log('User is searching for:', searchTerm);
    debouncedSearch(searchTerm);
  };



  return (
    <FormBox sx={{ marginTop: '20px', marginLeft: '5%' }}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          clinicName: Yup.string().required('Please enter clinic name!'),
          address: Yup.string().required('Please enter address!'),
          user: Yup.string().required('Please select a user!'),
        })}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, handleSubmit, touched, errors, isValid, setFieldValue }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
              <Typography
                variant="h4"
                color="#393A96"
                fontWeight="bold"
                sx={{ marginLeft: '2%' }}
              >
                {id ? 'Edit Clinic' : 'Add Clinic'}
              </Typography>
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  Clinic Name
                </Typography>
                <InputComponent
                  variant="outlined"
                  fullWidth
                  name="clinicName"
                  value={values.clinicName}
                  onChange={handleChange}
                  error={touched.clinicName && Boolean(errors.clinicName)}
                  helperText={touched.clinicName && errors.clinicName}
                />
              </Grid>
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  Address
                </Typography>
                <InputComponent
                  variant="outlined"
                  fullWidth
                  name="address"
                  value={values.address}
                  onChange={handleChange}
                  error={touched.address && Boolean(errors.address)}
                  helperText={touched.address && errors.address}
                />
              </Grid>
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  User
                </Typography>
                <InputAutocomplete
                  variant="outlined"
                  fullWidth
                  name="user"
                  value={doctorOptions.find(doctor => doctor.value === values.user) || null}
                  onChange={(value) => setFieldValue("user", value ? value.value : '')}
                  options={doctorOptions}
                  onInputChange={(event, value) => handleInputChange(value)}
                  placeholder="Select a doctor"
                  error={touched.user && Boolean(errors.user)}
                  helperText={touched.user && errors.user}
                />
              </Grid>
              <Grid container justifyContent="center" sx={{ marginTop: '20px' }}>
                <Button variant="contained" type="submit" disabled={!isValid}>
                  {id ? 'Update Clinic' : 'Add Clinic'}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </FormBox>
  );
}

export default AddClinic;
