const images = {
  logoW: require('src/assets/images/logo-white.svg').default,
  logo: require('src/assets/images/logo.svg').default,
  auth: {
    authBg: require('src/assets/images/auth/auth-bg.png'),
    login1: require('src/assets/images/auth/login-1.png'),
  },
};

export default images;
