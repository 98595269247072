import React from "react";
import AddAppointment from "../components/appointment/AddAppointment";
function AddAppointmentContainer() {
    return (
        <>
            <container maxWidth='lg'>
            <AddAppointment/>
            </container>
        </>
    )
}
export default AddAppointmentContainer;