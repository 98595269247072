import * as authTypes from './authTypes';

export const login = (data, resolve, reject) => ({
  type: authTypes.LOGIN,
  data,
  resolve,
  reject,
});

export const logout = () => ({ type: authTypes.LOGOUT });

export const restoreSession = () => ({ type: authTypes.RESTORE_SESSION });

export const setAuthAdmin = (admin) => ({ type: authTypes.SET_AUTH_ADMIN, admin });
