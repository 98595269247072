import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { searchRequest } from 'src/modules/app/store/appActions';
import AccountMenu from '../AccountMenu';

const SearchResultsDropdown = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: '60px',
  left: 0,
  right: 0,
  maxHeight: '300px',
  overflowY: 'auto',
  border: '1px solid #ddd',
  zIndex: 1000,
}));

const Header = () => {
  const [input, setInput] = useState('');
  const dispatch = useDispatch();
  const searchResults = useSelector((state) => state.app.results);
  const error = useSelector((state) => state.app.error);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();

  const handleSearch = () => {
    if (input.trim()) {
      dispatch(searchRequest({ input, resolve: handleSearchSuccess, reject: handleSearchFailure }));
    }
  };

  const handleSearchSuccess = (data) => {
    console.log('Search Results:', data);
  };

  const handleSearchFailure = (error) => {
    console.error('Search Error:', error);
    setSnackbarOpen(true);
  };

  const handlePatientClick = (patientId) => {
    navigate(`/patient/${patientId}`);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ flexGrow: 1, position: 'relative' }}>
      <Grid container spacing={3}>
        <Grid item xs={7}>
          <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
            onSubmit={(e) => {
              e.preventDefault();
              handleSearch();
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Patient Name, Etc..."
              inputProps={{ 'aria-label': 'Search Patient Name, Etc...' }}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              fullWidth
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleSearch}>
              <SearchIcon />
            </IconButton>
          </Paper>

          {searchResults?.patients?.length > 0 && (
            <SearchResultsDropdown>
              <ul>
                {searchResults.patients.map((patient) => (
                  <li
                    key={patient.id}
                    onClick={() => handlePatientClick(patient.id)}
                    style={{
                      padding: '8px 16px',
                      cursor: 'pointer',
                      borderBottom: '1px solid #f0f0f0'
                    }}
                  >
                    {patient.fullName} - Age: {patient.age}
                  </li>
                ))}
              </ul>
            </SearchResultsDropdown>
          )}
        </Grid>
        <Grid item xs={5} container justifyContent="flex-end" alignItems="center">
          <Stack
            spacing={2}
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
          >
            <IconButton color="inherit">
              <Badge badgeContent={1} color="secondary">
                <NotificationsIcon color="secondary" />
              </Badge>
            </IconButton>
            <IconButton color="inherit">
              <SettingsIcon color="gray" />
            </IconButton>
            <AccountMenu />
          </Stack>
        </Grid>
      </Grid>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {error || 'An error occurred during the search.'}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Header;
