import React, { useState, useEffect } from 'react';
import {
    MenuItem,
    Stack,
    Popover,
    IconButton,
    Grid,
    Box,
    Drawer,
    Typography,
    Button,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AppGrid from 'src/components/App/AppGrid';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDoctorProfilesRequest, deleteDoctorProfileRequest } from 'src/modules/app/store/appActions';
import storage from 'src/utils/storageUtils';
import toast from 'src/utils/toast';
import Paging from 'src/components/App/Paging';

function DoctorProfilesContainer() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedDoctorProfile, setSelectedDoctorProfile] = useState(null);
    const [selectedDoctorProfileId, setSelectedDoctorProfileId] = useState(null);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [doctorProfiles, setDoctorProfiles] = useState([]);
    const [open, setOpen] = useState(null);
    const navigate = useNavigate();
    const clinic = useSelector((state) => state.app.clinic);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            const token = storage.get('TOKEN');
            dispatch(fetchDoctorProfilesRequest(token, page, perPage, (response) => {
                console.log('API Response:', response);
                if (response && response.data) {
                    setDoctorProfiles(response.data);
                    setTotalPages(response.meta.totalPages);
                } else {
                    console.error('Unexpected response format:', response);
                }
            }, (error) => {
                console.error('Failed to fetch doctor profiles:', error);
                toast.error('Failed to fetch doctor profiles');
            }));
        };

        fetchData();
    }, [dispatch, page, perPage]);

    //     const testData = [
    //         {
    //             personalInfo: {
    //                 fullName: "John Don",
    //                 displayName: "Dr. John",
    //             },
    //             loginInfo: {
    //                 email: "john.don@example.com",
    //             },
    //             professionalInfo: {
    //                 experience: "10 years",
    //             },
    //         },
    //     ];

    //     <AppGrid
    //     columns={columns}
    //     data={testData}
    //     selectableRows="none"
    //     title="Doctor Profiles"
    // />

    const handleDeleteDoctorProfile = async () => {
        if (!selectedDoctorProfile || !selectedDoctorProfile.personalInfo || !selectedDoctorProfile.personalInfo.user) {
            console.error('No selected doctor profile available for deletion');
            toast.error('Failed to delete doctor profile: No profile selected');
            return;
        }

        const userIdToDelete = selectedDoctorProfile.personalInfo.user;

        try {
            const response = await new Promise((resolve, reject) =>
                dispatch(deleteDoctorProfileRequest(userIdToDelete, resolve, reject))
            );

            if (response) {
                setDoctorProfiles(doctorProfiles.filter((doctorProfile) =>
                    doctorProfile.personalInfo.user !== userIdToDelete
                ));
                handleCloseMenu();
                toast.success('Doctor profile deleted successfully');
            }
        } catch (error) {
            toast.error('Failed to delete doctor profile');
            console.error('Failed to delete doctor profile', error);
        }
    };

    const handleActionMenu = (event, doctorProfile) => {
        console.log('Opening action menu for doctor profile:', doctorProfile.personalInfo.user);
        setOpen(event.currentTarget);
        setSelectedDoctorProfile(doctorProfile);
        setSelectedDoctorProfileId(doctorProfile.personalInfo.user);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const AddDoctorProfile = () => {
        navigate('/doctorprofile');
    };

    const handleViewDetails = (dataIndex) => () => {
        setSelectedDoctorProfileId(doctorProfiles[dataIndex]);
        setDrawerOpen(true);
        handleCloseMenu();
    };

    const closeDrawer = () => {
        setDrawerOpen(false);
        setSelectedDoctorProfile(null);
    };

    const handleEditDoctorProfile = () => {
        if (selectedDoctorProfileId) {
            navigate(`/doctorprofile/${selectedDoctorProfileId}`);
        } else {
            toast.error('DoctorProfile information is missing');
        }
    };

    useEffect(() => {
        console.log('Doctor Profiles Data:', doctorProfiles);
    }, [doctorProfiles]);


    const columns = [
        {
            label: 'Full Name',
            name: 'personalInfo',
            options: {
                customBodyRender: (value) => value.fullName, 
            },
        },
        {
            label: 'Display Name',
            name: 'personalInfo',
            options: {
                customBodyRender: (value) => value.displayName, 
            },
        },    
        {
            label: 'Email',
            name: 'loginInfo',
            options: {
                customBodyRender: (value) => value.email,
            },
        },
        {
            label: 'Experience',
            name: 'professionalInfo',
            options: {
                customBodyRender: (value) => value.experience,
            },
        },
        {
            label: 'Actions',
            name: '',
            options: {
                customBodyRenderLite: (dataIndex) => (
                    <>
                        <IconButton onClick={(event) => handleActionMenu(event, doctorProfiles[dataIndex])}>
                            <MoreVertIcon />
                        </IconButton>
                        <Popover
                            open={Boolean(open)}
                            anchorEl={open}
                            onClose={handleCloseMenu}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            PaperProps={{ sx: { width: 170 } }}
                        >
                            <MenuItem onClick={handleViewDetails(dataIndex)}>
                                <Stack spacing={1} direction="row">
                                    <VisibilityIcon />
                                    <span>View Details</span>
                                </Stack>
                            </MenuItem>
                            <MenuItem onClick={handleEditDoctorProfile}>
                                <Stack spacing={1} direction="row">
                                    <EditIcon />
                                    <span>Edit</span>
                                </Stack>
                            </MenuItem>
                            <MenuItem onClick={handleDeleteDoctorProfile} sx={{ color: 'error.main' }}>
                                <Stack spacing={1} direction="row">
                                    <DeleteIcon />
                                    <span>Delete</span>
                                </Stack>
                            </MenuItem>
                        </Popover>
                    </>
                ),
            },
        },
    ];

    return (
        <>
            <Box sx={{ marginBottom: 2 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} container>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant="contained" onClick={AddDoctorProfile}>Add DoctorProfile</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ marginBottom: 2 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <AppGrid
                            columns={columns}
                            data={doctorProfiles}
                            selectableRows="none"
                            title="Doctor Profiles"
                            onRowClick={(rowData) => console.log('Row Data:', rowData)} 
                        />
                        <Paging
                            page={page - 1}
                            perPage={perPage}
                            count={totalPages * perPage}
                            onChange={(newPage) => setPage(newPage + 1)}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Drawer anchor="right" open={drawerOpen} onClose={closeDrawer}>
                <Box sx={{ width: 350, padding: 2 }}>
                    {selectedDoctorProfile ? (
                        <>
                            <Typography variant="h6" gutterBottom>
                                Doctor Profile Details
                            </Typography>

                            {/* Personal Info */}
                            <Typography variant="subtitle1">Personal Information</Typography>
                            <Typography>Name: {selectedDoctorProfile.personalInfo.fullName}</Typography>
                            <Typography>Display Name: {selectedDoctorProfile.personalInfo.displayName}</Typography>
                            <Typography>Email: {selectedDoctorProfile.loginInfo.email}</Typography>
                            <Typography>Contact Number: {selectedDoctorProfile.loginInfo.contactNumber}</Typography>
                            <Typography>Home Address: {selectedDoctorProfile.personalInfo.homeAddress}</Typography>
                            <Typography>Languages Spoken: {selectedDoctorProfile.personalInfo.languagesSpoken}</Typography>
                            <Typography>About: {selectedDoctorProfile.personalInfo.aboutPersonal}</Typography>

                            {/* Professional Info */}
                            <Typography variant="subtitle1">Professional Information</Typography>
                            <Typography>Experience: {selectedDoctorProfile.professionalInfo.experience}</Typography>
                            <Typography>License Info: {selectedDoctorProfile.professionalInfo.licenceInfo}</Typography>
                            <Typography>Professional Experience: {selectedDoctorProfile.professionalInfo.professionalExperience}</Typography>
                            <Typography>Awards: {selectedDoctorProfile.professionalInfo.awardsAchievements}</Typography>

                            {/* Educational Info */}
                            <Typography variant="subtitle1">Educational Information</Typography>
                            <Typography>School: {selectedDoctorProfile.educationalInfo.schoolInformation}</Typography>
                            <Typography>College: {selectedDoctorProfile.educationalInfo.collegeInformation}</Typography>
                            <Typography>Specialization: {selectedDoctorProfile.educationalInfo.specialisationInformation}</Typography>

                            {/* Consulting Info */}
                            <Typography variant="subtitle1">Consulting Information</Typography>
                            <Typography>Home Visit: {selectedDoctorProfile.consulting.homeVisit ? 'Yes' : 'No'}</Typography>
                            <Typography>Consulting Fees (New Case): ${selectedDoctorProfile.consulting.newCaseConsultingFees}</Typography>

                            {/* Future Options */}
                            <Typography variant="subtitle1">Future Options</Typography>
                            <Typography>Current Status: {selectedDoctorProfile.futureOptions.currentStatus}</Typography>

                            {/* Photos */}
                            {selectedDoctorProfile?.photos?.length > 0 ? (
                                selectedDoctorProfile.photos.map(photo => (
                                    <Box key={photo.id} sx={{ marginBottom: 1 }}>
                                        <Typography>{photo.title}</Typography>
                                        <img src={photo.photoUrl} alt={photo.title} style={{ width: '100%', borderRadius: '8px' }} />
                                    </Box>
                                ))
                            ) : (
                                <Typography>No photos available.</Typography>
                            )}


                            {/* Timings */}
                            {selectedDoctorProfile?.doctorTimings?.timings?.length > 0 ? (
                                selectedDoctorProfile.doctorTimings.timings.map(timing => (
                                    <Typography key={timing.id}>
                                        {timing.day}: {timing.startTime} - {timing.endTime}
                                    </Typography>
                                ))
                            ) : (
                                <Typography>No consulting timings available.</Typography>
                            )}


                            <Button variant="contained" sx={{ marginTop: 2 }} onClick={closeDrawer}>
                                Close
                            </Button>
                        </>
                    ) : (
                        <Typography>No profile selected</Typography>
                    )}
                </Box>
            </Drawer>

        </>
    );
}

export default DoctorProfilesContainer;
