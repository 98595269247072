import React, { useState, useEffect } from 'react';
import {
    AppBar, Tabs, Tab, Box, Grid, Divider, Typography
} from "@mui/material";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    addClinicProfileRequest,
    fetchClinicProfileByIdRequest
} from '../../store/appActions';
import { Button } from 'src/components/shared';
import ClinicInformation from './ClinicInformation';
import ClinicTimings from './ClinicTimings';
import PrescriptionTemplates from './PrescriptionTemplates';
import MessageTemplates from './MessageTemplates';
import ClinicSettings from './ClinicSettings';
import Services from './Services';
import Facilities from './Facilities';
import ClinicLinks from './ClinicLinks';
// import Photos from './Photos';
// import CMS from './CMS';
import TaskSettings from './TaskSettings';

const initialvalues = {
    id: '',
    clinicInfo: {
        title: '',
        tagLine: '',
        keywords: '',
        clinicRegistrationInfo: '',
        address: '',
        country: '',
        state: '',
        city: '',
        postalCode: '',
        description: ''
    },
    clinicTiming:
        [{ day: '', startTime: '', endTime: '' }],
    prescriptionTemplate: [{
        title: '',
        text: '',
        type: 'prescription',
        status: 'ACTIVE',
    }],
    messageTemplate: [{
        title: '',
        text: '',
        type: 'message',
        status: 'ACTIVE',
    }],
    clinicSetting: {
        acceptingTokens: 'NA',
        automaticTaskAssignTo: [],
        followUpTaskDays: 3,
        medicineReminderTask: false,
        treatmentSchedulingTask: false,
        newCaseFollowUpTask: false,
        associatedDoctors: [],
        acceptingPatientReview: false,
        consultingFees: 0,
        oldCaseConsultingFees: 0,
        caseValidityDays: 45,
        emergencyFees: 0,
        status: 'ACTIVE',
    },
    services: [{ name: '', value: '', status: 'ACTIVE' }],
    facilities: [{ name: '', value: '', status: 'ACTIVE' }],
    clinicLink: [{ url: '', linkTitle: '', status: 'ACTIVE' }],
    clinicPhoto: [],
    cmsPage: {
        title: '',
        description: '',
        status: 'ACTIVE',
    },
    taskSetting: {
        followUpTask: false,
        followUpTaskFrequency: 7,
        followUpTaskCount: 1,
        treatmentSchedulingReminderTask: false,
        treatmentSchedulingReminderTaskFrequency: 7,
        treatmentSchedulingReminderTaskCount: 1,
        patientFeedbackTask: false,
        patientFeedbackTaskFrequency: 7,
        patientFeedbackTaskCount: 1,
        appointmentReminderTask: false,
        appointmentReminderTaskFrequency: 7,
        appointmentReminderTaskCount: 1,
        paymentPendingReminderTask: false,
        paymentPendingReminderTaskFrequency: 7,
        paymentPendingReminderTaskCount: 1,
        medicineReminderTask: false,
        medicineReminderTaskFrequency: 7,
        medicineReminderTaskCount: 1,
        healthCheckupTask: false,
        healthCheckupTaskFrequency: 7,
        healthCheckupTaskCount: 1,
        appointmentReschedulingTask: false,
        appointmentReschedulingTaskFrequency: 7,
        appointmentReschedulingTaskCount: 1,
        status: 'ACTIVE',
    },
};

function ClinicProfile() {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [selectedTab, setSelectedTab] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [initialValues, setInitialValues] = useState(initialvalues);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    useEffect(() => {
        const fetchClinicProfile = async () => {
            if (id) {
                try {
                    await new Promise((resolve, reject) => {
                        dispatch(fetchClinicProfileByIdRequest(id, (profile) => {
                            setInitialValues(profile);
                            setIsLoading(false);
                        }, reject));
                    });
                } catch (error) {
                    enqueueSnackbar('Failed to load clinic profile', { variant: 'error' });
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        };
        fetchClinicProfile();
    }, [dispatch, id, enqueueSnackbar]);

    const validationSchema = Yup.object({
        clinicInformation: Yup.object().shape({
            clinicName: Yup.string().required("Clinic name is required!"),
            contactNumber: Yup.string().matches(/^\d{10}$/, 'Contact number must be 10 digits'),
        }),
    });

    const handleSubmit = async (values, { resetForm }) => {
        console.log('Form values:', values);
        const clinicProfile = { ...values };

        try {
            await new Promise((resolve, reject) => {
                dispatch(addClinicProfileRequest(clinicProfile, resolve, reject));
            });

            enqueueSnackbar(id ? 'Clinic profile updated successfully' : 'Clinic profile added successfully', { variant: 'success' });
            resetForm();
            navigate('/clinicprofiles');

        } catch (error) {
            enqueueSnackbar(error.message || 'Failed to save clinic profile', { variant: 'error' });
        }
    };

    if (isLoading) return <Typography>Loading...</Typography>;

    return (
        <Box>
            <Typography variant="h3" color='#393A96' fontWeight='bold' textAlign='center'>
                {id ? 'Edit Clinic Profile' : 'Add Clinic Profile'}
            </Typography>
            <Divider sx={{ width: '95%', margin: '20px auto' }} />

            <AppBar position="static" color="default">
                <Tabs value={selectedTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto">
                    <Tab label="Clinic Information" />
                    <Tab label="ClinicTimings" />
                    <Tab label="Prescription Templates" />
                    <Tab label="Message Templates" />
                    <Tab label="Settings" />
                    <Tab label="Services Offered" />
                    <Tab label="Facilities" />
                    <Tab label="Links" />
                    <Tab label="Photos" />
                    <Tab label="CMS" />
                    <Tab label="Task Settings" />
                </Tabs>
            </AppBar>

            <Formik
                enableReinitialize
                initialValues={initialValues}
                // validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, handleChange, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        {selectedTab === 0 && (
                            <ClinicInformation
                                formValues={values.clinicInformation}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                            />
                        )}
                        {selectedTab === 1 && (
                            <ClinicTimings
                                formValues={values.clinicTiming}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                            />
                        )}
                        {selectedTab === 2 && (
                            <PrescriptionTemplates
                                formValues={values.prescriptionTemplates}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                            />
                        )}
                        {selectedTab === 3 && (
                            <MessageTemplates
                                formValues={values.messageTemplates}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                            />
                        )}
                        {selectedTab === 4 && (
                            <ClinicSettings
                                formValues={values.clinicSetting}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                            />
                        )}
                        {selectedTab === 5 && (
                            <Services
                                formValues={values.services}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                            />
                        )}
                        {selectedTab === 6 && (
                            <Facilities
                                formValues={values.facilities}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                            />
                        )}
                        {selectedTab === 7 && (
                            <ClinicLinks
                                formValues={values.cliniclinks}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                            />
                        )}
                        {/* {selectedTab === 8 && (
                            <Photos
                                formValues={values.photos}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                            />
                        )}
                        {selectedTab === 9 && (
                            <CMS
                                formValues={values.cms}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                            />
                        )} */}
                        {selectedTab === 10 && (
                            <TaskSettings
                                formValues={values.taskSetting}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                            />
                        )}

                        <Grid container justifyContent="space-between" sx={{ marginTop: '20px' }}>
                            {selectedTab > 0 && (
                                <Button onClick={() => setSelectedTab((prev) => prev - 1)}>
                                    Previous
                                </Button>
                            )}
                            {selectedTab < 10 && (
                                <Button onClick={() => setSelectedTab((prev) => prev + 1)}>
                                    Next
                                </Button>
                            )}
                            {selectedTab === 10 && (
                                <Button type="submit" variant="contained">
                                    {id ? 'Update Profile' : 'Add Profile'}
                                </Button>
                            )}


                        </Grid>
                    </form>
                )}
            </Formik>
        </Box>
    );
}

export default ClinicProfile;
