import React from "react";
import AddFollowup from "../../components/followup/AddFollowup";

function AddFollowupContainer() {
    return (
        <>
            <container maxWidth='lg'>
            <AddFollowup/>
            </container>
        </>
    )
}
export default AddFollowupContainer;