import React from "react";
import AddTreatment from "../../components/treatment/AddTreatment";

function AddTreatmentContainer() {
    return (
        <>
            <container maxWidth='lg'>
                <AddTreatment />
            </container>
        </>
    )
}
export default AddTreatmentContainer;