import React, { useState, useEffect, useMemo } from "react";
import { FormBox } from "../doctor/style";
import { Country, State, City } from 'country-state-city';
import { Grid, Typography } from "@mui/material";
import Select from 'src/components/shared/Form/Select';
import InputComponent from 'src/components/shared/Form/Input';
import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from "notistack";
import { fetchClinicnameRequest } from "../../store/appActions";
import { Button } from 'src/components/shared';
import InputAutocomplete from "src/components/shared/Form/AutoComplete";

const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

function ClinicInformation({ handlePrevious, handleNext }) {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');

    const handleCountryChange = (event) => {
        setSelectedCountry(event.target.value);
        setSelectedState('');
        setSelectedCity('');
        setFieldValue('clinicInfo.country', event.target.value);
    };

    const handleStateChange = (event) => {
        setSelectedState(event.target.value);
        setSelectedCity('');
        setFieldValue('clinicInfo.state', event.target.value);
    };

    const handleCityChange = (event) => {
        setSelectedCity(event.target.value);
        setFieldValue('clinicInfo.city', event.target.value);
    };

    const countries = Country.getAllCountries();
    const states = selectedCountry ? State.getStatesOfCountry(selectedCountry) : [];
    const cities = selectedState ? City.getCitiesOfState(selectedCountry, selectedState) : [];

    const { values, handleChange, setFieldValue } = useFormikContext();
    const clinics = useSelector((state) => state.app.clinics || []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await new Promise((resolve, reject) => dispatch(fetchClinicnameRequest('', resolve, reject)));
            } catch (error) {
                console.error('Failed to load data:', error);
                enqueueSnackbar(error.message || 'Failed to load data', { variant: 'error' });
            }
        };

        fetchData();
    }, [dispatch, enqueueSnackbar]);

    const clinicOptions = useMemo(() => (
        clinics.map((clinic) => ({
            label: clinic.name,
            value: clinic.id,
        }))
    ), [clinics]);

    const handleSearchClinic = (searchTerm) => {
        if (searchTerm && typeof searchTerm === 'string' && searchTerm.trim().length >= 3) {
            return new Promise((resolve, reject) => {
                dispatch(fetchClinicnameRequest(searchTerm.trim(), resolve, reject));
            });
        }
    };

    const debouncedSearch = useMemo(() => debounce(handleSearchClinic, 300), [dispatch]);

    const handleInputChange = (searchTerm) => {
        console.log('Clinic is searching for:', searchTerm);
        debouncedSearch(searchTerm);
    };

    const onSearchChange = (searchTerm) => {
        console.log('Clinic is searching for:', searchTerm);
        debouncedSearch(searchTerm);
    };

    return (
        <FormBox sx={{ marginTop: '20px', marginLeft: '5%' }}>
            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                <Grid item>
                    <Typography variant="h5" fontWeight="bold">Clinic ID</Typography>
                    <InputAutocomplete
                        variant="outlined"
                        fullWidth
                        name="clinic"
                        value={clinicOptions.find(clinic => clinic.value === values.clinic) || null}
                        onChange={(value) => setFieldValue("clinic", value ? value.value : '')}
                        options={clinicOptions}
                        onInputChange={(event, value) => handleInputChange(value)}
                        placeholder="Select a clinic"
                    />
                </Grid>

                <Grid item>
                    <Typography variant="h5" fontWeight="bold">Clinic Name</Typography>
                    <InputComponent
                        variant="outlined"
                        fullWidth
                        name="clinicInfo.title"
                        value={values.clinicInfo.title}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item>
                    <Typography variant="h5" fontWeight="bold">Tag Line</Typography>
                    <InputComponent
                        variant="outlined"
                        fullWidth
                        name="clinicInfo.tagLine"
                        value={values.clinicInfo.tagLine}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item>
                    <Typography variant="h5" fontWeight="bold">Keywords</Typography>
                    <InputComponent
                        variant="outlined"
                        fullWidth
                        name="clinicInfo.keywords"
                        value={values.clinicInfo.keywords}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item>
                    <Typography variant="h5" fontWeight="bold">Registration Info</Typography>
                    <InputComponent
                        variant="outlined"
                        fullWidth
                        name="clinicInfo.clinicRegistrationInfo"
                        value={values.clinicInfo.clinicRegistrationInfo}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item>
                    <Typography variant="h5" fontWeight="bold">Address</Typography>
                    <InputComponent
                        variant="outlined"
                        fullWidth
                        name="clinicInfo.address"
                        value={values.clinicInfo.address}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item>
                    <Typography variant="h5" fontWeight="bold">Country</Typography>
                    <Select
                        variant="outlined"
                        fullWidth
                        name="clinicInfo.country"
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        options={countries.map(country => ({
                            label: country.name,
                            value: country.isoCode,
                        }))}
                        placeholder="Select a country"
                    />
                </Grid>

                <Grid item>
                    <Typography variant="h5" fontWeight="bold">State</Typography>
                    <Select
                        variant="outlined"
                        fullWidth
                        name="clinicInfo.state"
                        value={selectedState}
                        onChange={handleStateChange}
                        options={states.map(state => ({
                            label: state.name,
                            value: state.isoCode,
                        }))}
                        placeholder="Select a state"
                        disabled={!selectedCountry}
                    />
                </Grid>

                <Grid item>
                    <Typography variant="h5" fontWeight="bold">City</Typography>
                    <Select
                        variant="outlined"
                        fullWidth
                        name="clinicInfo.city"
                        value={selectedCity}
                        onChange={handleCityChange}
                        options={cities.map(city => ({
                            label: city.name,
                            value: city.name,
                        }))}
                        placeholder="Select a city"
                        disabled={!selectedState}
                    />
                </Grid>

                <Grid item>
                    <Typography variant="h5" fontWeight="bold">Postal Code</Typography>
                    <InputComponent
                        variant="outlined"
                        fullWidth
                        name="clinicInfo.postalCode"
                        value={values.clinicInfo.postalCode}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item>
                    <Typography variant="h5" fontWeight="bold">Description</Typography>
                    <InputComponent
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={3}
                        name="clinicInfo.description"
                        value={values.clinicInfo.description}
                        onChange={handleChange}
                    />
                </Grid>

            </Grid>
        </FormBox>
    );
}

export default ClinicInformation;
