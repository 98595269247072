import React from "react";
import { FormBox } from "../doctor/style";
import { Grid, Typography, Box } from "@mui/material";
import { useFormikContext } from 'formik';
import CheckboxInput from 'src/components/shared/Form/Checkbox';
import InputComponent from 'src/components/shared/Form/Input';
import Select from 'src/components/shared/Form/Select';

function ConsultingOptions({ handlePrevious, handleNext }) {
    const { values, setFieldValue, handleChange, touched, errors } = useFormikContext();

    const gapBetweenAppointmentsOptions = [
        { label: '5 Min', value: '5' },
        { label: '10 Min', value: '10' },
        { label: '15 Min', value: '15' },
        { label: '20 Min', value: '20' },
    ];

    const averagePatientTimeOptions = [
        { label: '5 Min', value: '5' },
        { label: '10 Min', value: '10' },
        { label: '15 Min', value: '15' },
        { label: '20 Min', value: '20' },
    ];

    return (
        <FormBox sx={{ marginTop: '20px', marginLeft: '5%' }}>
            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                    {[
                        { name: 'consulting.noteForPatientsOnUnavailability', label: 'Note for Patients on Unavailability' },
                        { name: 'consulting.insuranceAccepting', label: 'Insurance Accepting' },
                        { name: 'consulting.governmentYojana', label: 'Government Yojana' },
                        { name: 'consulting.publishProfileOnline', label: 'Publish Profile Online' },
                        { name: 'consulting.acceptPatientsOnline', label: 'Accept Patients Online' },
                        { name: 'consulting.teleMedicines', label: 'TeleMedicine' },
                        { name: 'consulting.homeVisit', label: 'In Person at Home' },
                        { name: 'consulting.emergencyAvailability', label: 'Emergency Availability' },
                        { name: 'consulting.clinicMeet', label: 'In Person at Clinic' },
                        { name: 'consulting.collectPatientsInfo', label: 'Collect Patients Info' },
                    ].map((field) => (
                        <Grid item xs={6} key={field.name}>
                            <Grid container alignItems="center">
                                <Grid item xs>
                                    <Typography variant='h5' fontWeight='bold'>
                                        {field.label}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <CheckboxInput
                                        name={field.name}
                                        checked={values.consulting[field.name.split('.').pop()] === 'Yes'}
                                        onChange={(checked) => setFieldValue(field.name, checked ? 'Yes' : 'No')}
                                        sx={{ position: 'relative', right: '68px' }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>

                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                    <Grid item xs={6}>
                        <Typography variant="h5" fontWeight="bold">
                            One Patient Average Time
                        </Typography>
                        <Select
                            variant="outlined"
                            fullWidth
                            name="consulting.onePatientAverageTime"
                            value={values.consulting.onePatientAverageTime}
                            onChange={handleChange}
                            options={averagePatientTimeOptions}
                            placeholder="Select one Patient Average Time"
                            error={touched.consulting?.onePatientAverageTime && Boolean(errors.consulting?.onePatientAverageTime)}
                            helperText={touched.consulting?.onePatientAverageTime && errors.consulting?.onePatientAverageTime}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h5" fontWeight="bold">
                            Gap Between Appointments
                        </Typography>
                        <Select
                            variant="outlined"
                            fullWidth
                            name="consulting.gapBetweenAppointments"
                            value={values.consulting.gapBetweenAppointments}
                            onChange={handleChange}
                            options={gapBetweenAppointmentsOptions}
                            placeholder="Select gap Between Appointments"
                            error={touched.consulting?.gapBetweenAppointments && Boolean(errors.consulting?.gapBetweenAppointments)}
                            helperText={touched.consulting?.gapBetweenAppointments && errors.consulting?.gapBetweenAppointments}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                    <Grid item xs={6}>
                        <Typography variant="h5" fontWeight="bold">
                            New Case Consulting Fees
                        </Typography>
                        <InputComponent
                            variant="outlined"
                            fullWidth
                            name="consulting.newCaseConsultingFees"
                            value={values.consulting.newCaseConsultingFees}
                            onChange={handleChange}
                            error={touched.consulting?.newCaseConsultingFees && Boolean(errors.consulting?.newCaseConsultingFees)}
                            helperText={touched.consulting?.newCaseConsultingFees && errors.consulting?.newCaseConsultingFees}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h5" fontWeight="bold">
                            Old Case Consulting Fees
                        </Typography>
                        <InputComponent
                            variant="outlined"
                            fullWidth
                            name="consulting.oldCaseConsultingFees"
                            value={values.consulting.oldCaseConsultingFees}
                            onChange={handleChange}
                            error={touched.consulting?.oldCaseConsultingFees && Boolean(errors.consulting?.oldCaseConsultingFees)}
                            helperText={touched.consulting?.oldCaseConsultingFees && errors.consulting?.oldCaseConsultingFees}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </FormBox>
    );
}

export default ConsultingOptions;
