import React from "react";
import { FormBox } from "../doctor/style";
import { Grid, Typography, Box } from "@mui/material";
import InputComponent from 'src/components/shared/Form/Input';
import { useFormikContext } from 'formik';

function EducationalInfo({ handlePrevious, handleNext }) {
    const { values, handleChange, touched, errors } = useFormikContext();

    return (
        <FormBox sx={{ marginTop: '20px', marginLeft: '5%' }}>
            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                <Grid item>
                    <Typography variant="h5" fontWeight="bold">School Information</Typography>
                    <InputComponent
                        variant="outlined"
                        fullWidth
                        name="educationalInfo.schoolInformation" // Updated field name
                        value={values.educationalInfo?.schoolInformation || ''} // Default to empty string
                        onChange={handleChange}
                        error={touched.educationalInfo?.schoolInformation && Boolean(errors.educationalInfo?.schoolInformation)}
                        helperText={touched.educationalInfo?.schoolInformation && errors.educationalInfo?.schoolInformation}
                    />
                </Grid>
                <Grid item>
                    <Typography variant="h5" fontWeight="bold">College Information</Typography>
                    <InputComponent
                        variant="outlined"
                        fullWidth
                        name="educationalInfo.collegeInformation" // Updated field name
                        value={values.educationalInfo?.collegeInformation || ''} // Default to empty string
                        onChange={handleChange}
                        error={touched.educationalInfo?.collegeInformation && Boolean(errors.educationalInfo?.collegeInformation)}
                        helperText={touched.educationalInfo?.collegeInformation && errors.educationalInfo?.collegeInformation}
                    />
                </Grid>
                <Grid item>
                    <Typography variant="h5" fontWeight="bold">Specialisation Information</Typography>
                    <InputComponent
                        variant="outlined"
                        fullWidth
                        name="educationalInfo.specialisationInformation" // Updated field name
                        value={values.educationalInfo?.specialisationInformation || ''} // Default to empty string
                        onChange={handleChange}
                        error={touched.educationalInfo?.specialisationInformation && Boolean(errors.educationalInfo?.specialisationInformation)}
                        helperText={touched.educationalInfo?.specialisationInformation && errors.educationalInfo?.specialisationInformation}
                    />
                </Grid>
            </Grid>
        </FormBox>
    );
}

export default EducationalInfo;
