import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FormControl, TextField } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

const propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
};

const defaultProps = {
  className: undefined,
  value: undefined,
  onChange: () => {},
  variant: 'outlined',
  fullWidth: false,
  disabled: false,
};

const AppTimePicker = ({ value, variant, onChange, fullWidth, disabled }) => {
  const [inputValue, setInputValue] = useState(null);

  useEffect(() => {
    if (value) {
      // Convert input value to `dayjs` object with format `HH:mm`
      setInputValue(dayjs(value, 'HH:mm'));
    }
  }, [value]);

  const handleChange = (newValue) => {
    if (newValue) {
      // Format the selected time to `HH:mm` format
      const formattedTime = dayjs(newValue).format('HH:mm');
      onChange(formattedTime);
    } else {
      onChange(null); // Handle case where time is cleared
    }
  };

  return (
    <FormControl fullWidth={fullWidth} margin="normal">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          disabled={disabled}
          value={inputValue}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} variant={variant} fullWidth />}
        />
      </LocalizationProvider>
    </FormControl>
  );
};

AppTimePicker.propTypes = propTypes;
AppTimePicker.defaultProps = defaultProps;

export default AppTimePicker;
