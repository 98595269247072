import React from "react";
import AddTag from "../../components/tag/AddTag";

function AddTagContainer() {
    return (
        <>
            <container maxWidth='lg'>
            <AddTag/>
            </container>
        </>
    )
}
export default AddTagContainer;