import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Grid, Typography, CircularProgress } from '@mui/material';
import { Formik } from 'formik';
import { addTaskRequest, fetchDoctornameRequest } from '../../store/appActions';
import { FormBox } from '../doctor/style';
import { Button } from 'src/components/shared';
import { useSnackbar } from 'notistack';
import InputComponent from 'src/components/shared/Form/Input';
import Select from 'src/components/shared/Form/Select';
import toast from "src/utils/toast";
import InputAutocomplete from 'src/components/App/AppGrid/Filter/Dropdown';

const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

function AddTask() {
    const dispatch = useDispatch();
    const doctors = useSelector((state) => state.app.doctors );
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchDoctors = async () => {
            try {
                await new Promise((resolve, reject) => {
                    dispatch(fetchDoctornameRequest('',resolve, reject));
                });
                setIsLoading(false);
            } catch (error) {
                console.log(error)
                setIsLoading(false);
            }
        };

        fetchDoctors();
    }, [dispatch, enqueueSnackbar]);

    const doctorOptions = useMemo(() => (
        doctors.map((doctor) => ({
            label: doctor.name,
            value: doctor.id,
        }))
    ), [doctors]);

    const handleSubmit = async (values, { resetForm }) => {
        console.log('Form Values:', values);
        const task = { ...values };

        try {
            await new Promise((resolve, reject) => {
                dispatch(addTaskRequest(task, resolve, reject));
            });
            toast.success('Task Added Successfully');
            resetForm();
        } catch (error) {
            console.log(error)
        }
    };


    const handleSearchDoctor = async (searchTerm) => {
        if (searchTerm && typeof searchTerm === 'string' && searchTerm.trim().length >= 3) {
            try {
                await new Promise((resolve, reject) => {
                    dispatch(fetchDoctornameRequest(searchTerm.trim(), resolve, reject));
                });
            } catch (error) {
                console.error('Search error:', error);
                enqueueSnackbar(error.message || 'Failed to search users', { variant: 'error' });
            }
        }
    };



    const debouncedSearch = useMemo(() => debounce(handleSearchDoctor, 300), [handleSearchDoctor]);


    const handleInputChange = (searchTerm) => {
        console.log('User is searching for:', searchTerm);
        onSearchChange(searchTerm);
      };
    
      const onSearchChange = (searchTerm) => {
        console.log('User is searching for:', searchTerm);
        debouncedSearch(searchTerm);
      };    

    if (isLoading) {
        return (
            <FormBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </FormBox>
        );
    }

    return (
        <>
            <FormBox>
                <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                    <Formik
                        initialValues={{
                            name: '',
                            assigned: '',
                            dueDate: '',
                            priority: '',
                            caseID: '',
                            tokenOrAppointment: '',
                            description: '',
                            status: 'pending',
                            user: '',
                        }}
                        validationSchema={Yup.object().shape({
                            name: Yup.string().required('Please enter the task name!'),
                            assigned: Yup.string().required('Please enter the assignee!'),
                            dueDate: Yup.date().required('Please select the due date!'),
                            priority: Yup.string().required('Please select the priority!'),
                            caseID: Yup.string(),
                            tokenOrAppointment: Yup.string(),
                            description: Yup.string().required('Please enter the task description!'),
                            status: Yup.string().required('Please select the status!'),
                            user: Yup.string().required('Please select the user!')
                        })}
                        onSubmit={handleSubmit}
                    >
                        {({ values, handleChange, handleSubmit, touched, errors, isValid, setFieldValue }) => (
                            <form onSubmit={handleSubmit} noValidate>
                                <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                                    <Typography variant="h4" color="#393A96" fontWeight="bold" sx={{ marginLeft: '2%' }}>
                                        Add Task
                                    </Typography>
                                    <Grid item>
                                        <Typography variant="h5" fontWeight="bold">Task Name</Typography>
                                        <InputComponent
                                            variant="outlined"
                                            fullWidth
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            error={touched.name && Boolean(errors.name)}
                                            helperText={touched.name && errors.name}
                                        />

                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h5" fontWeight="bold">Assigned To</Typography>
                                        <InputComponent
                                            variant="outlined"
                                            fullWidth
                                            name="assigned"
                                            value={values.assigned}
                                            onChange={handleChange}
                                            error={touched.assigned && Boolean(errors.assigned)}
                                            helperText={touched.assigned && errors.assigned}
                                        />

                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h5" fontWeight="bold">Due Date</Typography>
                                        <InputComponent
                                            variant="outlined"
                                            fullWidth
                                            name="dueDate"
                                            type="date"
                                            value={values.dueDate}
                                            onChange={handleChange}
                                            error={touched.dueDate && Boolean(errors.dueDate)}
                                            helperText={touched.dueDate && errors.dueDate}
                                            InputLabelProps={{ shrink: true }}
                                        />

                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h5" fontWeight="bold">Priority</Typography>
                                        <Select
                                            variant="outlined"
                                            fullWidth
                                            name="priority"
                                            value={values.priority}
                                            options={[
                                                { label: 'High', value: 'high' },
                                                { label: 'Medium', value: 'medium' },
                                                { label: 'Low', value: 'low' }
                                            ]}
                                            onChange={handleChange}
                                            error={touched.priority && Boolean(errors.priority)}
                                            helperText={touched.priority && errors.priority}
                                        >
                                        </Select>

                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h5" fontWeight="bold">Case ID</Typography>
                                        <InputComponent
                                            variant="outlined"
                                            fullWidth
                                            name="caseID"
                                            value={values.caseID}
                                            onChange={handleChange}
                                            error={touched.caseID && Boolean(errors.caseID)}
                                            helperText={touched.caseID && errors.caseID}
                                        />

                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h5" fontWeight="bold">Token/Appointment</Typography>
                                        <InputComponent
                                            variant="outlined"
                                            fullWidth
                                            name="tokenOrAppointment"
                                            value={values.tokenOrAppointment}
                                            onChange={handleChange}
                                            error={touched.tokenOrAppointment && Boolean(errors.tokenOrAppointment)}
                                            helperText={touched.tokenOrAppointment && errors.tokenOrAppointment}
                                        />

                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h5" fontWeight="bold">Description</Typography>
                                        <InputComponent
                                            variant="outlined"
                                            fullWidth
                                            name="description"
                                            value={values.description}
                                            onChange={handleChange}
                                            error={touched.description && Boolean(errors.description)}
                                            helperText={touched.description && errors.description}
                                            multiline
                                            rows={4}
                                        />

                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h5" fontWeight="bold">Status</Typography>
                                        <Select
                                            variant="outlined"
                                            fullWidth
                                            name="status"
                                            value={values.status}
                                            options={[
                                                { label: 'Pending', value: 'pending' },
                                                { label: 'Completed', value: 'completed' },
                                                { label: 'In Progress', value: 'in-progress' }
                                            ]}
                                            onChange={handleChange}
                                            error={touched.status && Boolean(errors.status)}
                                            helperText={touched.status && errors.status}
                                        />

                                    </Grid>
                                    <Grid item>
                                        <Typography variant='h5' fontWeight='bold'>
                                            User
                                        </Typography>
                                        <InputAutocomplete
                                            variant="outlined"
                                            fullWidth
                                            name="user"
                                            value={doctorOptions.find(doctor => doctor.value === values.user) || null}
                                            onChange={(value) => setFieldValue("user", value ? value.value : '')}
                                            options={doctorOptions}
                                            onInputChange={(event, value) => handleInputChange(value)}
                                            placeholder="Select a doctor"
                                            error={touched.user && Boolean(errors.user)}
                                            helperText={touched.user && errors.user}
                                        />
                                    </Grid>
                                    <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '30px' }}>
                                        <Button type="submit" variant="contained" sx={{ textAlign: 'center' }} disabled={!isValid}>
                                            {isLoading ? 'Saving...' : 'Add Task'}
                                        </Button>

                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </Grid>
            </FormBox>
        </>
    )
}
export default AddTask;