import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import AppGrid from 'src/components/App/AppGrid';
import { Button } from 'src/components/shared';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchMessagesRequest,deleteMessageRequest } from '../../store/appActions'; 
import storage from 'src/utils/storageUtils';
import toast from 'src/utils/toast';
import Paging from 'src/components/App/Paging';

function MessageContainer() {
  const [messages, setMessages] = useState([]);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [page, setPage] = useState(1);  
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const token = storage.get('TOKEN'); 
      const response = await new Promise(
        (resolve, reject) => dispatch(fetchMessagesRequest(token,page,perPage, resolve, reject)),
      );
      if (response && response.data) {
        setMessages(response.data); 
        setTotalPages(response.meta.totalPages)
      }
    };

    fetchData();
  }, [dispatch,page,perPage]);

  const handleActionMenu = (event, id) => {
    console.log("Opening action menu for message ID:", id);
    setOpen(event.currentTarget);
    setSelectedMessageId(id); 
  };

  const handleEditMessage = () => {
    if (selectedMessageId) {
      navigate(`/message/${selectedMessageId}`);
    } else {
      toast.error('message information is missing');
    }
  };

  const addmessage = () => {
    navigate('/message')
  }

  const handleMenuClose = () => {
    setOpen(null);
  };

  const handleDeleteMessage = async () => {
    try {
      await new Promise((resolve, reject) =>
        dispatch(deleteMessageRequest(selectedMessageId, resolve, reject)),
      );
      setMessages(messages.filter((message) => message.id !== selectedMessageId));
      handleMenuClose();
      toast.success('message Deleted Successfully')
    } catch (error) {
      console.error('Failed to delete message:', error);
    }
  };
  

  const columns = [
    {
        label: 'Title',
        name: 'title',
      },
    {
      label: 'Language',
      name: 'language',
    },
    {
      label: 'Message',
      name: 'message',
      
    },
    {
        label: 'Clinic Name',
        name: 'clinicName',
        options: {
          customBodyRenderLite: (dataIndex) => {
            const message = messages[dataIndex];
            return message.clinic ? message.clinic.clinicName : 'N/A';
          },
        },
      },
    {
      label: () => {
        <></>;
      },
      name: 'action',
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              <IconButton onClick={(event) => handleActionMenu(event, messages[dataIndex]?.id)}>
                <MoreVertIcon />
              </IconButton>
              <Popover
                open={!!open}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                  sx: { width: 140 },
                }}  
              >
                <MenuItem onClick={handleEditMessage}>
                  <Stack spacing={1} direction="row">
                    <EditIcon />
                    <span>Edit</span>
                  </Stack>
                </MenuItem>
                <MenuItem onClick={handleDeleteMessage} sx={{ color: 'error.main' }}>
                  <Stack spacing={1} direction="row">
                    <DeleteIcon />
                    <span>Delete</span>
                  </Stack>
                </MenuItem>
              </Popover>
            </>
          );
        },
      },
    },
  ];

  const handleCloseMenu = () => {
    setOpen(null);
  };

  return (
    <>
      <Box
        sx={{
          marginBottom: 2,
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} container>
            <Grid item xs={6}>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={addmessage} variant="contained">
                Add Message
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginBottom: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AppGrid
              columns={columns}
              data={messages}
              selectableRows="none"
              onFilterChange={() => {}}
              appliedFilters={{}}
              bulkActions={[]}
              onBulkAction={() => {}}
              onSort={() => {}}
              onClearFilters={() => {}}
              title=""
              titleProps={{}}
              pagingType="table"
            />
            <Paging
              page={page - 1}
              perPage={perPage}
              count={totalPages * perPage} 
              onChange={(newPage) => setPage(newPage + 1)}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default MessageContainer;
