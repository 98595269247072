import React from "react";
import { FormBox } from "../doctor/style";
import { Grid, Typography } from "@mui/material";
import InputComponent from 'src/components/shared/Form/Input';
import { useFormikContext } from 'formik';

function ProfessionalInfo({ handlePrevious, handleNext }) {
    const { values, handleChange, touched, errors } = useFormikContext();

    return (
        <FormBox sx={{ marginTop: '20px', marginLeft: '5%' }}>
            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                <Grid item>
                    <Typography variant="h5" fontWeight="bold">Experience</Typography>
                    <InputComponent
                        variant="outlined"
                        fullWidth
                        name="professionalInfo.experience"
                        value={values.professionalInfo.experience}
                        onChange={handleChange}
                        error={touched.professionalInfo?.experience && Boolean(errors.professionalInfo?.experience)}
                        helperText={touched.professionalInfo?.experience && errors.professionalInfo?.experience}
                    />
                </Grid>
                <Grid item>
                    <Typography variant="h5" fontWeight="bold">Licence Info</Typography>
                    <InputComponent
                        variant="outlined"
                        fullWidth
                        name="professionalInfo.licenceInfo"
                        value={values.professionalInfo.licenceInfo}
                        onChange={handleChange}
                        error={touched.professionalInfo?.licenceInfo && Boolean(errors.professionalInfo?.licenceInfo)}
                        helperText={touched.professionalInfo?.licenceInfo && errors.professionalInfo?.licenceInfo}
                    />
                </Grid>
                <Grid item>
                    <Typography variant="h5" fontWeight="bold">Professional Experience</Typography>
                    <InputComponent
                        variant="outlined"
                        fullWidth
                        name="professionalInfo.professionalExperience"
                        value={values.professionalInfo.professionalExperience}
                        onChange={handleChange}
                        error={touched.professionalInfo?.professionalExperience && Boolean(errors.professionalInfo?.professionalExperience)}
                        helperText={touched.professionalInfo?.professionalExperience && errors.professionalInfo?.professionalExperience}
                    />
                </Grid>
                <Grid item>
                    <Typography variant="h5" fontWeight="bold">Challenges</Typography>
                    <InputComponent
                        variant="outlined"
                        fullWidth
                        name="professionalInfo.challenges"
                        value={values.professionalInfo.challenges}
                        onChange={handleChange}
                        error={touched.professionalInfo?.challenges && Boolean(errors.professionalInfo?.challenges)}
                        helperText={touched.professionalInfo?.challenges && errors.professionalInfo?.challenges}
                    />
                </Grid>
                <Grid item>
                    <Typography variant="h5" fontWeight="bold">Awards Achievements</Typography>
                    <InputComponent
                        variant="outlined"
                        fullWidth
                        name="professionalInfo.awardsAchievements"
                        value={values.professionalInfo.awardsAchievements}
                        onChange={handleChange}
                        error={touched.professionalInfo?.awardsAchievements && Boolean(errors.professionalInfo?.awardsAchievements)}
                        helperText={touched.professionalInfo?.awardsAchievements && errors.professionalInfo?.awardsAchievements}
                    />
                </Grid>
                <Grid item>
                    <Typography variant="h5" fontWeight="bold">Special Procedure</Typography>
                    <InputComponent
                        variant="outlined"
                        fullWidth
                        name="professionalInfo.specialProcedure"
                        value={values.professionalInfo.specialProcedure}
                        onChange={handleChange}
                        error={touched.professionalInfo?.specialProcedure && Boolean(errors.professionalInfo?.specialProcedure)}
                        helperText={touched.professionalInfo?.specialProcedure && errors.professionalInfo?.specialProcedure}
                    />
                </Grid>
            </Grid>
        </FormBox>
    );
}

export default ProfessionalInfo;
