import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import AppGrid from 'src/components/App/AppGrid';
import { Button } from 'src/components/shared';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchBillingsRequest,deleteBillingRequest } from '../../store/appActions'; 
import storage from 'src/utils/storageUtils';
import toast from 'src/utils/toast';
import Paging from 'src/components/App/Paging';

function BillingContainer() {
  const [billings, setBillings] = useState([]);
  const [selectedBillingId, setSelectedBillingId] = useState(null);
  const [page, setPage] = useState(1);  
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);  
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const token = storage.get('TOKEN'); 
      const response = await new Promise(
        (resolve, reject) => dispatch(fetchBillingsRequest(token,page,perPage, resolve, reject)),
      );
      if (response && response.data) {
        setBillings(response.data); 
        setTotalPages(response.meta.totalPages); 
      }
    };

    fetchData();
  }, [dispatch,page,perPage]);

  const addBillingRecord = (event) => {
    navigate('/billing'); 
  };

  const handleEditBilling = () => {
    if (selectedBillingId) {
      navigate(`/billing/${selectedBillingId}`);
    } else {
      toast.error('Clinic information is missing');
    }
  };

  const handleActionMenu = (event, id) => {
    console.log("Opening action menu for billing ID:", id); 
    setOpen(event.currentTarget);
    setSelectedBillingId(id); 
  };
  

  const handleDeleteBilling = async () => {
    const response = await new Promise((resolve, reject) =>
      dispatch(deleteBillingRequest(selectedBillingId, resolve, reject)),
    );
    if (response) {
      setBillings(billings.filter((billing) => billing.id !== selectedBillingId));
      handleCloseMenu();
      toast.success('Billing Deleted Successfully')

    }
  };


  const columns = [
    {
        label: 'Patient Name',
        name: 'patientName',
       
      },
    {
      label: 'Payment For',
      name: 'paymentFor',
    },
    {
      label: 'Amount',
      name: 'amount',
    },
    {
      label: 'Date',
      name: 'date',
    },
    {
      label: 'Type',
      name: 'type',
    },
    {
      label: 'Method',
      name: 'method',
    },
    {
      label: 'User Name',
      name: 'userName',
      
    },
    {
      label: () => {
        <></>;
      },
      name: 'action',
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              <IconButton onClick={(event) => handleActionMenu(event, billings[dataIndex]?.id)}>
                <MoreVertIcon />
              </IconButton>
              <Popover
                open={!!open}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                  sx: { width: 140 },
                }}
              >
                <MenuItem onClick={handleEditBilling}>
                  <Stack spacing={1} direction="row">
                    <EditIcon />
                    <span>Edit</span>
                  </Stack>
                </MenuItem>
                <MenuItem onClick={handleDeleteBilling} sx={{ color: 'error.main' }}>
                  <Stack spacing={1} direction="row">
                    <DeleteIcon />
                    <span>Delete</span>
                  </Stack>
                </MenuItem>
              </Popover>
            </>
          );
        },
      },
    },
  ];

  const handleCloseMenu = () => {
    setOpen(null);
  };

  return (
    <>
      <Box sx={{ marginBottom: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} container>
            <Grid item xs={6}>
              {/* Add any additional filters or controls here */}
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" onClick={addBillingRecord}>
                Add Billing Record
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginBottom: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AppGrid
              columns={columns}
              data={billings}
              selectableRows="none"
              onFilterChange={() => {}}
              appliedFilters={{}}
              bulkActions={[]}
              onBulkAction={() => {}}
              onSort={() => {}}
              onClearFilters={() => {}}
              title=""
              titleProps={{}}
              pagingType="table"
            />
            <Paging
              page={page - 1}
              perPage={perPage}
              count={totalPages * perPage} 
              onChange={(newPage) => setPage(newPage + 1)}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default BillingContainer;
