import React from "react";
import { FormBox } from "../doctor/style";
import { Grid, Typography, Button } from "@mui/material";
import InputComponent from 'src/components/shared/Form/Input';
import Select from 'src/components/shared/Form/Select';
import AppTimePicker from 'src/components/shared/Form/Timepicker';
import SwitchComponent from 'src/components/shared/Form/Switch';
import { useFormikContext } from 'formik';

const days = [
    { label: 'Monday', value: 'Monday' },
    { label: 'Tuesday', value: 'Tuesday' },
    { label: 'Wednesday', value: 'Wednesday' },
    { label: 'Thursday', value: 'Thursday' },
    { label: 'Friday', value: 'Friday' },
    { label: 'Saturday', value: 'Saturday' },
    { label: 'Sunday', value: 'Sunday' },
    { label: 'Monday-Friday', value: 'Monday-Friday' },
    { label: 'Saturday-Sunday', value: 'Saturday-Sunday' },
];

function Availability({ handlePrevious, handleNext }) {
    const { values, setFieldValue, touched, errors } = useFormikContext();

    const handleAddOpeningHour = () => {
        setFieldValue('doctorTimings.timings', [
            ...values.doctorTimings.timings,
            { day: '', startTime: '', endTime: '' },
        ]);
    };

    const handleRemoveOpeningHour = (index) => {
        const newTimings = values.doctorTimings.timings.filter((_, i) => i !== index);
        setFieldValue('doctorTimings.timings', newTimings);
    };

    return (
        <FormBox sx={{ marginTop: '20px', marginLeft: '5%' }}>
            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                <Typography variant="h5" fontWeight="bold">Opening Hours</Typography>
                {values.doctorTimings.timings.map((openingHour, index) => (
                    <Grid container spacing={2} key={index}>
                        <Grid item xs={4}>
                            <Select
                                variant="outlined"
                                fullWidth
                                name={`doctorTimings.timings[${index}].day`}
                                value={openingHour.day}
                                onChange={(event) => setFieldValue(`doctorTimings.timings[${index}].day`, event.target.value)}
                                options={days}
                                error={touched.doctorTimings?.timings?.[index]?.day && Boolean(errors.doctorTimings?.timings?.[index]?.day)}
                                helperText={touched.doctorTimings?.timings?.[index]?.day && errors.doctorTimings?.timings?.[index]?.day}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AppTimePicker
                                variant="outlined"
                                fullWidth
                                value={openingHour.startTime}
                                onChange={(value) => setFieldValue(`doctorTimings.timings[${index}].startTime`, value)}
                                renderInput={(params) => <InputComponent {...params} />}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AppTimePicker
                                variant="outlined"
                                fullWidth
                                value={openingHour.endTime}
                                onChange={(value) => setFieldValue(`doctorTimings.timings[${index}].endTime`, value)}
                                renderInput={(params) => <InputComponent {...params} />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="outlined" color="error" onClick={() => handleRemoveOpeningHour(index)}>
                                Remove
                            </Button>
                        </Grid>
                    </Grid>
                ))}

                <Grid item>
                    <Button onClick={handleAddOpeningHour}>
                        Add Opening Hour
                    </Button>
                </Grid>

                <Grid item>
                    <Typography variant="h6" fontWeight="bold" display="inline">
                        Accepting Appointments
                    </Typography>
                    <SwitchComponent
                        checked={values.doctorTimings.acceptingAppointments === 'yes'}
                        onChange={(event) =>
                            setFieldValue('doctorTimings.acceptingAppointments', event.target.checked ? 'yes' : 'no')
                        }
                    />

                </Grid>

            </Grid>
        </FormBox>
    );
}

export default Availability;
