import Icon from 'src/components/App/Icon';

export const menuItems = [
  {
    title: 'Dashboard',
    link: '/',
    icon: <Icon name="dashboard" />,
  },
  {
    title: 'Doctor',
    link: '/doctors',
    icon: <Icon name="doctor" />,
  },
  {
    key: 'appointment',
    title: 'Appointment',
    link: '/appointments',
    icon: <Icon name="appointment" />,
  },
  {
    key: 'patients',
    title: 'Patients',
    link: '/patients',
    icon: <Icon name="patients" />,
  },
  {
    key: 'staff',
    title: 'Staff',
    link: '/staffs',
    icon: <Icon name="staff" />,
  },
  {
    key: 'tasks',
    title: 'Tasks',
    link: '/tasks',
    icon: <Icon name="tasks" />,
  },
  {
    key: 'clinics',
    title: 'Clinic',
    link: '/clinics',
    icon: <Icon name="clinics" />,
  },
  {
    key: 'settings',
    title: 'Settings',
    link: '/settings',
    icon: <Icon name="settings" />,
  },
  {
    key: 'billing',
    title: 'Billing & payments',
    link: '/billings',
    icon: <Icon name="billing" />,
  },
  {
    key: 'area',
    title: 'Area',
    link: '/areas',
    icon: <Icon name="area" />,
  },
  {
    key: 'tag',
    title: 'Tag',
    link: '/tags',
    icon: <Icon name="tag" />,
  },
  {
    key: 'treatment',
    title:'Treatment',
    link: '/treatments',
    icon: <Icon name="treatment" />,
  },
  {
    key: 'permissions',
    title:'Permissions',
    link: '/permissions',
    icon: <Icon name="permission" />,
  },
  {
    key: 'templates',
    title:'Templates',
    link: '/templates',
    icon: <Icon name="template" />,
  },
  {
    key: 'medicalrecords',
    title:'Medicalrecords',
    link: '/medicalrecords',
    icon: <Icon name="medicalrecords" />,
  },
  {
    key: 'followups',
    title:'Followups',
    link: '/followups',
    icon: <Icon name="followup" />,
  },
  {
    key: 'messages',
    title:'Messages',
    link: '/messages',
    icon: <Icon name="messages" />,
  },
  {
    key: 'notifications',
    title:'Notifications',
    link: '/notifications',
    icon: <Icon name="notifications" />,
  },
  {
    key: 'notificationSettings',
    title:'Notification Settings',
    link: '/notificationSettings',
    icon: <Icon name="notificationSetting" />,
  },
  {
    key: 'patientinfos',
    title:'Patient Info',
    link: '/patientinfos',
    icon: <Icon name="patientinfo" />,
  },
  
];
