import React, { useState, useEffect, useMemo } from 'react';
import { FormBox } from '../doctor/style';
import * as Yup from 'yup';
import { Grid, Typography, MenuItem, Select } from '@mui/material';
import { Button } from 'src/components/shared';
import { addTagRequest, updateTagRequest, fetchTagByIdRequest, fetchDoctornameRequest, fetchClinicnameRequest, fetchPatientnameRequest, fetchPatientByUserIdRequest, fetchClinicByUserIdRequest } from '../../store/appActions';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useParams, useNavigate } from 'react-router-dom';
import toast from "src/utils/toast";
import InputAutocomplete from 'src/components/App/AppGrid/Filter/Dropdown';
import InputComponent from 'src/components/shared/Form/Input';

const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

function Tag() {
    const dispatch = useDispatch();
    const doctors = useSelector((state) => state.app.doctors );
    const [availablePatients, setAvailablePatients] = useState([]);
    const [availableClinics, setAvailableClinics] = useState([]);
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        tag: '',
        user: '',
        clinic: '',
        patient: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([
                    new Promise((resolve, reject) => dispatch(fetchDoctornameRequest('', resolve, reject))),
                ]);

                if (id) {
                    const tag = await new Promise((resolve, reject) => {
                        dispatch(fetchTagByIdRequest(id, (tag) => {
                            setInitialValues({
                                tag: tag.tag || '',
                                user: tag.user || '',
                                clinic: tag.clinic || '',
                                patient: tag.patient || '',
                            });
                            resolve(tag);
                        }, reject));
                    });
                    handleFetchPatients(tag.user);
                    handleFetchClinics(tag.user);
                }
                setIsLoading(false);
            } catch (error) {
                enqueueSnackbar(error.message || 'Failed to load data', { variant: 'error' });
                setIsLoading(false);
            }
        };

        fetchData();
    }, [dispatch, id, enqueueSnackbar]);

    const handleFetchPatients = (userId) => {
        console.log('Fetching patients for userId:', userId);
        dispatch(fetchPatientByUserIdRequest(userId, (data) => {
            console.log('Fetched patients:', data);
            setAvailablePatients(data.map((patient) => ({ label: patient.name, value: patient.id })));
        }, (error) => {
            enqueueSnackbar('Failed to fetch patients', { variant: 'error' });
        }));
    };

    const handleFetchClinics = (userId) => {
        console.log('Fetching clinics for userId:', userId);
        dispatch(fetchClinicByUserIdRequest(userId, (data) => {
            console.log('Fetched clinics:', data);
            setAvailableClinics(data.map((clinic) => ({ label: clinic.name, value: clinic.id })));
        }, (error) => {
            enqueueSnackbar('Failed to fetch clinics', { variant: 'error' });
        }));
    };

    const handleSubmit = async (values, { resetForm }) => {
        const tagData = { ...values, tag: values.tag.split(',').map(t => t.trim()) };

        try {
            if (id) {
                await new Promise((resolve, reject) => {
                    dispatch(updateTagRequest(id, tagData, resolve, reject));
                });
                toast.success('Tag updated successfully');
            } else {
                await new Promise((resolve, reject) => {
                    dispatch(addTagRequest(tagData, resolve, reject));
                });
                toast.success('Tag added successfully');
            }

            resetForm();
            navigate('/tags');
        } catch (error) {
            console.log(error);
        }
    };

    const doctorOptions = useMemo(() => (
        doctors.map((doctor) => ({ label: doctor.name, value: doctor.id }))
    ), [doctors]);

    const handleSearch = (searchTerm, fetchFunc) => {
        if (searchTerm && typeof searchTerm === 'string' && searchTerm.trim().length >= 3) {
            return new Promise((resolve, reject) => {
                dispatch(fetchFunc(searchTerm.trim(), resolve, reject));
            });
        }
    };

    const debouncedSearchDoctor = useMemo(() => debounce((term) => handleSearch(term, fetchDoctornameRequest), 300), []);
    const debouncedSearchClinic = useMemo(() => debounce((term) => handleSearch(term, fetchClinicnameRequest), 300), []);
    const debouncedSearchPatient = useMemo(() => debounce((term) => handleSearch(term, fetchPatientnameRequest), 300), []);


    const handleInputChange = (searchTerm, type) => {
        console.log(`User is searching for ${type}:`, searchTerm);
        if (type === 'doctor') {
            debouncedSearchDoctor(searchTerm);
        } else if (type === 'clinic') {
            debouncedSearchClinic(searchTerm);
        } else if (type === 'patient') {
            debouncedSearchPatient(searchTerm);
        }
    };

    return (
        <FormBox sx={{ marginTop: '20px' }}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    tag: Yup.string().required("Please enter the tag!"),
                    user: Yup.string().required('Please select a user!'),
                    clinic: Yup.string().required('Please select a clinic!'),
                    patient: Yup.string().required('Please select a patient!'),
                })}
                onSubmit={handleSubmit}
            >
                {({ values, handleChange, handleSubmit, touched, errors, isValid, setFieldValue }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                            <Typography
                                variant="h4"
                                color='#393A96'
                                fontWeight='bold'
                            >
                                {id ? 'Edit Tag' : 'Add Tag'}
                            </Typography>

                            <Typography variant="h5" fontWeight="bold">
                                Tag
                            </Typography>
                            <InputComponent
                                variant="outlined"
                                fullWidth
                                name="tag"
                                value={values.tag}
                                onChange={handleChange}
                                error={touched.tag && Boolean(errors.tag)}
                                helperText={touched.tag && errors.tag}
                            />

                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    User
                                </Typography>
                                <InputAutocomplete
                                    variant="outlined"
                                    fullWidth
                                    name="user"
                                    value={doctorOptions.find(doctor => doctor.value === values.user) || null}
                                    onChange={(value) => {
                                        setFieldValue("user", value ? value.value : '');
                                        handleFetchPatients(value ? value.value : '');
                                        handleFetchClinics(value ? value.value : '');
                                    }}
                                    options={doctorOptions}
                                    onInputChange={(event, value) => handleInputChange(value, 'doctor')}
                                    placeholder="Select a doctor"
                                    error={touched.user && Boolean(errors.user)}
                                    helperText={touched.user && errors.user}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    Clinic
                                </Typography>
                                <InputAutocomplete
                                    variant="outlined"
                                    fullWidth
                                    name="clinic"
                                    value={availableClinics.find(clinic => clinic.value === values.clinic) || null}
                                    onChange={(value) => setFieldValue("clinic", value ? value.value : '')}
                                    options={availableClinics}
                                    onInputChange={(event, value) => handleInputChange(value, 'clinic')}
                                    placeholder="Select a clinic"
                                    error={touched.clinic && Boolean(errors.clinic)}
                                    helperText={touched.clinic && errors.clinic}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    Patient
                                </Typography>
                                <InputAutocomplete
                                    variant="outlined"
                                    fullWidth
                                    name="patient"
                                    value={availablePatients.find(patient => patient.value === values.patient) || null}
                                    onChange={(value) => setFieldValue("patient", value ? value.value : '')}
                                    options={availablePatients}
                                    onInputChange={(event, value) => handleInputChange(value, 'patient')}
                                    placeholder="Select a patient"
                                    error={touched.patient && Boolean(errors.patient)}
                                    helperText={touched.patient && errors.patient}
                                />
                            </Grid>

                            <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '10px' }}>
                                <Button type="submit" variant="contained" sx={{ textAlign: 'center' }} disabled={!isValid}>
                                    {isLoading ? (id ? 'Updating...' : 'Saving...') : (id ? 'Update Tag' : 'Add Tag')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </FormBox>
    );
}

export default Tag;
