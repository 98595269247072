import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import {
    Typography,
    Grid,
    Tooltip,
    CircularProgress
} from '@mui/material';
import Select from 'src/components/shared/Form/Select';
import CheckboxInput from 'src/components/shared/Form/Checkbox';
import { Button } from 'src/components/shared';
import { Formik } from 'formik';
import { addPermissionRequest, fetchDoctornameRequest, updatePermissionRequest, fetchPermissionByIdRequest } from '../../store/appActions';
import { FormBox } from '../doctor/style';
import toast from 'src/utils/toast';
import storage from 'src/utils/storageUtils';
import InputAutocomplete from 'src/components/App/AppGrid/Filter/Dropdown';

const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

function AddPermission() {
    const dispatch = useDispatch();
    const doctors = useSelector((state) => state.app.doctors || []);
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();
    const [initialValues, setInitialValues] = useState({
        notes: false,
        prescriptions: false,
        followUp: false,
        treatment: false,
        messages: false,
        reports: false,
        token: false,
        appointment: false,
        patients: false,
        case: false,
        billing: false,
        task: false,
        area: false,
        patientInfo: false,
        pharmacy: false,
        laboratory: false,
        clinic: false,
        staff: false,
        review: false,
        user: '',
    });

    const navigate = useNavigate();

    useEffect(() => {
        const fetchDoctors = async () => {
            try {
                await new Promise((resolve, reject) => {
                    dispatch(fetchDoctornameRequest('', resolve, reject));
                });
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        };

        const fetchPermission = async (permissionId) => {
            try {
                const token = storage.get('TOKEN');
                await new Promise((resolve, reject) => {
                    dispatch(fetchPermissionByIdRequest(permissionId,
                        (permission) => {
                            const convertedPermission = Object.keys(permission).reduce((acc, key) => {
                                acc[key] = permission[key] === 'yes';
                                return acc;
                            }, {});

                            setInitialValues({
                                ...convertedPermission,
                                user: permission.user || '',
                            });
                        },
                        (error) => console.error('Error fetching permission:', error)
                    ));
                });
            } catch (error) {
                console.log(error);
            }
        };


        fetchDoctors();

        if (id) {
            fetchPermission(id);
        } else {
            setIsLoading(false);
        }
    }, [dispatch, id]);


    const handleSubmit = async (values, { resetForm }) => {
        const { user, ...permissionValues } = values;

        const filteredValues = Object.keys(permissionValues).reduce((acc, key) => {
            if (key !== 'createdAt' && key !== 'updatedAt' && key !== 'status' && key !== 'id') {
                acc[key] = permissionValues[key] ? 'yes' : 'no';
            }
            return acc;
        }, {});

        if (user) {
            filteredValues.user = user;
        }

        const token = storage.get('TOKEN');

        try {
            if (id) {
                await new Promise((resolve, reject) => {
                    dispatch(updatePermissionRequest(id, filteredValues, resolve, reject));
                });
                toast.success('Permission updated successfully');
            } else {
                await new Promise((resolve, reject) => {
                    dispatch(addPermissionRequest(filteredValues, resolve, reject));
                });
                toast.success('Permission added successfully');
            }

            resetForm();
            navigate('/permissions');
        } catch (error) {
            console.log(error);
        }
    };


    const doctorOptions = useMemo(() => (
        doctors.map((doctor) => ({
            label: doctor.name,
            value: doctor.id,
        }))
    ), [doctors]);


    const handleSearchDoctor = async (searchTerm) => {
        if (searchTerm && typeof searchTerm === 'string' && searchTerm.trim().length >= 3) {
            try {
                await new Promise((resolve, reject) => {
                    dispatch(fetchDoctornameRequest(searchTerm.trim(), resolve, reject));
                });
            } catch (error) {
                console.error('Search error:', error);
            }
        }
    };



    const debouncedSearch = useMemo(() => debounce(handleSearchDoctor, 300), [handleSearchDoctor]);


    const handleInputChange = (searchTerm) => {
        console.log('User is searching for:', searchTerm);
        onSearchChange(searchTerm);
    };

    const onSearchChange = (searchTerm) => {
        console.log('User is searching for:', searchTerm);
        debouncedSearch(searchTerm);
    };


    if (isLoading) {
        return (
            <FormBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </FormBox>
        );
    }

    return (
        <FormBox>
            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={Yup.object().shape({
                        notes: Yup.boolean(),
                        prescriptions: Yup.boolean(),
                        followUp: Yup.boolean(),
                        treatment: Yup.boolean(),
                        messages: Yup.boolean(),
                        reports: Yup.boolean(),
                        token: Yup.boolean(),
                        appointment: Yup.boolean(),
                        patients: Yup.boolean(),
                        case: Yup.boolean(),
                        billing: Yup.boolean(),
                        task: Yup.boolean(),
                        area: Yup.boolean(),
                        patientInfo: Yup.boolean(),
                        pharmacy: Yup.boolean(),
                        laboratory: Yup.boolean(),
                        clinic: Yup.boolean(),
                        staff: Yup.boolean(),
                        review: Yup.boolean(),
                        user: Yup.string().required('Please select a doctor!'),
                    })}
                    onSubmit={handleSubmit}
                    context={{ isAddMode: !id }}
                >
                    {({ values, touched, errors, setFieldValue, handleSubmit }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <Typography
                                variant='h4'
                                color='#393A96'
                                fontWeight='bold'
                                sx={{ marginLeft: '2%' }}
                            >
                                {id ? 'Edit Permission' : 'Add Permission'}
                            </Typography>

                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    User
                                </Typography>
                                <InputAutocomplete
                                    variant="outlined"
                                    fullWidth
                                    name="user"
                                    value={doctorOptions.find(doctor => doctor.value === values.user) || null}
                                    onChange={(value) => setFieldValue("user", value ? value.value : '')}
                                    options={doctorOptions}
                                    onInputChange={(event, value) => handleInputChange(value)}
                                    placeholder="Select a doctor"
                                    error={touched.user && Boolean(errors.user)}
                                    helperText={touched.user && errors.user}
                                />
                            </Grid>

                            <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                                {[{
                                    name: 'notes',
                                    label: 'Notes'
                                }, {
                                    name: 'prescriptions',
                                    label: 'Prescriptions'
                                }, {
                                    name: 'followUp',
                                    label: 'Follow-Up'
                                }, {
                                    name: 'treatment',
                                    label: 'Treatment'
                                }, {
                                    name: 'messages',
                                    label: 'Messages'
                                }, {
                                    name: 'reports',
                                    label: 'Reports'
                                }, {
                                    name: 'token',
                                    label: 'Token'
                                }, {
                                    name: 'appointment',
                                    label: 'Appointment'
                                }, {
                                    name: 'patients',
                                    label: 'Patients'
                                }, {
                                    name: 'case',
                                    label: 'Case'
                                }, {
                                    name: 'billing',
                                    label: 'Billing'
                                }, {
                                    name: 'task',
                                    label: 'Task'
                                }, {
                                    name: 'area',
                                    label: 'Area'
                                }, {
                                    name: 'patientInfo',
                                    label: 'Patient Info'
                                }, {
                                    name: 'pharmacy',
                                    label: 'Pharmacy'
                                }, {
                                    name: 'laboratory',
                                    label: 'Laboratory'
                                }, {
                                    name: 'clinic',
                                    label: 'Clinic'
                                }, {
                                    name: 'staff',
                                    label: 'Staff'
                                }, {
                                    name: 'review',
                                    label: 'Review'
                                }].map((field) => (
                                    <Grid item xs={6} key={field.name}>
                                        <Grid container alignItems="center">
                                            <Grid item xs>
                                                <Typography variant='h5' fontWeight='bold'>
                                                    {field.label}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Tooltip title={`Toggle ${field.label.toLowerCase()} access`}>
                                                    <CheckboxInput
                                                        name={field.name}
                                                        checked={values[field.name]}
                                                        onChange={(checked) => setFieldValue(field.name, checked)}
                                                        sx={{ position: 'relative', right: '68px' }}
                                                    />
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>

                            <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '30px' }}>
                                <Tooltip title={id ? "Update the permission" : "Add the permission"} arrow>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ textAlign: 'center' }}
                                        disabled={!id && !values.user}
                                    >
                                        {id ? 'Update Permission' : 'Add Permission'}
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </Grid>
        </FormBox>
    );
}

export default AddPermission;
