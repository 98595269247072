import React from 'react';
import { FormBox } from '../doctor/style';
import { Grid, Typography, MenuItem } from '@mui/material';
import InputComponent from 'src/components/shared/Form/Input';
import SwitchComponent from 'src/components/shared/Form/Switch';
import { useFormikContext } from 'formik';
import Select from 'src/components/shared/Form/Select';

const acceptingTokensOptions = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
    { label: 'Not Applicable', value: 'NA' },
];

function ClinicSettings() {
    const { values, setFieldValue, touched, errors } = useFormikContext();

    return (
        <FormBox sx={{ marginTop: '20px', marginLeft: '5%' }}>
            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5" fontWeight="bold">Accepting Tokens</Typography>
                        <Select
                            variant="outlined"
                            fullWidth
                            name="clinicSetting.acceptingTokens"
                            value={values.clinicSetting.acceptingTokens}
                            onChange={(event) => setFieldValue('clinicSetting.acceptingTokens', event.target.value)}
                            options={acceptingTokensOptions}
                            error={touched.clinicSetting?.acceptingTokens && Boolean(errors.clinicSetting?.acceptingTokens)}
                            helperText={touched.clinicSetting?.acceptingTokens && errors.clinicSetting?.acceptingTokens}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" fontWeight="bold">Follow-up Task Days</Typography>
                        <InputComponent
                            fullWidth
                            variant="outlined"
                            name="clinicSetting.followUpTaskDays"
                            type="number"
                            value={values.clinicSetting.followUpTaskDays}
                            onChange={(event) => setFieldValue('clinicSetting.followUpTaskDays', event.target.value)}
                            error={touched.clinicSetting?.followUpTaskDays && Boolean(errors.clinicSetting?.followUpTaskDays)}
                            helperText={touched.clinicSetting?.followUpTaskDays && errors.clinicSetting?.followUpTaskDays}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="h6" display="inline">Medicine Reminder Task</Typography>
                        <SwitchComponent
                            checked={values.clinicSetting.medicineReminderTask}
                            onChange={(event) => setFieldValue('clinicSetting.medicineReminderTask', event.target.checked)}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="h6" display="inline">Treatment Scheduling Task</Typography>
                        <SwitchComponent
                            checked={values.clinicSetting.treatmentSchedulingTask}
                            onChange={(event) => setFieldValue('clinicSetting.treatmentSchedulingTask', event.target.checked)}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="h6" display="inline">New Case Follow-up Task</Typography>
                        <SwitchComponent
                            checked={values.clinicSetting.newCaseFollowUpTask}
                            onChange={(event) => setFieldValue('clinicSetting.newCaseFollowUpTask', event.target.checked)}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="h6" display="inline">Accepting Patient Review</Typography>
                        <SwitchComponent
                            checked={values.clinicSetting.acceptingPatientReview}
                            onChange={(event) => setFieldValue('clinicSetting.acceptingPatientReview', event.target.checked)}
                        />
                    </Grid>


                    <Grid item xs={6}>
                        <Typography variant="h5" fontWeight="bold">Consulting Fees</Typography>
                        <InputComponent
                            fullWidth
                            variant="outlined"
                            name="clinicSetting.consultingFees"
                            type="number"
                            value={values.clinicSetting.consultingFees}
                            onChange={(event) => setFieldValue('clinicSetting.consultingFees', event.target.value)}
                            error={touched.clinicSetting?.consultingFees && Boolean(errors.clinicSetting?.consultingFees)}
                            helperText={touched.clinicSetting?.consultingFees && errors.clinicSetting?.consultingFees}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="h5" fontWeight="bold">Old Case Consulting Fees</Typography>
                        <InputComponent
                            fullWidth
                            variant="outlined"
                            name="clinicSetting.oldCaseConsultingFees"
                            type="number"
                            value={values.clinicSetting.oldCaseConsultingFees}
                            onChange={(event) => setFieldValue('clinicSetting.oldCaseConsultingFees', event.target.value)}
                            error={touched.clinicSetting?.oldCaseConsultingFees && Boolean(errors.clinicSetting?.oldCaseConsultingFees)}
                            helperText={touched.clinicSetting?.oldCaseConsultingFees && errors.clinicSetting?.oldCaseConsultingFees}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="h5" fontWeight="bold">Case Validity Days</Typography>
                        <InputComponent
                            fullWidth
                            variant="outlined"
                            name="clinicSetting.caseValidityDays"
                            type="number"
                            value={values.clinicSetting.caseValidityDays}
                            onChange={(event) => setFieldValue('clinicSetting.caseValidityDays', event.target.value)}
                            error={touched.clinicSetting?.caseValidityDays && Boolean(errors.clinicSetting?.caseValidityDays)}
                            helperText={touched.clinicSetting?.caseValidityDays && errors.clinicSetting?.caseValidityDays}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="h5" fontWeight="bold">Emergency Fees</Typography>
                        <InputComponent
                            fullWidth
                            variant="outlined"
                            name="clinicSetting.emergencyFees"
                            type="number"
                            value={values.clinicSetting.emergencyFees}
                            onChange={(event) => setFieldValue('clinicSetting.emergencyFees', event.target.value)}
                            error={touched.clinicSetting?.emergencyFees && Boolean(errors.clinicSetting?.emergencyFees)}
                            helperText={touched.clinicSetting?.emergencyFees && errors.clinicSetting?.emergencyFees}
                        />
                    </Grid>

                </Grid>
            </Grid>
        </FormBox>
    );
}

export default ClinicSettings;
