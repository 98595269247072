import React from 'react';
import { Grid, Typography, Box } from "@mui/material";
import { FormBox } from '../doctor/style';
import InputComponent from 'src/components/shared/Form/Input';
import { useFormikContext } from 'formik';
import CheckboxInput from 'src/components/shared/Form/Checkbox';

function TaskSettings() {
    const { values, setFieldValue,handleChange } = useFormikContext();

    return (
        <FormBox sx={{ marginTop: '20px', marginLeft: '5%' }}>
            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Box display="flex" alignItems="center">
                            <CheckboxInput
                                name="taskSetting.followUpTask"
                                checked={values.taskSetting.followUpTask}
                                onChange={(checked) => setFieldValue('taskSetting.followUpTask', checked)} />
                            <Typography variant="h5" fontWeight="bold" sx={{ marginLeft: 1 }}>Follow-up Task</Typography>
                        </Box>
                        <Typography variant="h5">Frequency (Days)</Typography>
                        <InputComponent
                            variant="outlined"
                            name="taskSetting.followUpTaskFrequency"
                            value={values.taskSetting.followUpTaskFrequency}
                            onChange={handleChange}
                            fullWidth
                        />
                        <Typography variant="h5">Count</Typography>
                        <InputComponent
                            variant="outlined"
                            name="taskSetting.followUpTaskCount"
                            value={values.taskSetting.followUpTaskCount}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Box display="flex" alignItems="center">
                            <CheckboxInput
                                name="taskSetting.treatmentSchedulingReminderTask"
                                checked={values.taskSetting.treatmentSchedulingReminderTask}
                                onChange={(checked) => setFieldValue('taskSetting.treatmentSchedulingReminderTask', checked)} />
                            <Typography variant="h5" fontWeight="bold" sx={{ marginLeft: 1 }}>Treatment Scheduling Reminder Task</Typography>
                        </Box>
                        <Typography variant="h5">Frequency (Days)</Typography>
                        <InputComponent
                            variant="outlined"
                            name="taskSetting.treatmentSchedulingReminderTaskFrequency"
                            value={values.taskSetting.treatmentSchedulingReminderTaskFrequency}
                            onChange={handleChange}
                            fullWidth
                        />
                        <Typography variant="h5">Count</Typography>
                        <InputComponent
                            variant="outlined"
                            name="taskSetting.treatmentSchedulingReminderTaskCount"
                            value={values.taskSetting.treatmentSchedulingReminderTaskCount}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Box display="flex" alignItems="center">
                            <CheckboxInput
                                name="taskSetting.patientFeedbackTask"
                                checked={values.taskSetting.patientFeedbackTask}
                                onChange={(checked) => setFieldValue('taskSetting.patientFeedbackTask', checked)} />
                            <Typography variant="h5" fontWeight="bold" sx={{ marginLeft: 1 }}>Patient Feedback Task</Typography>
                        </Box>
                        <Typography variant="h5">Frequency (Days)</Typography>
                        <InputComponent
                            variant="outlined"
                            name="taskSetting.patientFeedbackTaskFrequency"
                            value={values.taskSetting.patientFeedbackTaskFrequency}
                            onChange={handleChange}
                            fullWidth
                        />
                        <Typography variant="h5">Count</Typography>
                        <InputComponent
                            variant="outlined"
                            name="taskSetting.patientFeedbackTaskCount"
                            value={values.taskSetting.patientFeedbackTaskCount}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Box display="flex" alignItems="center">
                            <CheckboxInput
                                name="taskSetting.appointmentReminderTask"
                                checked={values.taskSetting.appointmentReminderTask}
                                onChange={(checked) => setFieldValue('taskSetting.appointmentReminderTask', checked)} />
                            <Typography variant="h5" fontWeight="bold" sx={{ marginLeft: 1 }}>Appointment Reminder Task</Typography>
                        </Box>
                        <Typography variant="h5">Frequency (Days)</Typography>
                        <InputComponent
                            variant="outlined"
                            name="taskSetting.appointmentReminderTaskFrequency"
                            value={values.taskSetting.appointmentReminderTaskFrequency}
                            onChange={handleChange}
                            fullWidth
                        />
                        <Typography variant="h5">Count</Typography>
                        <InputComponent
                            variant="outlined"
                            name="taskSetting.appointmentReminderTaskCount"
                            value={values.taskSetting.appointmentReminderTaskCount}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Box display="flex" alignItems="center">
                            <CheckboxInput
                                name="taskSetting.paymentPendingReminderTask"
                                checked={values.taskSetting.paymentPendingReminderTask}
                                onChange={(checked) => setFieldValue('taskSetting.paymentPendingReminderTask', checked)} />
                            <Typography variant="h5" fontWeight="bold" sx={{ marginLeft: 1 }}>Payment Pending Reminder Task</Typography>
                        </Box>
                        <Typography variant="h5">Frequency (Days)</Typography>
                        <InputComponent
                            variant="outlined"
                            name="taskSetting.paymentPendingReminderTaskFrequency"
                            value={values.taskSetting.paymentPendingReminderTaskFrequency}
                            onChange={handleChange}
                            fullWidth
                        />
                        <Typography variant="h5">Count</Typography>
                        <InputComponent
                            variant="outlined"
                            name="taskSetting.paymentPendingReminderTaskCount"
                            value={values.taskSetting.paymentPendingReminderTaskCount}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Box display="flex" alignItems="center">
                            <CheckboxInput
                                name="taskSetting.medicineReminderTask"
                                checked={values.taskSetting.medicineReminderTask}
                                onChange={(checked) => setFieldValue('taskSetting.medicineReminderTask', checked)} />
                            <Typography variant="h5" fontWeight="bold" sx={{ marginLeft: 1 }}>Medicine Reminder Task</Typography>
                        </Box>
                        <Typography variant="h5">Frequency (Days)</Typography>
                        <InputComponent
                            variant="outlined"
                            name="taskSetting.medicineReminderTaskFrequency"
                            value={values.taskSetting.medicineReminderTaskFrequency}
                            onChange={handleChange}
                            fullWidth
                        />
                        <Typography variant="h5">Count</Typography>
                        <InputComponent
                            variant="outlined"
                            name="taskSetting.medicineReminderTaskCount"
                            value={values.taskSetting.medicineReminderTaskCount}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Box display="flex" alignItems="center">
                            <CheckboxInput
                                name="taskSetting.healthCheckupTask"
                                checked={values.taskSetting.healthCheckupTask}
                                onChange={(checked) => setFieldValue('taskSetting.healthCheckupTask', checked)} />
                            <Typography variant="h5" fontWeight="bold" sx={{ marginLeft: 1 }}>Health Checkup Task</Typography>
                        </Box>
                        <Typography variant="h5">Frequency (Days)</Typography>
                        <InputComponent
                            variant="outlined"
                            name="taskSetting.healthCheckupTaskFrequency"
                            value={values.taskSetting.healthCheckupTaskFrequency}
                            onChange={handleChange}
                            fullWidth
                        />
                        <Typography variant="h5">Count</Typography>
                        <InputComponent
                            variant="outlined"
                            name="taskSetting.healthCheckupTaskCount"
                            value={values.taskSetting.healthCheckupTaskCount}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Box display="flex" alignItems="center">
                            <CheckboxInput
                                name="taskSetting.appointmentReschedulingTask"
                                checked={values.taskSetting.appointmentReschedulingTask}
                                onChange={(checked) => setFieldValue('taskSetting.appointmentReschedulingTask', checked)} />
                            <Typography variant="h5" fontWeight="bold" sx={{ marginLeft: 1 }}>Appointment Rescheduling Task</Typography>
                        </Box>
                        <Typography variant="h5">Frequency (Days)</Typography>
                        <InputComponent
                            variant="outlined"
                            name="taskSetting.appointmentReschedulingTaskFrequency"
                            value={values.taskSetting.appointmentReschedulingTaskFrequency}
                            onChange={handleChange}
                            fullWidth
                        />
                        <Typography variant="h5">Count</Typography>
                        <InputComponent
                            variant="outlined"
                            name="taskSetting.appointmentReschedulingTaskCount"
                            value={values.taskSetting.appointmentReschedulingTaskCount}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
        </FormBox>
    );
}

export default TaskSettings;
