import React from "react";
import { FormBox } from "../doctor/style";
import { Grid, Typography, Box } from "@mui/material";
import InputComponent from 'src/components/shared/Form/Input';
import { Button } from 'src/components/shared';
import { useFormikContext, FieldArray } from 'formik';
import { AddCircle } from "@mui/icons-material";

function ClinicLinks({ handlePrevious, handleNext }) {
    const { values, handleChange, touched, errors, setFieldValue } = useFormikContext();


    return (
        <FormBox sx={{ marginTop: '20px', marginLeft: '5%' }}>
            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                <FieldArray name="clinicLink">
                    {({ push, remove }) => (
                        <>
                            {values.clinicLink && values.clinicLink.length > 0 ? (
                                values.clinicLink.map((url, index) => (
                                    <Box key={index} component="div">
                                        <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                                            <Grid item>
                                                <Typography variant="h5" fontWeight="bold">
                                                    Link Title
                                                </Typography>
                                                <InputComponent
                                                    variant="outlined"
                                                    fullWidth
                                                    name={`clinicLink.${index}.linkTitle`}
                                                    value={url.linkTitle}
                                                    onChange={handleChange}
                                                    error={touched.clinicLink?.[index]?.linkTitle && Boolean(errors.clinicLink?.[index]?.linkTitle)}
                                                    helperText={touched.clinicLink?.[index]?.linkTitle && errors.clinicLink?.[index]?.linkTitle}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h5" fontWeight="bold">
                                                    Link
                                                </Typography>
                                                <InputComponent
                                                    variant="outlined"
                                                    fullWidth
                                                    name={`clinicLink.${index}.url`}
                                                    value={url.url}
                                                    onChange={handleChange}
                                                    error={touched.clinicLink?.[index]?.url && Boolean(errors.clinicLink?.[index]?.url)}
                                                    helperText={touched.clinicLink?.[index]?.url && errors.clinicLink?.[index]?.url}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => remove(index)}
                                                >
                                                    Remove Link
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))
                            ) : (
                                <Typography>No links added yet.</Typography>
                            )}
                            <Grid item xs={2}>
                                <Button
                                    startIcon={<AddCircle />}
                                    onClick={() => push({ url: '', linkTitle: '', status: 'ACTIVE' })}
                                >
                                    Add New Link
                                </Button>
                            </Grid>
                        </>
                    )}
                </FieldArray>
            </Grid>
        </FormBox>
    );
}

export default ClinicLinks;
