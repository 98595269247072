import React from "react";
import DoctorProfile from "../../components/profile/AddDoctorProfile";

function AddDoctorProfileContainer() {
    return (
        <>
            <container maxWidth='lg'>
            <DoctorProfile/>
            </container>
        </>
    )
}
export default AddDoctorProfileContainer;