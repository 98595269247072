import React from "react";
import { FormBox } from "../doctor/style"; 
import { Grid, Typography } from "@mui/material";
import InputComponent from 'src/components/shared/Form/Input';
import { useFormikContext } from 'formik';

function SEO({ handlePrevious }) {
    const { values, handleChange, touched, errors } = useFormikContext();

    return (
        <FormBox sx={{ marginTop: '20px', marginLeft: '5%' }}>
            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                <Grid item>
                    <Typography variant="h5" fontWeight="bold">Profile Title</Typography>
                    <InputComponent
                        variant="outlined"
                        fullWidth
                        name="seoPurpose.profileTitle"
                        value={values.seoPurpose.profileTitle}
                        onChange={handleChange}
                        error={touched.seoPurpose?.profileTitle && Boolean(errors.seoPurpose?.profileTitle)}
                        helperText={touched.seoPurpose?.profileTitle && errors.seoPurpose?.profileTitle}
                    />
                </Grid>
                <Grid item>
                    <Typography variant="h5" fontWeight="bold">Profile Meta Keywords</Typography>
                    <InputComponent
                        variant="outlined"
                        fullWidth
                        name="seoPurpose.profileMetaKeywords"
                        value={values.seoPurpose.profileMetaKeywords}
                        onChange={handleChange}
                        error={touched.seoPurpose?.profileMetaKeywords && Boolean(errors.seoPurpose?.profileMetaKeywords)}
                        helperText={touched.seoPurpose?.profileMetaKeywords && errors.seoPurpose?.profileMetaKeywords}
                    />
                </Grid>
                <Grid item>
                    <Typography variant="h5" fontWeight="bold">Footer Text</Typography>
                    <InputComponent
                        variant="outlined"
                        fullWidth
                        name="seoPurpose.footerText"
                        value={values.seoPurpose.footerText}
                        onChange={handleChange}
                        error={touched.seoPurpose?.footerText && Boolean(errors.seoPurpose?.footerText)}
                        helperText={touched.seoPurpose?.footerText && errors.seoPurpose?.footerText}
                    />
                </Grid>
                <Grid item>
                    <Typography variant="h5" fontWeight="bold">Top Keywords</Typography>
                    <InputComponent
                        variant="outlined"
                        fullWidth
                        name="seoPurpose.topKeywords"
                        value={values.seoPurpose.topKeywords}
                        onChange={handleChange}
                        error={touched.seoPurpose?.topKeywords && Boolean(errors.seoPurpose?.topKeywords)}
                        helperText={touched.seoPurpose?.topKeywords && errors.seoPurpose?.topKeywords}
                    />
                </Grid>
                <Grid item>
                    <Typography variant="h5" fontWeight="bold">Profile Description</Typography>
                    <InputComponent
                        variant="outlined"
                        fullWidth
                        name="seoPurpose.profileDescription"
                        value={values.seoPurpose.profileDescription}
                        onChange={handleChange}
                        error={touched.seoPurpose?.profileDescription && Boolean(errors.seoPurpose?.profileDescription)}
                        helperText={touched.seoPurpose?.profileDescription && errors.seoPurpose?.profileDescription}
                    />
                </Grid>
            </Grid>
        </FormBox>
    );
}

export default SEO;
