import React from "react";
import AddNotification from "../../components/notification/AddNotification";

function AddNotificationContainer() {
    return (
        <>
            <container maxWidth='lg'>
            <AddNotification/>
            </container>
        </>
    )
}
export default AddNotificationContainer;