import React, { useState, useEffect, useMemo } from "react";
import { FormBox } from "../doctor/style";
import * as Yup from 'yup';
import { Grid, Typography } from "@mui/material";
import InputComponent from "src/components/shared/Form/Input";
import Select from 'src/components/shared/Form/Select';
import { Button } from "src/components/shared";
import { addMedicalRecordRequest, updateMedicalRecordRequest, fetchMedicalRecordByIdRequest, fetchDoctornameRequest, fetchPatientnameRequest, fetchTokenAppointmentByPatientIdRequest, fetchPatientByUserIdRequest } from '../../store/appActions';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useParams, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import toast from "src/utils/toast";
import InputAutocomplete from 'src/components/App/AppGrid/Filter/Dropdown';


const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

function AddMedicalRecord() {
    const dispatch = useDispatch();
    const doctors = useSelector((state) => state.app.doctors );
    const [availablePatients, setAvailablePatients] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [tokenAppointments, setTokenAppointments] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState('');
    const { id } = useParams();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [initialValues, setInitialValues] = useState({
        notes: '',
        recordType: '',
        patientView: '',
        user: '',
        patient: '',
        tokenappointment: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([
                    new Promise((resolve, reject) => dispatch(fetchDoctornameRequest('', resolve, reject))),
                ]);

                if (id) {
                    const medicalRecord = await new Promise((resolve, reject) =>
                        dispatch(fetchMedicalRecordByIdRequest(id, resolve, reject))
                    );

                    setInitialValues({
                        notes: medicalRecord.notes || '',
                        recordType: medicalRecord.recordType || '',
                        patientView: medicalRecord.patientView || '',
                        user: medicalRecord.user || '',
                        patient: medicalRecord.patient || '',
                        tokenappointment: medicalRecord.tokenappointment || '',
                    });

                    handleFetchPatients(medicalRecord.user);
                    handleFetchTokenAppointments(medicalRecord.patient);
                }

                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [dispatch, id]);



    const handleFetchPatients = (userId) => {
        console.log('Fetching patients for userId:', userId);
        dispatch(fetchPatientByUserIdRequest(userId, (data) => {
            console.log('Fetched patients:', data);
            setAvailablePatients(data.map((patient) => ({ label: patient.name, value: patient.id })));
        }, (error) => {
            enqueueSnackbar('Failed to fetch patients', { variant: 'error' });
        }));
    };

    const handleFetchTokenAppointments = (patientId) => {
        dispatch(fetchTokenAppointmentByPatientIdRequest(patientId, (data) => {
            setTokenAppointments(data);
        }, (error) => {
            console.error('Error:', error);
        }));
    };

    const handlePatientChange = (e) => {
        const patientId = e.target.value;
        setSelectedPatient(patientId);
        handleFetchTokenAppointments(patientId);
    };

    const handleSubmit = async (values, { resetForm }) => {
        
        const medicalRecord = {
            ...values,
            date: dayjs().format('YYYY-MM-DD'),
            time: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
        };

        try {
            if (id) {
                await new Promise((resolve, reject) => {
                    dispatch(updateMedicalRecordRequest(id, medicalRecord, resolve, reject));
                });
                toast.success('MedicalRecord Updated Successfully');
            } else {
                await new Promise((resolve, reject) => {
                    dispatch(addMedicalRecordRequest(medicalRecord, resolve, reject));
                });
                toast.success('MedicalRecord Added Successfully');
                resetForm();
            }

            navigate('/medicalrecords');
        } catch (error) {
            toast.error('Error saving medical record');
        }
    };

    const tokenAppointmentOptions = useMemo(() => (
        tokenAppointments.map((appointment) => ({
            label: appointment.id,
            value: appointment.id,
        }))
    ), [tokenAppointments]);

    const handleSearch = (searchTerm, fetchFunc) => {
        if (searchTerm && typeof searchTerm === 'string' && searchTerm.trim().length >= 3) {
            return new Promise((resolve, reject) => {
                dispatch(fetchFunc(searchTerm.trim(), resolve, reject));
            });
        }
    };

    const debouncedSearchDoctor = useMemo(() => debounce((term) => handleSearch(term, fetchDoctornameRequest), 300), []);
    const debouncedSearchPatient = useMemo(() => debounce((term) => handleSearch(term, fetchPatientnameRequest), 300), []);

    const doctorOptions = useMemo(() => (
        doctors.map((doctor) => ({ label: doctor.name, value: doctor.id }))
    ), [doctors]);

    const handleInputChange = (searchTerm, type) => {
        console.log(`User is searching for ${type}:`, searchTerm);
        if (type === 'doctor') {
            debouncedSearchDoctor(searchTerm);
        } else if (type === 'patient') {
            debouncedSearchPatient(searchTerm);
        }
    };


    return (
        <FormBox sx={{ marginTop: '20px' }}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    notes: Yup.string().required('Please enter the notes!'),
                    recordType: Yup.string().required('Please select the recordType!'),
                    patientView: Yup.string().required('Please select the patientView!'),
                    user: Yup.string().required('Please select the user!'),
                    patient: Yup.string().required('Please select the patient!'),
                    tokenappointment: Yup.string().required('Please select the token appointment!'),
                })}
                onSubmit={handleSubmit}
            >
                {({ values, handleChange, handleSubmit, touched, errors, isValid, setFieldValue }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                            <Typography variant="h4" color='#393A96' fontWeight='bold'>
                                {id ? 'Edit Medical Record' : 'Add Medical Record'}
                            </Typography>

                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">
                                    Notes
                                </Typography>
                                <InputComponent
                                    variant="outlined"
                                    fullWidth
                                    name="notes"
                                    value={values.notes}
                                    onChange={handleChange}
                                    error={touched.notes && Boolean(errors.notes)}
                                    helperText={touched.notes && errors.notes}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">
                                    Patient View
                                </Typography>
                                <Select 
                                    variant="outlined"
                                    fullWidth
                                    name="patientView"
                                    value={values.patientView}
                                    options={[
                                        { label: 'Off', value: 'off' },
                                        { label: 'On', value: 'on' },
                                    ]}
                                    onChange={handleChange}
                                    error={touched.patientView && Boolean(errors.patientView)}
                                    helperText={touched.patientView && errors.patientView}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">
                                    Record Type
                                </Typography>
                                <Select
                                    variant="outlined"
                                    fullWidth
                                    name="recordType"
                                    value={values.recordType}
                                    options={[
                                        { label: 'Note', value: 'note' },
                                        { label: 'Prescription', value: 'prescription' },
                                        { label: 'Reports', value: 'reports' },
                                        { label: 'XYZ Transfer', value: 'xyz' },
                                    ]}
                                    onChange={handleChange}
                                    error={touched.recordType && Boolean(errors.recordType)}
                                    helperText={touched.recordType && errors.recordType}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">User</Typography>
                                <InputAutocomplete
                                    variant="outlined"
                                    fullWidth
                                    name="user"
                                    value={doctorOptions.find(doctor => doctor.value === values.user) || null}
                                    onChange={(value) => {
                                        setFieldValue("user", value ? value.value : '');
                                        handleFetchPatients(value ? value.value : '');
                                    }}
                                    options={doctorOptions}
                                    onInputChange={(event, value) => handleInputChange(value, 'doctor')}
                                    placeholder="Select a doctor"
                                    error={touched.user && Boolean(errors.user)}
                                    helperText={touched.user && errors.user}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">Patient</Typography>
                                <InputAutocomplete
                                    variant="outlined"
                                    fullWidth
                                    name="patient"
                                    value={availablePatients.find(patient => patient.value === values.patient) || null}
                                    onChange={(value) => {
                                        const selectedPatientId = value ? value.value : '';
                                        setFieldValue("patient", selectedPatientId);
                                        if (selectedPatientId) {
                                            handleFetchTokenAppointments(selectedPatientId); 
                                        } else {
                                            setTokenAppointments([]);
                                        }
                                    }}
                                    options={availablePatients}
                                    onInputChange={(event, value) => handleInputChange(value, 'patient')}
                                    placeholder="Select a patient"
                                    error={touched.patient && Boolean(errors.patient)}
                                    helperText={touched.patient && errors.patient}
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">Token Appointment</Typography>
                                <InputAutocomplete
                                    variant="outlined"
                                    fullWidth
                                    name="tokenappointment"
                                    value={tokenAppointmentOptions.find((option) => option.value === values.tokenappointment) || null}
                                    onChange={(value) => setFieldValue("tokenappointment", value ? value.value : '')}
                                    options={tokenAppointmentOptions}
                                    placeholder="Select a token appointment"
                                    error={touched.tokenappointment && Boolean(errors.tokenappointment)}
                                    helperText={touched.tokenappointment && errors.tokenappointment}
                                />
                            </Grid>

                            <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '10px' }}>
                                <Button type="submit" variant="contained" sx={{ textAlign: 'center' }} disabled={!isValid}>
                                    {id ? 'Update Medical Record' : 'Save Medical Record'}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </FormBox>
    );
}

export default AddMedicalRecord;
