import React from "react";
import AddClinic from "../../components/clinic/AddClinic";

function AddClinicContainer() {
    return (
        <>
            <container maxWidth='lg'>
            <AddClinic/>
            </container>
        </>
    )
}
export default AddClinicContainer;