import React from "react";
import { FormBox } from "../doctor/style";
import { Grid, Typography, Box } from "@mui/material";
import InputComponent from 'src/components/shared/Form/Input';
import { Button } from 'src/components/shared';
import { useFormikContext, FieldArray } from 'formik';
import { AddCircle } from "@mui/icons-material";

function Links({ handlePrevious, handleNext }) {
    const { values, handleChange, touched, errors } = useFormikContext();

    return (
        <FormBox sx={{ marginTop: '20px', marginLeft: '5%' }}>
            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                <FieldArray name="links">
                    {({ push, remove }) => (
                        <>
                            {values.links.map((link, index) => (
                                <Box key={index} component="div">
                                    <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                                        <Grid item>
                                            <Typography variant="h5" fontWeight="bold">
                                                Link Title
                                            </Typography>
                                            <InputComponent
                                                variant="outlined"
                                                fullWidth
                                                name={`links.${index}.linkTitle`}
                                                value={link.linkTitle}
                                                onChange={handleChange}
                                                error={touched.links?.[index]?.linkTitle && Boolean(errors.links?.[index]?.linkTitle)}
                                                helperText={touched.links?.[index]?.linkTitle && errors.links?.[index]?.linkTitle}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" fontWeight="bold">
                                                Link
                                            </Typography>
                                            <InputComponent
                                                variant="outlined"
                                                fullWidth
                                                name={`links.${index}.link`}
                                                value={link.link}
                                                onChange={handleChange}
                                                error={touched.links?.[index]?.link && Boolean(errors.links?.[index]?.link)}
                                                helperText={touched.links?.[index]?.link && errors.links?.[index]?.link}
                                            />
                                        </Grid>
                                        {index === values.links.length - 1 && (
                                            <Grid item xs={2}>
                                                <Button
                                                    startIcon={<AddCircle />}
                                                    onClick={() => push({ link: '', linkTitle: '' })} // Initialize with empty fields
                                                >
                                                    Add New Link
                                                </Button>
                                            </Grid>
                                        )}
                                        {index > 0 && (
                                            <Grid item xs={2}>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => remove(index)}
                                                >
                                                    Remove Link
                                                </Button>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Box>
                            ))}
                        </>
                    )}
                </FieldArray>
            </Grid>
        </FormBox>
    );
}

export default Links;
