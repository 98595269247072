import React from 'react';
import PropTypes from 'prop-types';

import {
  Switch,
  FormGroup,
  FormControlLabel,
  FormControl,
  Box,
  FormHelperText,
} from '@mui/material';

function SwitchComponent({
  label,
  labelPlacement,
  color,
  onChange,
  error,
  checked,  // Updated to use checked instead of value
  helperText,
  sx,
  ...props
}) {
  return (
    <Box mt={2} sx={sx}>
      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={<Switch color={color} checked={checked} onChange={onChange} />}
            label={label}
            labelPlacement={labelPlacement}
            {...props}
          />
          {helperText && helperText !== '' ? <FormHelperText>{helperText}</FormHelperText> : null}
        </FormGroup>
      </FormControl>
    </Box>
  );
}

SwitchComponent.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  labelPlacement: PropTypes.string,
  onChange: PropTypes.func,
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'success', 'error']),
  checked: PropTypes.bool,  // Updated to require boolean checked
  helperText: PropTypes.string,
  sx: PropTypes.object,
};

SwitchComponent.defaultProps = {
  className: undefined,
  label: undefined,
  labelPlacement: 'end',
  onChange: () => {},
  color: 'primary',
  checked: false,  // Updated to default to false
  helperText: '',
  sx: {},
};

export default SwitchComponent;
