import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import AppGrid from 'src/components/App/AppGrid';
import { Button } from 'src/components/shared';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchTagsRequest,deleteTagRequest } from '../../store/appActions';
import storage from 'src/utils/storageUtils';
import toast from 'src/utils/toast';
import Paging from 'src/components/App/Paging';

function TagContainer() {
  const [tags, setTags] = useState([]);
  const [selectedTagId, setSelectedTagId] = useState(null);
  const [page, setPage] = useState(1);  
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);  
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const token = storage.get('TOKEN');
      const response = await new Promise((resolve, reject) =>
        dispatch(fetchTagsRequest(token,page,perPage, resolve, reject)),
      );
      if (response && response.data) {
        setTags(response.data);
        setTotalPages(response.meta.totalPages); 
      }
    };

    fetchData();
  }, [dispatch,page,perPage]);

  const handleActionMenu = (event, id) => {
    console.log("Opening action menu for tag ID:", id); 
    setOpen(event.currentTarget);
    setSelectedTagId(id); 
  };

  const handleDeleteTag = async () => {
    const response = await new Promise((resolve, reject) =>
      dispatch(deleteTagRequest(selectedTagId, resolve, reject)),
    );
    if (response) {
      setTags(tags.filter((tag) => tag.id !== selectedTagId));
      handleCloseMenu();
      toast.success('Tag Deleted Successfully');
    }
  };

  const addTag = () => {
    navigate('/tag')
  };

  const handleEditTag = () => {
    if (selectedTagId) {
      navigate(`/tag/${selectedTagId}`);
    } else {
      toast.error('Tag information is missing');
    }
  };

  const columns = [
    {
      label: 'Patient Name',
      name: 'patientName',
    },
    {
      label: 'Tag Name',
      name: 'tag',
      options: {
        customBodyRender: (value) => value.join(', '), 
      },
    },
    {
      label: 'User Name',
      name: 'userName',
    },
    {
      label: 'Clinic Name',
      name: 'clinicName'
    },
    {
      label: () => {
        <></>;
      },
      name: 'action',
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              <IconButton onClick={(event) => handleActionMenu(event, tags[dataIndex]?.id)}>
                <MoreVertIcon />
              </IconButton>
              <Popover
                open={!!open}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                  sx: { width: 140 },
                }}
              >
                <MenuItem onClick={handleEditTag}>
                  <Stack spacing={1} direction="row">
                    <EditIcon />
                    <span>Edit</span>
                  </Stack>
                </MenuItem>
                <MenuItem onClick={handleDeleteTag} sx={{ color: 'error.main' }}>
                  <Stack spacing={1} direction="row">
                    <DeleteIcon />
                    <span>Delete</span>
                  </Stack>
                </MenuItem>
              </Popover>
            </>
          );
        },
      },
    },
  ];

  const handleCloseMenu = () => {
    setOpen(null);
  };

  return (
    <>
      <Box sx={{ marginBottom: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} container>
            <Grid item xs={6}></Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={addTag} variant="contained">Add Tag</Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginBottom: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AppGrid
              columns={columns}
              data={tags}
              selectableRows="none"
              onFilterChange={() => {}}
              appliedFilters={{}}
              bulkActions={[]}
              onBulkAction={() => {}}
              onSort={() => {}}
              onClearFilters={() => {}}
              title=""
              titleProps={{}}
              pagingType="table"
            />
             <Paging
              page={page - 1}
              perPage={perPage}
              count={totalPages * perPage} 
              onChange={(newPage) => setPage(newPage + 1)}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default TagContainer;

// customBodyRender: (value) => {
//   if (Array.isArray(value)) {
//     return value.join(', ');
//   }
//   return value; 
// },
// },