import { useState,useEffect } from 'react';
import Grid from '@mui/material/Grid';
import AppGrid from 'src/components/App/AppGrid';
import { appointments } from '../../../../mock/appointments';
import { Button } from 'src/components/shared';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import {useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchClinicsRequest,deleteClinicRequest } from '../../store/appActions';
import storage from 'src/utils/storageUtils';
import toast from 'src/utils/toast';
import Paging from 'src/components/App/Paging';


function ClinicContainer() {
  const [clinics, setClinics] = useState([]);
  const [selectedClinicId, setSelectedClinicId] = useState(null);
  const [page, setPage] = useState(1);  
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0); 
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
        const token = storage.get('TOKEN');
        const response = await new Promise((resolve, reject) =>
            dispatch(fetchClinicsRequest(token,page,perPage, resolve, reject)),
        );
        if (response && response.data) {
            setClinics(response.data);
            setTotalPages(response.meta.totalPages); 
        }
    };

    fetchData();
}, [dispatch,page,perPage]);


const handleActionMenu = (event, id) => {
  console.log("Opening action menu for clinic ID:", id);
  setOpen(event.currentTarget);
  setSelectedClinicId(id);
};


const handleDeleteClinic = async () => {
  const response = await new Promise((resolve, reject) =>
    dispatch(deleteClinicRequest(selectedClinicId, resolve, reject)),
  );
  if (response) {
    setClinics(clinics.filter((clinic) => clinic.id !== selectedClinicId));
    handleCloseMenu();
    toast.success('Clinic Deleted Successfully')
  }
};

  const addClinic = (event) => {
    navigate('/clinic')
  };

  const handleEditClinic = () => {
    if (selectedClinicId) {
      navigate(`/clinic/${selectedClinicId}`);
    } else {
      toast.error('Clinic information is missing');
    }
  };


  const toProfile = () => {
    navigate('/doctorprofile');
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  appointments.map((item, index) => {
    return (item.index = index + 1);
  });

  const columns = [
    
    {
      label: 'Clinic Name',
      name: 'clinicName',
    },
    {
      label: 'Address',
      name: 'address',
    },
    {
      label: 'User Name',
      name: 'user.fullName',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const user = clinics[tableMeta.rowIndex]?.user;
          return user ? user.fullName : 'N/A';
        },
      },
    },
    {
      label: 'Status',
      name: 'status',
    },
 
    {
      label: () => {
        <></>;
      },
      name: 'action',
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              <IconButton onClick={(event) => handleActionMenu(event, clinics[dataIndex]?.id)}>
                <MoreVertIcon />
              </IconButton>
              <Popover
                open={!!open}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                  sx: { width: 140 },
                }}
              >
                <MenuItem onClick={handleEditClinic}>
                  <Stack spacing={1} direction="row">
                    <EditIcon />
                    <span>Edit</span>
                  </Stack>
                </MenuItem>

                <MenuItem onClick={handleDeleteClinic} sx={{ color: 'error.main' }}>
                  <Stack spacing={1} direction="row">
                    <DeleteIcon />
                    <span>Delete</span>
                  </Stack>
                </MenuItem>

                <MenuItem onClick={toProfile}>
                <Stack spacing={1} direction="row">
                  <AccountBoxIcon/>
                  <span>Profile</span>
                </Stack>
              </MenuItem>
              </Popover>
            </>
          );
        },
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          marginBottom: 2,
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} container>
            <Grid item xs={6}>
              {/* <Stack spacing={1} direction="row">
                <Button variant="contained">Today</Button>
                <Button variant="outlined">Tomorrow</Button>
                <PickerWithButtonField />
              </Stack> */}
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" onClick={addClinic}>Add Clinic</Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          marginBottom: 2,
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AppGrid
              columns={columns}
              data={clinics}
              selectableRows="none"
              onFilterChange={() => {}}
              appliedFilters={{}}
              bulkActions={[]}
              onBulkAction={() => {}}
              onSort={() => {}}
              onClearFilters={() => {}}
              title=""
              titleProps={{}}
              pagingType="table"
            />
            <Paging
              page={page - 1}
              perPage={perPage}
              count={totalPages * perPage} 
              onChange={(newPage) => setPage(newPage + 1)}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ClinicContainer;