import React from "react";
import { FormBox } from "../doctor/style";
import { Grid, Typography, Box } from "@mui/material";
import InputComponent from 'src/components/shared/Form/Input';
import { Button } from 'src/components/shared';
import { useFormikContext, FieldArray } from 'formik';
import { AddCircle } from "@mui/icons-material";

function PrescriptionTemplates({ handlePrevious, handleNext }) {
    const { values, handleChange, touched, errors } = useFormikContext();

    return (
        <FormBox sx={{ marginTop: '20px', marginLeft: '5%' }}>
            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                <FieldArray name="prescriptionTemplate">
                    {({ push, remove }) => (
                        <>
                            {values.prescriptionTemplate && values.prescriptionTemplate.length > 0 ? (
                                values.prescriptionTemplate.map((template, index) => (
                                    <Box key={index} component="div">
                                        <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                                            <Grid item>
                                                <Typography variant="h5" fontWeight="bold">Title</Typography>
                                                <InputComponent
                                                    variant="outlined"
                                                    fullWidth
                                                    name={`prescriptionTemplate.${index}.title`}
                                                    value={template.title}
                                                    onChange={handleChange}
                                                    error={touched.prescriptionTemplate?.[index]?.title && Boolean(errors.prescriptionTemplate?.[index]?.title)}
                                                    helperText={touched.prescriptionTemplate?.[index]?.title && errors.prescriptionTemplate?.[index]?.title}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h5" fontWeight="bold">Text</Typography>
                                                <InputComponent
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                    rows={3}
                                                    name={`prescriptionTemplate.${index}.text`}
                                                    value={template.text}
                                                    onChange={handleChange}
                                                    error={touched.prescriptionTemplate?.[index]?.text && Boolean(errors.prescriptionTemplate?.[index]?.text)}
                                                    helperText={touched.prescriptionTemplate?.[index]?.text && errors.prescriptionTemplate?.[index]?.text}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => remove(index)}
                                                >
                                                    Remove Template
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))
                            ) : (
                                <Typography>No Prescription templates added yet.</Typography>
                            )}
                            <Grid item xs={2}>
                                <Button
                                    startIcon={<AddCircle />}
                                    onClick={() => push({ title: '', text: '', type: 'prescription', status: 'ACTIVE' })}
                                >
                                    Add New Template
                                </Button>
                            </Grid>
                        </>
                    )}
                </FieldArray>
            </Grid>
        </FormBox>
    );
}

export default PrescriptionTemplates;
