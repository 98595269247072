import React, { useState, useEffect, useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { Button } from 'src/components/shared';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
    fetchPatientInfoByIdRequest,
    addPatientInfoRequest,
    updatePatientInfoRequest,
    fetchDoctornameRequest,
    fetchPatientnameRequest,
    fetchPatientByUserIdRequest,
} from '../../store/appActions';
import { useSnackbar } from 'notistack';
import toast from 'src/utils/toast';
import { FormBox } from '../doctor/style';
import InputComponent from 'src/components/shared/Form/Input';
import InputAutocomplete from 'src/components/App/AppGrid/Filter/Dropdown';

const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

function AddPatientInfo() {
    const dispatch = useDispatch();
    const doctors = useSelector((state) => state.app.doctors);
    const [availablePatients, setAvailablePatients] = useState([]);
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        weight: '',
        height: '',
        patient: '',
        user: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([
                    new Promise((resolve, reject) => dispatch(fetchDoctornameRequest('', resolve, reject))),
                    new Promise((resolve, reject) => dispatch(fetchPatientnameRequest('', resolve, reject)))
                ]);

                if (id) {
                   const patientinfo = await new Promise((resolve, reject) => {
                        dispatch(fetchPatientInfoByIdRequest(id, (patientinfo) => {
                            setInitialValues({
                                weight: patientinfo.weight,
                                height: patientinfo.height,
                                user: patientinfo.user || '',
                                patient: patientinfo.patient || '',
                            });
                            resolve();
                        }, reject));
                    });
                    handleFetchPatients(patientinfo.user);  
                }
                setIsLoading(false);
            } catch (error) {
                enqueueSnackbar(error.message || 'Failed to load data', { variant: 'error' });
                setIsLoading(false);
            }
        };

        fetchData();
    }, [dispatch, id, enqueueSnackbar]);

    const handleFetchPatients = (userId) => {
        console.log('Fetching patients for userId:', userId);
        dispatch(fetchPatientByUserIdRequest(userId, (data) => {
            console.log('Fetched patients:', data);
            setAvailablePatients(data.map((patient) => ({ label: patient.name, value: patient.id })));
        }, (error) => {
            enqueueSnackbar('Failed to fetch patients', { variant: 'error' });
        }));
    };

    const handleSubmit = async (values, { resetForm }) => {
        const patientinfo = { ...values };
        try {
            if (id) {
                await new Promise((resolve, reject) => {
                    dispatch(updatePatientInfoRequest(id, patientinfo, resolve, reject));
                });
                toast.success('Patient info updated successfully');
            } else {
                await new Promise((resolve, reject) => {
                    dispatch(addPatientInfoRequest(patientinfo, resolve, reject));
                });
                toast.success('Patient info added successfully');
            }
            resetForm();
            navigate('/patientinfos');
        } catch (error) {
            enqueueSnackbar(error.message || 'Failed to submit', { variant: 'error' });
        }
    };
    const handleSearch = (searchTerm, fetchFunc) => {
        if (searchTerm && typeof searchTerm === 'string' && searchTerm.trim().length >= 3) {
            return new Promise((resolve, reject) => {
                dispatch(fetchFunc(searchTerm.trim(), resolve, reject));
            });
        }
    };

   const debouncedSearchDoctor = useMemo(() => debounce((term) => handleSearch(term, fetchDoctornameRequest), 300), []);
    const debouncedSearchPatient = useMemo(() => debounce((term) => handleSearch(term, fetchPatientnameRequest), 300), []);

    const doctorOptions = useMemo(() => (
        doctors.map((doctor) => ({ label: doctor.name, value: doctor.id }))
    ), [doctors]);

    const handleInputChange = (searchTerm, type) => {
        console.log(`User is searching for ${type}:`, searchTerm);
        if (type === 'doctor') {
            debouncedSearchDoctor(searchTerm);
        } else if (type === 'patient') {
            debouncedSearchPatient(searchTerm);
        }
    };


    return (
        <FormBox sx={{ marginTop: '20px' }}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    weight: Yup.string().required("Please enter the weight's name!"),
                    user: Yup.string().required('Please select a user!'),
                    height: Yup.string().required('Please select a height!'),
                    patient: Yup.string().required('Please select a patient!'),
                })}
                onSubmit={handleSubmit}
            >
                {({ values, handleChange, handleSubmit, touched, errors, isValid, setFieldValue }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                            <Typography variant="h4" color='#393A96' fontWeight='bold'>
                                {id ? 'Edit PatientInfo' : 'Add PatientInfo'}
                            </Typography>

                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">
                                    Weight
                                </Typography>
                                <InputComponent
                                    variant="outlined"
                                    fullWidth
                                    name="weight"
                                    value={values.weight}
                                    onChange={handleChange}
                                    error={touched.weight && Boolean(errors.weight)}
                                    helperText={touched.weight && errors.weight}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">
                                    Height
                                </Typography>
                                <InputComponent
                                    variant="outlined"
                                    fullWidth
                                    name="height"
                                    value={values.height}
                                    onChange={handleChange}
                                    error={touched.height && Boolean(errors.height)}
                                    helperText={touched.height && errors.height}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    User
                                </Typography>
                                <InputAutocomplete
                                    variant="outlined"
                                    fullWidth
                                    name="user"
                                    value={doctorOptions.find(doctor => doctor.value === values.user) || null}
                                    onChange={(value) => {
                                        setFieldValue("user", value ? value.value : '');
                                        handleFetchPatients(value ? value.value : '');
                                    }}
                                    options={doctorOptions}
                                    onInputChange={(event, value) => handleInputChange(value, 'doctor')}
                                    placeholder="Select a doctor"
                                    error={touched.user && Boolean(errors.user)}
                                    helperText={touched.user && errors.user}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    Patient
                                </Typography>
                                <InputAutocomplete
                                    variant="outlined"
                                    fullWidth
                                    name="patient"
                                    value={availablePatients.find(patient => patient.value === values.patient) || null}
                                    onChange={(value) => setFieldValue("patient", value ? value.value : '')}
                                    options={availablePatients}
                                    onInputChange={(event, value) => handleInputChange(value, 'patient')}
                                    placeholder="Select a patient"
                                    error={touched.patient && Boolean(errors.patient)}
                                    helperText={touched.patient && errors.patient}
                                />
                            </Grid>

                            <Grid container justifyContent="center" sx={{ marginTop: '20px' }}>
                            <Button variant='contained' type='submit' disabled={!isValid || isLoading}>
                            {id ? 'Update PatientInfo' : 'Add PatientInfo'}
                            </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </FormBox>
    );
}

export default AddPatientInfo;
