import React, { useState, useEffect, useMemo } from "react";
import { FormBox } from "../doctor/style";
import * as Yup from 'yup';
import { Grid, Typography } from "@mui/material";
import InputComponent from "src/components/shared/Form/Input";
import Select from 'src/components/shared/Form/Select';
import { Button } from "src/components/shared";
import { addFollowupRequest, updateFollowupRequest, fetchDoctornameRequest, fetchPatientnameRequest, fetchClinicnameRequest, fetchTokenAppointmentByPatientIdRequest, fetchFollowupByIdRequest, fetchPatientByUserIdRequest, fetchClinicByUserIdRequest } from '../../store/appActions';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useParams, useNavigate } from 'react-router-dom';
import toast from "src/utils/toast";
import InputAutocomplete from 'src/components/App/AppGrid/Filter/Dropdown';


const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

function AddEditFollowup() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();
    const doctors = useSelector((state) => state.app.doctors || []);
    const [availablePatients, setAvailablePatients] = useState([]);
    const [tokenAppointments, setTokenAppointments] = useState([]);
    const [availableClinics, setAvailableClinics] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedPatient, setSelectedPatient] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    const [initialValues, setInitialValues] = useState({
        totalFollowup: '',
        remainingFollowup: '',
        days: '',
        user: '',
        patient: '',
        tokenAppointment: '',
        clinic: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([
                    new Promise((resolve, reject) => dispatch(fetchDoctornameRequest('', resolve, reject))),
                ]);


                if (id) {
                    const followup = await new Promise((resolve, reject) =>
                        dispatch(fetchFollowupByIdRequest(id, resolve, reject))
                    );
                    setInitialValues({
                        totalFollowup: followup.totalFollowup || '',
                        remainingFollowup: followup.remainingFollowup || '',
                        days: followup.days || '',
                        user: followup.user || '',
                        patient: followup.patient || '',
                        tokenAppointment: followup.tokenAppointment || '',
                        clinic: followup.clinic || '',
                    });
                    handleFetchTokenAppointments(followup.patient);
                    handleFetchPatients(followup.user);
                    handleFetchClinics(followup.user);
                }

                setIsLoading(false);
            } catch (error) {
                console.log(error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [dispatch, id]);

    const handleFetchPatients = (userId) => {
        console.log('Fetching patients for userId:', userId);
        dispatch(fetchPatientByUserIdRequest(userId, (data) => {
            console.log('Fetched patients:', data);
            setAvailablePatients(data.map((patient) => ({ label: patient.name, value: patient.id })));
        }, (error) => {
            enqueueSnackbar('Failed to fetch patients', { variant: 'error' });
        }));
    };

    const handleFetchClinics = (userId) => {
        console.log('Fetching clinics for userId:', userId);
        dispatch(fetchClinicByUserIdRequest(userId, (data) => {
            console.log('Fetched clinics:', data);
            setAvailableClinics(data.map((clinic) => ({ label: clinic.name, value: clinic.id })));
        }, (error) => {
            enqueueSnackbar('Failed to fetch clinics', { variant: 'error' });
        }));
    };

    const handleFetchTokenAppointments = (patientId) => {
        dispatch(fetchTokenAppointmentByPatientIdRequest(patientId, (data) => {
            setTokenAppointments(data);
        }, (error) => {
            console.error('Error:', error);
        }));
    };

    // const handlePatientChange = (e) => {
    //     const patientId = e.target.value;
    //     setSelectedPatient(patientId);
    //     handleFetchTokenAppointments(patientId);
    // };

    const handleSubmit = async (values, { resetForm }) => {
        const followup = { ...values };

        try {
            if (id) {
                await new Promise((resolve, reject) => dispatch(updateFollowupRequest(id, followup, resolve, reject)));
                toast.success('Followup Updated Successfully');
            } else {
                await new Promise((resolve, reject) => dispatch(addFollowupRequest(followup, resolve, reject)));
                toast.success('Followup Added Successfully');
                resetForm();
            }
            navigate('/followups');
        } catch (error) {
            toast.error('Error saving followup');
        }
    };

    const handleSearch = (searchTerm, fetchFunc) => {
        if (searchTerm && typeof searchTerm === 'string' && searchTerm.trim().length >= 3) {
            return new Promise((resolve, reject) => {
                dispatch(fetchFunc(searchTerm.trim(), resolve, reject));
            });
        }
    };

    const debouncedSearchDoctor = useMemo(() => debounce((term) => handleSearch(term, fetchDoctornameRequest), 300), []);
    const debouncedSearchClinic = useMemo(() => debounce((term) => handleSearch(term, fetchClinicnameRequest), 300), []);
    const debouncedSearchPatient = useMemo(() => debounce((term) => handleSearch(term, fetchPatientnameRequest), 300), []);

    const doctorOptions = useMemo(() => (
        doctors.map((doctor) => ({ label: doctor.name, value: doctor.id }))
    ), [doctors]);

    const tokenAppointmentOptions = useMemo(() => (
        tokenAppointments.map((appointment) => ({
            label: appointment.id,
            value: appointment.id,
        }))
    ), [tokenAppointments]);


    const handleInputChange = (searchTerm, type) => {
        console.log(`User is searching for ${type}:`, searchTerm);
        if (type === 'doctor') {
            debouncedSearchDoctor(searchTerm);
        } else if (type === 'clinic') {
            debouncedSearchClinic(searchTerm);
        } else if (type === 'patient') {
            debouncedSearchPatient(searchTerm);
        }
    };


    return (
        <FormBox sx={{ marginTop: '20px' }}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    totalFollowup: Yup.string().required("Please enter total followup!"),
                    remainingFollowup: Yup.string().required('Please enter the remaining followup!'),
                    days: Yup.string().required('Please enter the days!'),
                    patient: Yup.string().required('Please select the patient!'),
                    tokenAppointment: Yup.string().required('Please select the token appointment!'),
                    clinic: Yup.string().required('Please select the clinic!'),
                })}
                onSubmit={handleSubmit}
            >
                {({ values, handleChange, handleSubmit, touched, errors, setFieldValue, isValid }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                            <Typography variant="h4" color='#393A96' fontWeight='bold'>
                                {id ? 'Edit Followup' : 'Add Followup'}
                            </Typography>

                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">
                                    Total Follow-Up
                                </Typography>
                                <InputComponent
                                    variant="outlined"
                                    fullWidth
                                    name="totalFollowup"
                                    value={values.totalFollowup}
                                    onChange={handleChange}
                                    error={touched.totalFollowup && Boolean(errors.totalFollowup)}
                                    helperText={touched.totalFollowup && errors.totalFollowup}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">
                                    Remaining Follow-Up
                                </Typography>
                                <InputComponent
                                    variant="outlined"
                                    fullWidth
                                    name="remainingFollowup"
                                    value={values.remainingFollowup}
                                    onChange={handleChange}
                                    error={touched.remainingFollowup && Boolean(errors.remainingFollowup)}
                                    helperText={touched.remainingFollowup && errors.remainingFollowup}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">
                                    Days
                                </Typography>
                                <InputComponent
                                    variant="outlined"
                                    fullWidth
                                    name="days"
                                    value={values.days}
                                    onChange={handleChange}
                                    error={touched.days && Boolean(errors.days)}
                                    helperText={touched.days && errors.days}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    User
                                </Typography>
                                <InputAutocomplete
                                    variant="outlined"
                                    fullWidth
                                    name="user"
                                    value={doctorOptions.find(doctor => doctor.value === values.user) || null}
                                    onChange={(value) => {
                                        setFieldValue("user", value ? value.value : '');
                                        handleFetchPatients(value ? value.value : '');
                                        handleFetchClinics(value ? value.value : '');
                                    }}
                                    options={doctorOptions}
                                    onInputChange={(event, value) => handleInputChange(value, 'doctor')}
                                    placeholder="Select a doctor"
                                    error={touched.user && Boolean(errors.user)}
                                    helperText={touched.user && errors.user}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    Clinic
                                </Typography>
                                <InputAutocomplete
                                    variant="outlined"
                                    fullWidth
                                    name="clinic"
                                    value={availableClinics.find(clinic => clinic.value === values.clinic) || null}
                                    onChange={(value) => setFieldValue("clinic", value ? value.value : '')}
                                    options={availableClinics}
                                    onInputChange={(event, value) => handleInputChange(value, 'clinic')}
                                    placeholder="Select a clinic"
                                    error={touched.clinic && Boolean(errors.clinic)}
                                    helperText={touched.clinic && errors.clinic}
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">Patient</Typography>
                                <InputAutocomplete
                                    variant="outlined"
                                    fullWidth
                                    name="patient"
                                    value={availablePatients.find(patient => patient.value === values.patient) || null}
                                    onChange={(value) => {
                                        const selectedPatientId = value ? value.value : '';
                                        setFieldValue("patient", selectedPatientId);
                                        if (selectedPatientId) {
                                            handleFetchTokenAppointments(selectedPatientId);
                                        } else {
                                            setTokenAppointments([]);
                                        }
                                    }}
                                    options={availablePatients}
                                    onInputChange={(event, value) => handleInputChange(value, 'patient')}
                                    placeholder="Select a patient"
                                    error={touched.patient && Boolean(errors.patient)}
                                    helperText={touched.patient && errors.patient}
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">Token Appointment</Typography>
                                <InputAutocomplete
                                    variant="outlined"
                                    fullWidth
                                    name="tokenAppointment"
                                    value={tokenAppointmentOptions.find((option) => option.value === values.tokenAppointment) || null}
                                    onChange={(value) => setFieldValue("tokenAppointment", value ? value.value : '')}
                                    options={tokenAppointmentOptions}
                                    placeholder="Select a token appointment"
                                    error={touched.tokenAppointment && Boolean(errors.tokenAppointment)}
                                    helperText={touched.tokenAppointment && errors.tokenAppointment}
                                />
                            </Grid>

                            <Grid container justifyContent="center" sx={{ marginTop: '20px' }}>                                <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={!isValid}
                            >
                                {id ? 'Update Followup' : 'Add Followup'}
                            </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </FormBox>
    );
}

export default AddEditFollowup;
