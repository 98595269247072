import React from "react";
import AddMessage from "../../components/message/AddMessage";

function AddMessageContainer() {
    return (
        <>
            <container maxWidth='lg'>
            <AddMessage/>
            </container>
        </>
    )
}
export default AddMessageContainer;