import React from "react";
import AddMedicalRecord from "../../components/medicalrecord/AddMedicalRecord";

function AddMedicalRecordContainer() {
    return (
        <>
            <container maxWidth='lg'>
            <AddMedicalRecord/>
            </container>
        </>
    )
}
export default AddMedicalRecordContainer;