import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Grid, Typography, Tooltip } from '@mui/material';
import { Formik } from 'formik';
import { addStaffRequest, updateStaffRequest, fetchStaffByIdRequest, fetchDoctornameRequest } from '../../store/appActions';
import { FormBox } from '../doctor/style';
import { Button } from 'src/components/shared';
import { useSnackbar } from 'notistack';
import InputComponent from 'src/components/shared/Form/Input';
import Select from 'src/components/shared/Form/Select';
import { useParams, useNavigate } from 'react-router-dom';
import InputAutocomplete from 'src/components/App/AppGrid/Filter/Dropdown';

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

function AddStaff() {
  const dispatch = useDispatch();
  const doctors = useSelector((state) => state.app.doctors || []);
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const [initialValues, setInitialValues] = useState({
    name: '',
    email: '',
    password: '',
    role: '',
    user: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        await new Promise((resolve, reject) => {
          dispatch(fetchDoctornameRequest('',resolve, reject));
        });

        if (id) {
          await new Promise((resolve, reject) => {
            dispatch(fetchStaffByIdRequest(id, (staff) => {
              setInitialValues({
                name: staff.name || '',
                email: staff.email || '',
                role: staff.role || '',
                user: staff.user || '',
              });
            }, reject));
          });
        }

        setIsLoading(false);
      } catch (error) {
        console.error('Failed to load data:', error);
        enqueueSnackbar(error.message || 'Failed to load data', { variant: 'error' });
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch, id, enqueueSnackbar]);

  const handleSubmit = async (values, { resetForm }) => {
    const { password, ...staffData } = values;

    const staff = {
      ...staffData,
      ...(id ? {} : { password }),
    };

    try {
      if (id) {
        await new Promise((resolve, reject) => {
          dispatch(updateStaffRequest(id, staff, resolve, reject));
        });
        enqueueSnackbar('Staff updated successfully', { variant: 'success' });
      } else {
        await new Promise((resolve, reject) => {
          dispatch(addStaffRequest(staff, resolve, reject));
        });
        enqueueSnackbar('Staff added successfully', { variant: 'success' });
      }

      resetForm();
      navigate('/staffs');
    } catch (error) {
      console.error('Failed to save staff:', error);
      enqueueSnackbar('Failed to save staff', { variant: 'error' });
    }
  };

  const doctorOptions = useMemo(() => (
    doctors.map((doctor) => ({
      label: doctor.name,
      value: doctor.id,
    }))
  ), [doctors]);

  const roleOptions = [
    { label: 'Nurse', value: 'nurse' },
    { label: 'Assistant', value: 'assistant' },
    { label: 'Receptionist', value: 'receptionist' },
  ];

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Please enter the staff name!'),
    email: Yup.string().email('Invalid email address').required('Please enter the staff email!'),
    password: id
      ? Yup.string()
      : Yup.string()
        .required('Please enter a password!')
        .min(8, 'Password must be at least 8 characters long')
        .matches(
          /^[a-zA-Z0-9]{8,30}$/,
          'Password must contain only alphanumeric characters'
        ),
    role: Yup.string().required('Please select the staff role!'),
    user: Yup.string().required('Please select a user!'),
  });


  const handleSearchDoctor = async (searchTerm) => {
    if (searchTerm && typeof searchTerm === 'string' && searchTerm.trim().length >= 3) {
      try {
        await new Promise((resolve, reject) => {
          dispatch(fetchDoctornameRequest(searchTerm.trim(), resolve, reject));
        });
      } catch (error) {
        console.error('Search error:', error);
        enqueueSnackbar(error.message || 'Failed to search users', { variant: 'error' });
      }
    }
  };



  const debouncedSearch = useMemo(() => debounce(handleSearchDoctor, 300), [handleSearchDoctor]);


  const handleInputChange = (searchTerm) => {
    console.log('User is searching for:', searchTerm);
    onSearchChange(searchTerm);
  };

  const onSearchChange = (searchTerm) => {
    console.log('User is searching for:', searchTerm);
    debouncedSearch(searchTerm);
  };



  return (
    <FormBox sx={{ marginTop: '20px', marginLeft: '5%' }}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, handleSubmit, touched, errors, isValid, setFieldValue }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
              <Typography
                variant="h4"
                color="#393A96"
                fontWeight="bold"
                sx={{ marginLeft: '2%' }}
              >
                {id ? 'Edit Staff' : 'Add Staff'}
              </Typography>
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  Name
                </Typography>
                <Tooltip title="Enter the staff member's name">
                  <InputComponent
                    variant="outlined"
                    fullWidth
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Tooltip>
              </Grid>
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  Email
                </Typography>
                <Tooltip title="Enter the staff member's email address">
                  <InputComponent
                    variant="outlined"
                    fullWidth
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Tooltip>
              </Grid>
              {!id && (
                <Grid item>
                  <Typography variant="h5" fontWeight="bold">
                    Password
                  </Typography>
                  <Tooltip title="Enter a password for the staff member">
                    <InputComponent
                      variant="outlined"
                      fullWidth
                      type="password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                    />
                  </Tooltip>
                </Grid>
              )}
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  Role
                </Typography>
                <Tooltip title="Select the staff member's role">
                  <Select
                    variant="outlined"
                    fullWidth
                    name="role"
                    value={values.role}
                    onChange={handleChange}
                    options={roleOptions}
                    placeholder="Select a role"
                    error={touched.role && Boolean(errors.role)}
                    helperText={touched.role && errors.role}
                  />
                </Tooltip>
              </Grid>
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  User
                </Typography>
                <InputAutocomplete
                  variant="outlined"
                  fullWidth
                  name="user"
                  value={doctorOptions.find(doctor => doctor.value === values.user) || null}
                  onChange={(value) => setFieldValue("user", value ? value.value : '')}
                  options={doctorOptions}
                  onInputChange={(event, value) => handleInputChange(value)}
                  placeholder="Select a doctor"
                  error={touched.user && Boolean(errors.user)}
                  helperText={touched.user && errors.user}
                />
              </Grid>
              <Grid container justifyContent="center" sx={{ marginTop: '20px' }}>
                <Button variant="contained" type="submit" disabled={!isValid}>
                  {id ? 'Update Staff' : 'Add Staff'}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </FormBox>
  );
}

export default AddStaff;
