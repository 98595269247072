import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import TextField from '@mui/material/TextField';
import { IconButton, InputAdornment } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.string,
  filter: PropTypes.instanceOf(RegExp),
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(['standard', 'filled', 'outlined']),
  loading: PropTypes.bool,
  showCounter: PropTypes.bool,
  InputProps: PropTypes.any,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
};

const defaultProps = {
  className: undefined,
  value: undefined,
  icon: undefined,
  filter: undefined,
  onChange: () => {},
  variant: 'standard',
  loading: false,
  showCounter: false,
  InputProps: {},
  helperText: '',
  error: false,
  id: undefined,
  disabled: false,
  startAdornment: null,
  endAdornment: null,
};

const NumericFormatCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue || 0, // Handle empty or invalid input
          },
        });
      }}
      thousandSeparator=","
      valueIsNumericString
    />
  );
});

const NumberInput = forwardRef(
  (
    {
      icon,
      className,
      filter,
      onChange,
      variant,
      helperText,
      error,
      id,
      loading,
      disabled,
      value,
      showCounter,
      startAdornment,
      endAdornment,
      ...props
    },
    ref,
  ) => {
    const handleChange = (inputValue) => {
      const parsedValue = parseInt(inputValue, 10);
      if (!isNaN(parsedValue) && (!filter || filter.test(parsedValue))) {
        onChange(parsedValue, null);
      } else if (inputValue === '') {
        onChange(0, null); // Handle empty input by setting value to 0
      }
    };

    const inputProps = {
      startAdornment: (
        <InputAdornment position="start">
          <IconButton
            onClick={() => {
              if (value > 0) {
                handleChange(value - 1);
              }
            }}
            disabled={disabled}
            size="large"
          >
            <RemoveIcon size={20} />
          </IconButton>
        </InputAdornment>
      ),
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            onClick={() => handleChange(value + 1)}
            disabled={disabled}
            size="large"
          >
            <AddIcon size={20} />
          </IconButton>
        </InputAdornment>
      ),
    };

    if (showCounter) {
      return (
        <TextField
          className={className}
          type="number"
          onChange={(event) => handleChange(event.target.value)}
          ref={ref}
          variant={variant}
          helperText={helperText}
          error={error}
          id={id}
          InputProps={{
            ...inputProps,
            ...props.InputProps,
            disableUnderline: true,
          }}
          disabled={loading || disabled}
          value={value || ''} // Ensure value is a number or empty string
          {...props}
        />
      );
    }

    return (
      <TextField
        className={`${className} MuiNumberInput`}
        onChange={(event) => handleChange(event.target.value)}
        variant={variant}
        helperText={helperText}
        error={error}
        id={id}
        InputProps={{
          inputComponent: NumericFormatCustom,
          startAdornment: startAdornment ? (
            <InputAdornment position="start">
              {startAdornment}
            </InputAdornment>
          ) : null,
          endAdornment: endAdornment ? (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ) : null,
        }}
        disabled={loading || disabled}
        value={value || ''} // Ensure value is a number or empty string
        {...props}
      />
    );
  },
);

NumberInput.propTypes = propTypes;
NumberInput.defaultProps = defaultProps;

export default NumberInput;
